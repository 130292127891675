import React from 'react'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useState } from 'react'
import { useTranslation } from 'react-i18next';
function Aboutboard() {
    const { t, i18n } = useTranslation();
    const [readMore, setReadMore] = useState(false);
    const [readMore2, setReadMore2] = useState(false);
    const [readMore3, setReadMore3] = useState(false);
    const extraContent = <div>
        <p style={{ fontFamily: t('fontFamilyone') }} className="extra-content p-3">
            {t("Our factory is located in Raysut Industrial Estate, Salalah provides logistical advantage in international trade due to its proximity to the port of Salalah which is equidistant from emerging economies – East Africa, Middle East & GCC. We have our own subsidiaries and branch offices in Muscat, United Arab Emirates and Kingdom of Saudi Arabia.")}
        </p>
    </div>
    const linkName = readMore ? <span style={{ fontFamily: t('fontFamilyone') }}>{t("Read Less <<")}</span> : <span style={{ fontFamily: t('fontFamilyone') }}>{t("Read More >>")}</span>
    const extraContent2 = <div>
        <p style={{ fontFamily: t('fontFamilyone') }} className="extra-content2 p-3">
            {t("We have state of the art refinery, most advanced fully automatic high speed filling machinery and equipments to produce quality products conforming to international standards and under strict hygienic conditions. Highly qualified technical staff with considerable food manufacturing experience oversee the entire operations.")}
        </p>
    </div>
    const linkName2 = readMore2 ? <span style={{ fontFamily: t('fontFamilyone') }}>{t("Read Less <<")}</span> : <span style={{ fontFamily: t('fontFamilyone') }}>{t("Read More >>")}</span>
    const extraContent3 = <div>
        <p style={{ fontFamily: t('fontFamilyone') }} className="extra-content3 p-3">
            {t("Product quality measures are in place to ensure that our products meet the customer's satisfaction and statutory requirements. Our facility is an HACCP & QMS Certified company, follows stringent GMP practices and certified food safety management systems by SGS Europe.")}
            <br></br>
            {t("Our strategy for ensuring food safety starts with suppliers and extends throughout the entire supply chain. To ensure that we choose safe, premium raw materials, we adhere to strict specifications and procurement procedures. This entails creating standards for the materials we use and carrying out checks to make sure they consistently adhere to the strictest laws and the most recent scientific findings.")}</p>
    </div>
    const linkName3 = readMore3 ? <span style={{ fontFamily: t('fontFamilyone') }}>{t("Read Less <<")}</span> : <span style={{ fontFamily: t('fontFamilyone') }}>{t("Read More >>")}</span>
    return (
        <>
            <div style={{ background: "#ffe3c5" }}>
                <div className='container about-board'>
                    <div className='row'>

                        <div className='col-md-4 col-lg-3'>
                            <div className='about-company-board'>
                                {/* <h1 style={{ fontFamily: t('fontFamilytwo') }}>{t("Board of")}<br></br>
                                    {t("Directors")}</h1> */}

                            </div>
                        </div>
                        <div className='col-12 col-sm-8 col-md-8 col-lg-9 about-company-board-para'>
                            {/* <p style={{ fontFamily: t('fontFamilyone') }}>{t("The Board of Directors is responsible for Omani Gulf Food Company strategic direction, oversight and corporate governance...")}</p> */}
                            {/* <Link to="/board-of-directors"><Button style={{ fontFamily: t('fontFamilyone') }}>{t("DISCOVER NOW")}</Button></Link> */}
                        </div>
                    </div>
                </div>
                <div className='container company-images'>
                    <div className='row'>
                        <div className='col-12 col-sm-4 col-md-4  col-sm-12 ' style={{ padding: "0px", margin: '0px' }}>
                            <div className='companyimage'>
                                <img src='/aboutimages/directbord.jpg' alt="ogfc-img"></img>
                                <div className='company-head'>
                                    <h1 style={{ fontFamily: t('fontFamilyone') }}>{t("Location")}</h1>
                                    <>
                                        <a className="read-more-link" onClick={() => { setReadMore(!readMore) }}>

                                            <Button>{linkName}</Button>


                                        </a>
                                        {readMore && extraContent}
                                    </>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4 col-sm-12  ' style={{ padding: "0px" }} >
                            <div className='companyimage'>
                                <img src='/aboutimages/directbord1.jpg' alt="ogfc-img"></img>
                                <div className='company-head'>
                                    <h1 style={{ fontFamily: t('fontFamilyone') }}>{t("Technology")}</h1>
                                    <>
                                        <a className="read-more-link" onClick={() => { setReadMore2(!readMore2) }}>

                                            <Button>{linkName2}</Button>



                                        </a>
                                        {readMore2 && extraContent2}
                                    </>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4 col-sm-12  ' style={{ padding: "0px" }}>
                            <div className='companyimage'>
                                <img src='/aboutimages/directbord2.jpg' alt="ogfc-img"></img>
                                <div className='company-head'>
                                    <h1 style={{ fontFamily: t('fontFamilyone') }}>{t("Quality")}</h1>
                                    <>
                                        <a className="read-more-link" onClick={() => { setReadMore3(!readMore3) }}>

                                            <Button>{linkName3}</Button>



                                        </a>
                                        {readMore3 && extraContent3}
                                    </>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
            <div className='row company-last-image' >
                <div className='col-lg-4 col-md-12 col-sm-12' style={{ padding: '2px', margin: '0px' }}>

                    <img src='/aboutimages/company1.jpg' alt="ogfc-img"></img>

                </div>
                <div className='col-lg-4 col-md-12  col-sm-12' style={{ padding: '2px', margin: '0px' }}>

                    <img src='/aboutimages/company2.jpg' alt="ogfc-img"></img>
                </div>
                <div className='col-lg-4 col-md-12 col-sm-12' style={{ padding: '2px', margin: '0px' }}>
                    <img src='/aboutimages/company3.jpg' alt="ogfc-img"></img>

                </div>
            </div>
        </>
    )
}

export default Aboutboard
