import React, { useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
function Aboutceo() {
  const { t, i18n } = useTranslation();
  const [state, setState] = useState(t)
  return (
    <div>
      <div className='aboutceo'>
        <Container >
          <Row>
            <Col>
              <div className='ceoimage'>

                {/* < img src={t("img-ceo")} alt="ogfc-img"></img>            */}
              </div>

            </Col>
          </Row>

        </Container>



      </div>

    </div >
  )
}

export default Aboutceo
