import React from 'react';
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
function Aboutmission() {
  const { t, i18n } = useTranslation();
  return (
    <div>
      <div className='about-mission'>
        <div className='container'>
          <div className='row'>


            <div className='about-company-mission'>
              <div className='col-md-7 col-lg-7'>
              
                <h1 className='linedown' style={{ fontFamily: t('fontFamilytwo') }}>{t("MISSION-VISION")}<br></br>{t("MISSION-slider")}
                </h1>
              
                <p style={{ fontFamily: t('fontFamilyone') }}>{t("Our culture is shaped by adherence to a set of core values that cover all our stakeholders and help us achieve our ambitious vision and mission.")}</p>
                <Link to="/mission-quality"><Button style={{ fontFamily: t('fontFamilyone') }}>{t("READ MORE")}</Button></Link>
              </div>
            </div>
            <div className='col-md-5 col-lg-5'>

            </div>

          </div>
        </div>
      </div>
    </div >
  )
}

export default Aboutmission
