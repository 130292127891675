import React from 'react';
import { Button, Container, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function Qualitycertification() {
  const { t, i18n } = useTranslation();
  return (

    <div className='section'>


      <Container>
        <Row>
          <Col className='col-12 col-sm-12   col-md-8  col-lg-8 '>
            <div className='qualification'>
              <h1 className='' style={{ textAlign: "start", fontFamily: t('fontFamily') }}>{t("QUALITY CERTIFICATION")}</h1>
            </div>
            <div className='about-para'>
              <p style={{ textAlign: "start", fontFamily: t('fontFamilyone') }}>{t("Our Manufacturing plants are up-to-date with most important certification in our industry.")}</p>
            </div>
            <div className='viewmorebutton' style={{ textAlign: "start" }}>
              <Link to="/quality-certification"><Button style={{ fontFamily: t('fontFamilyone') }}> {t("READ MORE")}</Button></Link>
            </div>
          </Col>
          <Col className="col-12   col-sm-12 col-md-4   col-lg-4 ">


            <img src='images/qualification.jpg' style={{ width: "100%" }} alt="ogfc-img"></img>


          </Col>
        </Row>
      </Container>
    </div>

  )
}

export default Qualitycertification