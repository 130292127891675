import React from 'react';
import { Carousel } from "react-bootstrap";
import Footer from '../Includes/Footer';
import Aboutenquiry from '../About/Aboutenquiry';
import Galleryvideo from './Galleryvideo';
import { useTranslation } from 'react-i18next';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { Link } from 'react-router-dom';

function Medianews() {
  const { t, i18n } = useTranslation();

  return (
    <div >



      <div className="headertop" >
        <Carousel className="slider ">
          <Carousel.Item>
            
            <img className="d-block w-100" src="/gallery/promotionbanner.jpg" alt="ogfc-img" />

            <div className="carousel-gallery" >

              <h1 className='linedown' style={{ color: "#fff", fontFamily: t('fontFamilytwo') }} > {t("MEDIA NEWS")}</h1>

            </div>

          </Carousel.Item>
        </Carousel>
      </div>
      <div className='container'>

        <div className='section'>
          <div className='row justify-content-center'>
            <div className='col-sm-12 col-md-6 col-lg-4 '>
              <div className='media-news'>
                <img src='/gallery/MEDIA NEWS/Certificate Showing Image.jpg'></img>
                <div className='d-flex pt-2 justify-content-between'>

                  <h6 style={{ fontFamily: t('fontFamilyone') }}>{t("BRAND LEADERSHIP AWARD 2023")} </h6>
                  <Link to="/gallery/MEDIA NEWS/CERTIFICATE.pdf" target="_blank" download data-bs-toggle="tooltip" data-bs-placement="bottom" title="Tooltip on bottom"><SaveAltIcon sx={{ color: "#3ab44a;", fontWeight: "900", marginLeft: "5px" }} /></Link>
   </div>
              </div>
            </div>


            <div className='col-sm-12 col-md-6 col-lg-4 '>
              <div className='media-news'>
                <img src='/gallery/MEDIA NEWS/CEO Insights - Jan 2022-new.jpg'></img>
                <div className='d-flex pt-2 justify-content-between'>
                  <h6 style={{ fontFamily: t('fontFamilyone') }}>{t("CEO Insights - Jan 2022")}</h6>
                  <Link to="/gallery/MEDIA NEWS/CEO Insights - Jan 2022.pdf" target="_blank" download data-bs-toggle="tooltip" data-bs-placement="bottom" title="Tooltip on bottom"><SaveAltIcon sx={{ color: "#3ab44a;", fontWeight: "900", marginLeft: "5px" }} /></Link>
                </div>
              </div>
            </div>


            <div className='col-sm-12 col-md-6 col-lg-4'>
              <div className='media-news '>
                <img src='/gallery/MEDIA NEWS/IAF Award_Mr. P.S. Kumar - Mar 2021-new.jpg'></img>
                <div className='d-flex pt-2 justify-content-between'>
                  <h6 style={{ fontFamily: t('fontFamilyone') }}>{t("IAF Award_Mr. P.S. Kumar - Mar 2021")}</h6>
                  <Link to="/gallery/MEDIA NEWS/IAF Award_Mr. P.S. Kumar - Mar 2021.pdf" target="_blank" download><SaveAltIcon sx={{ color: "#3ab44a;", fontWeight: "900", marginLeft: "5px" }} /></Link>
                </div>
              </div>
            </div>




            <div className='col-sm-12 col-md-6 col-lg-4'>
              <div className='media-news'>
                <img src='/gallery/MEDIA NEWS/Indian Achievers Award - Mar 2021-new.jpg'></img>
                <div className='d-flex pt-2 justify-content-between'>
                  <h6 style={{ fontFamily: t('fontFamilyone') }}>{t("Indian Achievers Award - Mar 2021")}</h6>
                  <Link to="/gallery/MEDIA NEWS/Indian Achievers Award - Mar 2021.pdf" target="_blank" download><SaveAltIcon sx={{ color: "#3ab44a;", fontWeight: "900", marginLeft: "5px" }} /></Link>
                </div>
              </div>
            </div>
            <div className='col-sm-12 col-md-6 col-lg-4'>
              <div className='media-news'>
                <img src='/gallery/MEDIA NEWS/Omani Vegetable Oils register impressive growth - Oman Observer Dec 2017.jpg'></img>
                <div className='d-flex pt-2 justify-content-between'>
                  <h6 style={{ fontFamily: t('fontFamilyone') }}>{t("Omani Vegetable Oils register impressive growth - Oman Observer Dec 2017")}</h6>
                  <Link to="/gallery/MEDIA NEWS/Omani Vegetable Oils register impressive growth - Oman Observer Dec 2017.pdf" target="_blank" download><SaveAltIcon sx={{ color: "#3ab44a;", fontWeight: "900", marginLeft: "5px" }} /></Link>
                </div>
              </div>
            </div>
            <div className='col-sm-12 col-md-6 col-lg-4'>
              <div className='media-news'>
                <img src='/gallery/MEDIA NEWS/Omans Inspiring CEO - P.S Kumar - Nov 2018-new.jpg'></img>
                <div className='d-flex pt-2 justify-content-between'>
                  <h6 style={{ fontFamily: t('fontFamilyone') }}>{t("Oman's Inspiring CEO - P.S Kumar - Nov 2018")}</h6>
                  <Link to="/gallery/MEDIA NEWS/Oman's Inspiring CEO - P.S Kumar - Nov 2018.pdf" target="_blank" download><SaveAltIcon sx={{ color: "#3ab44a;", fontWeight: "900", marginLeft: "5px" }} /></Link>
                </div>
              </div>
            </div>

            <div className='col-sm-12 col-md-6 col-lg-4'>
              <div className='media-news'>
                <img src='/gallery/MEDIA NEWS/OVOD - Euro Magazine 2016-new.jpg'></img>
                <div className='d-flex pt-2 justify-content-between'>
                  <h6 style={{ fontFamily: t('fontFamilyone') }}>{t("OVOD - Euro Magazine 2016")}</h6>
                  <Link to="/gallery/MEDIA NEWS/OVOD -  Euro Magazine 2016.pdf" target="_blank" download><SaveAltIcon sx={{ color: "#3ab44a;", fontWeight: "900", marginLeft: "5px" }} /></Link>
                </div>
              </div>
            </div>


            <div className='col-sm-12 col-md-6 col-lg-4'>
              <div className='media-news-link' >
                <Link to="https://www.linkedin.com/feed/update/urn:li:activity:7093498190582153216/" target="_blank"  ><img src='/gallery/MEDIA NEWS/Oman-Brand-Leadership-Award_Lin.jpg'></img></Link>
                <div className='pt-2 justify-content-between' >
                  <h6 style={{ fontFamily: t('fontFamilyone') }}>{t("Oman Brand Leadership Award ")} -</h6>

                  <Link to="https://www.linkedin.com/feed/update/urn:li:activity:7093498190582153216/" target="_blank"><p>https://www.linkedin.com/feed/update/
                    <br></br>
                    urn:li:activity:7093498190582153216/</p></Link>
                </div>
              </div>
            </div>


          </div>

        </div>


      </div>


      <Aboutenquiry />
      <Footer />



    </div >
  )
}

export default Medianews