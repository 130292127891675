
import React, { useState } from 'react';


import Aboutenquiry from '../About/Aboutenquiry'
import { Tab, Nav } from 'react-bootstrap';

import { Col, Row, Carousel } from 'react-bootstrap'

import Footer from "../Includes/Footer";
import { useTranslation } from 'react-i18next';
function Cornblendoil() {

    const { t, i18n } = useTranslation();


    const [selectedImagediyah, setSelectedImagediyah] = useState('/oilproduct-details/CORNBLEND OIL/CORNBLEND OIL/PRODUCT IMAGES/DIYAH/Diyah CBO 1p5L.jpg');
    const [selectedImagekareem, setSelectedImagekareem] = useState('/oilproduct-details/CORNBLEND OIL/CORNBLEND OIL/PRODUCT IMAGES/KAREEM/Kareem CBO 1p8L.jpg');


    const handleImageChangediyah = (imageName) => {
        setSelectedImagediyah(imageName);
    };

    const handleImageChangekareem = (imageName) => {
        setSelectedImagekareem(imageName);
    };







    return (
        <div>



            <div className="headertop" >
                <Carousel className="slider ">
                    <Carousel.Item>
                        <img className="d-block w-100  " src="/oilproduct-details/CORNBLEND OIL/CORNBLEND OIL/BANNER/cornblendnew.jpg" alt="ogfc-img" />

                        <div className="carousel-caption caption-left">
                            <div class="left-line4"></div>
                            <h1 className='linedown qualityhead' style={{ color: '#000', fontFamily: t('fontFamilytwo') }}>{t("CORNBLEND OIL")}
                            </h1>

                        </div>

                    </Carousel.Item>
                </Carousel>
            </div>








            <Tab.Container defaultActiveKey="first">

                <Row>
                    <Col sm={12}>
                        <Tab.Content>

                            <Tab.Pane eventKey="first">
                                <div className="container  py-5">
                                    <div className="row">
                                        <div className="col-md-6 product-details product-details-heading">
                                            <img style={{
                                                border: '1px solid #f2f2f2',
                                                borderRadius: '30px'
                                            }} src="/oilproduct-details/CORNBLEND OIL/CORNBLEND OIL/LOGO/Logo  Diyah.png" alt="ogfc-img" />
                                            <h1 style={{ fontFamily: t('fontFamilyone') }}>{t("CORNBLEND OIL")}</h1>
                                            <h6 style={{ fontFamily: t('fontFamilyone') }}>{t("Ingredients :")}</h6>

                                            <h6 style={{ fontFamily: t('fontFamilyone') }}>{t("D.F.Olein, Refined Sunflower Oil,Corn oil, Antioxidant (E319),")} <br></br>{t("Vitamin A & D")}</h6>


                                            <div class="productfree">
                                                <h2 className="rise" style={{ fontFamily: t('fontFamilytwo') }}>{t("FREE FROM TRANS FAT")}</h2>
                                            </div>





                                            <img className="detail-view" src="/oilproduct-details/CORNBLEND OIL/CORNBLEND OIL/TABLE/Cornblend Oil Table.jpg" alt="ogfc-img" />
                                        </div>
                                        <div style={{ textAlign: "center" }} className="tab-main col-md-6 ">


                                            <div className="availabletin">


                                                <div className="image-container">
                                                    <img src={selectedImagediyah} alt="ogfc-img" />
                                                    <div className="overlay"></div>
                                                    <div className="button-container">
                                                        <span style={{ fontWeight: 600 }}>Available SKU’s</span>:



                                                        <button onMouseOver={() => handleImageChangediyah('/oilproduct-details/CORNBLEND OIL/CORNBLEND OIL/PRODUCT IMAGES/DIYAH/Diyah CBO 1p5L.jpg')}>1.5L</button>
                                                        <button onMouseOver={() => handleImageChangediyah('/oilproduct-details/CORNBLEND OIL/CORNBLEND OIL/PRODUCT IMAGES/DIYAH/Diyah CBO 1p8L.jpg')}>1.8L</button>
                                                        <button onMouseOver={() => handleImageChangediyah('/oilproduct-details/CORNBLEND OIL/CORNBLEND OIL/PRODUCT IMAGES/DIYAH/Diyah CBO 20L.jpg')}> 20L</button>
                                                    </div>
                                                </div>

                                            </div>



                                        </div>
                                    </div>
                                </div>
                            </Tab.Pane>

                            <Tab.Pane eventKey="second">
                                <div className="container  py-5">
                                    <div className="row">
                                        <div className="col-md-6 product-details product-details-heading">
                                            <img style={{
                                                border: '1px solid #f2f2f2',
                                                borderRadius: '30px'
                                            }} src="/oilproduct-details/CORNBLEND OIL/CORNBLEND OIL/LOGO/Logo  Kareem.png" alt="ogfc-img" />
                                            <h1 style={{ fontFamily: t('fontFamilyone') }}>{t("CORNBLEND OIL")}</h1>
                                            <h6 style={{ fontFamily: t('fontFamilyone') }}>{t("Ingredients :")}</h6>

                                            <h6 style={{ fontFamily: t('fontFamilyone') }}>{t("D.F.Olein, Refined Sunflower Oil,Corn oil, Antioxidant (E319),")} <br></br>{t("Vitamin A & D")}</h6>


                                            <div class="productfree">
                                                <h2 className="rise" style={{ fontFamily: t('fontFamilytwo') }}>{t("FREE FROM TRANS FAT")}</h2>
                                            </div>
                                            <img className="detail-view" src="/oilproduct-details/CORNBLEND OIL/CORNBLEND OIL/TABLE/Cornblend Oil Table.jpg" alt="ogfc-img" />
                                        </div>
                                        <div style={{ textAlign: "center" }} className="tab-main col-md-6 ">


                                            <div className="availabletin">


                                                <div className="image-container">
                                                    <img src={selectedImagekareem} alt="ogfc-img" />
                                                    <div className="overlay"></div>
                                                    <div className="button-container">
                                                        <span style={{ fontWeight: 600 }}>Available SKU’s</span>:




                                                        <button onMouseOver={() => handleImageChangekareem('/oilproduct-details/CORNBLEND OIL/CORNBLEND OIL/PRODUCT IMAGES/KAREEM/Kareem CBO 1p8L.jpg')}>1.5L</button>

                                                    </div>
                                                </div>

                                            </div>



                                        </div>
                                    </div>
                                </div>
                            </Tab.Pane>








                        </Tab.Content>
                    </Col>
                </Row>

                <Row>
                    <Col sm={12}>

                        <Nav style={{ padding: '0px' }} variant="pills" className="d-flex justify-content-center">


                            <Nav.Item style={{ padding: '10px 5px ' }}>
                                <Nav.Link eventKey="first" style={{ background: "#fff", padding: "0px" }}>

                                    <img style={{
                                        width: '100%',
                                        border: '1px solid #f2f2f2',
                                        borderRadius: '30px'
                                    }} src="/oilproduct-details/CORNBLEND OIL/CORNBLEND OIL/LOGO/Logo  Diyah.png" alt="ogfc-img" />
                                </Nav.Link>
                            </Nav.Item>


                            <Nav.Item style={{ padding: '10px 5px' }}>
                                <Nav.Link eventKey="second" style={{ background: "#fff", padding: "0px" }}>
                                    <img style={{
                                        width: '100%',
                                        border: '1px solid #f2f2f2',
                                        borderRadius: '30px'
                                    }} src="/oilproduct-details/CORNBLEND OIL/CORNBLEND OIL/LOGO/Logo  Kareem.png" alt="ogfc-img" />
                                </Nav.Link>
                            </Nav.Item>

                        </Nav>

                    </Col>
                </Row>
            </Tab.Container >

            < Aboutenquiry />
            <Footer />





        </div>
    )
}
<>
    <span style={{ fontWeight: '600', marginRight: '10px', }}>Available SKU’s </span>
</>










export default Cornblendoil