
import React, { useState } from 'react';


import Aboutenquiry from '../About/Aboutenquiry'
import { Tab, Nav } from 'react-bootstrap';

import { Col, Row, Carousel } from 'react-bootstrap'

import Footer from "../Includes/Footer";
import { useTranslation } from 'react-i18next';

function Oilproductdetail() {

    const { t, i18n } = useTranslation();



    const [selectedImage, setSelectedImage] = useState('/oilproduct-details/AL SAFA PRODUCT IMAGE/Al-Safa-Palmolein-750ml.jpg');
    const [selectedImagenoof, setSelectedImagenoof] = useState('/oilproduct-details/NOOF PRODUCT IMAGE/Noof-Palm-Olein-750ml.jpg');


    const [selectedImagezain, setSelectedImagezain] = useState('/oilproduct-details/ZAIN PRODUCT IMAGE/Zain-Palmolein-1p5L-PET.jpg');
    const [selectedImagealmanal, setSelectedImagealmanal] = useState('/oilproduct-details/AL MANAL PRODUCT IMAGE/Al-Manal-Palmolein-1p5L.jpg');
    const [selectedImageshifa, setSelectedImageshifa] = useState('/oilproduct-details/AL MANAL PRODUCT IMAGE/Al-Manal-Palmolein-1p5L.jpg');
    const [selectedImagesalalah, setSelectedImagesalalah] = useState('/oilproduct-details/SALALAH PRODUCT IMAGES/Salalah Palm Olein 1p5l.jpg');
    const [selectedImagealbarkat, setSelectedImagealbarkat] = useState('/oilproduct-details/AL BARAKAT PRODUCT IMAGE/Al Barakat Palm Olein 750ml.jpg');
    const [selectedImagesultanah, setSelectedImagesultanah] = useState('/oilproduct-details/SULTANAH PRODUCT IMAGES/Sultanah Palm olein 1p8L.jpg');
    const [selectedImageasaadah, setSelectedImageasaadah] = useState('/oilproduct-details/ASAADAH PRODUCT IMAGES/ASaadah Palm Olein 1p8L.jpg');

    const [selectedImageluliya, setSelectedImageluliya] = useState('/oilproduct-details/LULIYA PRODUCT IMAGES/Luliya Palm Olein 18L Tin.jpg');

    const handleImageChange = (imageName) => {
        setSelectedImage(imageName);
    };
    const handleImageChangenoof = (imageName) => {
        setSelectedImagenoof(imageName);
    };
    const handleImageChangezain = (imageName) => {
        setSelectedImagezain(imageName);
    };
    const handleImageChangealmanal = (imageName) => {
        setSelectedImagealmanal(imageName);
    };
    const handleImageChangeshifa = (imageName) => {
        setSelectedImageshifa(imageName);
    };

    const handleImageChangesalalah = (imageName) => {
        setSelectedImagesalalah(imageName);
    };
    const handleImageChangealbarkat = (imageName) => {
        setSelectedImagealbarkat(imageName);
    };
    const handleImageChangesultanah = (imageName) => {
        setSelectedImagesultanah(imageName);
    };
    const handleImageChangeasaadah = (imageName) => {
        setSelectedImageasaadah(imageName);
    };
    const handleImageChangeluliya = (imageName) => {
        setSelectedImageluliya(imageName);
    };






    return (
        <div>


            <div className="headertop" >
                <Carousel className="slider ">
                    <Carousel.Item>
                        <img className="d-block w-100  " src="/oilproducts/oilslidernew.jpg" alt="ogfc-img" />

                        <div className="carousel-caption caption-left">
                            <div class="left-line2"></div>
                            <h1 className='linedown' style={{ fontFamily: t('fontFamilytwo') }} >{t("PALM OLEIN")}
                            </h1>

                        </div>

                    </Carousel.Item>
                </Carousel>
            </div>









            <Tab.Container defaultActiveKey="first">

                <Row>
                    <Col sm={12}>
                        <Tab.Content>

                            <Tab.Pane eventKey="first">
                                <div className="container  py-5">


                                    <div className="row">
                                        <div className="col-md-6 product-details product-details-heading">
                                            <img src="/oilproduct-details/Logo  Al-Safa.png" alt="ogfc-img" />
                                            <h1 style={{ fontFamily: t('fontFamilyone') }}>{t("PALM OLEIN")} <br></br>{t("VEGETABLE OIL")}</h1>

                                            <h6 style={{ fontFamily: t('fontFamilyone') }}>{t("Ingredients :")}</h6>
                                            <h6 style={{ fontFamily: t('fontFamilyone') }}>
                                                {t("Refined Palm Olein,Antioxidant(E321)")}<br></br>{t("Vitamin A & D")}


                                            </h6>
                                            <div class="productfree">
                                                <h2 className="rise" style={{ fontFamily: t('fontFamilytwo') }}>{t("FREE FROM TRANS FAT")}</h2>
                                            </div>
                                            <img className="detail-view" src="/oilproduct-details/Palm Olein Table.jpg" alt="ogfc-img" />
                                        </div>
                                        <div style={{ textAlign: "center" }} className="tab-main col-md-6 ">




                                            <Row className="py-3 px-0" >
                                                <div className="availabletin">


                                                    <div className="image-container">
                                                        <img src={selectedImage} alt="ogfc-img" />
                                                        <div className="overlay"></div>
                                                        <div className="button-container">
                                                            <span style={{ fontWeight: 600 }}>{t("Available SKU’s")}</span>:


                                                            <button onMouseOver={() => handleImageChange('/oilproduct-details/AL SAFA PRODUCT IMAGE/Al-Safa-Palmolein-750ml.jpg')}>750ML</button>
                                                            <button onMouseOver={() => handleImageChange('/oilproduct-details/AL SAFA PRODUCT IMAGE/Al-Safa-Palmolein-1L.jpg')}>1L</button>
                                                            <button onMouseOver={() => handleImageChange('/oilproduct-details/AL SAFA PRODUCT IMAGE/Al-Safa-Palmolein-1p5L-PET.jpg')}>1.5L PET</button>
                                                            <button onMouseOver={() => handleImageChange('/oilproduct-details/AL SAFA PRODUCT IMAGE/Al-Safa-Palmolein-1p5L-HDPE.jpg')}>1.5L HDPE</button>
                                                            <button onMouseOver={() => handleImageChange('/oilproduct-details/AL SAFA PRODUCT IMAGE/Al-Safa-Palmolein-1p8L-HDPE.jpg')}>1.8L</button>
                                                            <button onMouseOver={() => handleImageChange('/oilproduct-details/AL SAFA PRODUCT IMAGE/Al-Safa-Palmolein-3L-HDPE.jpg')}>3L</button>
                                                            <button onMouseOver={() => handleImageChange('/oilproduct-details/AL SAFA PRODUCT IMAGE/Al-Safa-Palmolein-4L-HDPE.jpg')}>4L</button>
                                                            <button onMouseOver={() => handleImageChange('/oilproduct-details/AL SAFA PRODUCT IMAGE/Al-Safa-Palmolein-5L-HDPE.jpg')}>5L</button>

                                                            <button onMouseOver={() => handleImageChange('/oilproduct-details/AL SAFA PRODUCT IMAGE/Al-Safa-Palmolein-8Lnew.jpg')}>8L</button>
                                                            <button onMouseOver={() => handleImageChange('/oilproduct-details/AL SAFA PRODUCT IMAGE/Al-Safa-Palmolein-10L.jpg')}>10L</button>

                                                            <button onMouseOver={() => handleImageChange('/oilproduct-details/AL SAFA PRODUCT IMAGE/Al-Safa-BIB-10L.jpg')}>10L BIB</button>
                                                            <button onMouseOver={() => handleImageChange('/oilproduct-details/AL SAFA PRODUCT IMAGE/Al-Safa-Palmolein-18L Tin.jpg')}>18L Tin</button>
                                                            <button onMouseOver={() => handleImageChange('/oilproduct-details/AL SAFA PRODUCT IMAGE/Al-Safa-Palmolein-18L-Jar.jpg')}>18L Jar</button>
                                                            <button onMouseOver={() => handleImageChange('/oilproduct-details/AL SAFA PRODUCT IMAGE/Al-Safa-Palmolein-20L-Jar.jpg')}>20L</button>



                                                        </div>
                                                    </div>

                                                </div>
                                            </Row>

                                        </div>
                                    </div>
                                </div>
                            </Tab.Pane>

                            <Tab.Pane eventKey="second">
                                <div className="container  py-5">
                                    <div className="row">
                                        <div className="col-md-6 product-details product-details-heading">
                                            <img src="/oilproduct-details/Logo  Noof.png" alt="Slide 1" />
                                            <h1 style={{ fontFamily: t('fontFamilyone') }}>{t("PALM OLEIN")} <br></br>{t("VEGETABLE OIL")}</h1>

                                            <h6 style={{ fontFamily: t('fontFamilyone') }}>{t("Ingredients :")}</h6>
                                            <h6 style={{ fontFamily: t('fontFamilyone') }}>
                                                {t("Refined Palm Olein,Antioxidant(E321)")}<br></br>{t("Vitamin A & D")}


                                            </h6>
                                            <div class="productfree">
                                                <h2 className="rise" style={{ fontFamily: t('fontFamilytwo') }}>{t("FREE FROM TRANS FAT")}</h2>
                                            </div>
                                            <img className="detail-view" src="/oilproduct-details/Palm Olein Table.jpg" alt="ogfc-img" />
                                        </div>
                                        <div style={{ textAlign: "center" }} className="tab-main col-md-6 ">

                                            <Row className="py-3 px-0" >
                                                <div className="availabletin">
                                                    <div className="image-container">
                                                        <img src={selectedImagenoof} alt="ogfc-img" />
                                                        <div className="overlay"></div>
                                                        <div className="button-container">


                                                            <span style={{ fontWeight: 600 }}>{t("Available SKU’s")}</span>:
                                                            <button onMouseOver={() => handleImageChangenoof('/oilproduct-details/NOOF PRODUCT IMAGE/Noof-Palm-Olein-750ml.jpg')}>750ML</button>
                                                            <button onMouseOver={() => handleImageChangenoof('/oilproduct-details/NOOF PRODUCT IMAGE/Noof-Palm-Olein-1p5L PET.jpg')}>1.5L PET</button>
                                                            <button onMouseOver={() => handleImageChangenoof('/oilproduct-details/NOOF PRODUCT IMAGE/Noof-Palm-Olein-1p5L HDPE.jpg')}>1.5L HDPE</button>
                                                            <button onMouseOver={() => handleImageChangenoof('/oilproduct-details/NOOF PRODUCT IMAGE/Noof-Palm-Olein-1p7L.jpg')}>1.7L</button>
                                                            <button onMouseOver={() => handleImageChangenoof('/oilproduct-details/NOOF PRODUCT IMAGE/Noof-Palm-Olein-1p8L.jpg')}>1.8L</button>
                                                            <button onMouseOver={() => handleImageChangenoof('/oilproduct-details/NOOF PRODUCT IMAGE/Noof-Palm-Olein-3L.jpg')}>3L</button>
                                                            <button onMouseOver={() => handleImageChangenoof('/oilproduct-details/NOOF PRODUCT IMAGE/Noof-Palm-Olein-4L.jpg')}>4L</button>
                                                            <button onMouseOver={() => handleImageChangenoof('/oilproduct-details/NOOF PRODUCT IMAGE/Noof-Palm-Olein-5L.jpg')}>5L</button>
                                                            <button onMouseOver={() => handleImageChangenoof('/oilproduct-details/NOOF PRODUCT IMAGE/Noof-Palm-Olein-17L Tin.jpg')}>17L Tin</button>
                                                            <button onMouseOver={() => handleImageChangenoof('/oilproduct-details/NOOF PRODUCT IMAGE/Noof-Palm-Olein-18L.jpg')}>18L</button>
                                                            <button onMouseOver={() => handleImageChangenoof('/oilproduct-details/NOOF PRODUCT IMAGE/Noof-Palm-Olein-20L.jpg')}>20L</button>

                                                        </div>
                                                    </div>
                                                </div>
                                            </Row>

                                        </div>
                                    </div>
                                </div>
                            </Tab.Pane>



                            <Tab.Pane eventKey="third">
                                <div className="container  py-5">
                                    <div className="row">
                                        <div className="col-md-6 product-details product-details-heading">
                                            <img src="/oilproduct-details/Logo Zain.png" alt="ogfc-img" />
                                            <h1 style={{ fontFamily: t('fontFamilyone') }}>{t("PALM OLEIN")} <br></br>{t("VEGETABLE OIL")}</h1>

                                            <h6 style={{ fontFamily: t('fontFamilyone') }}>{t("Ingredients :")}</h6>
                                            <h6 style={{ fontFamily: t('fontFamilyone') }}>
                                                {t("Refined Palm Olein,Antioxidant(E321)")}<br></br>{t("Vitamin A & D")}


                                            </h6>
                                            <div class="productfree">
                                                <h2 className="rise" style={{ fontFamily: t('fontFamilytwo') }}>{t("FREE FROM TRANS FAT")}</h2>
                                            </div>
                                            <img className="detail-view" src="/oilproduct-details/Palm Olein Table.jpg" alt="ogfc-img" />
                                        </div>
                                        <div style={{ textAlign: "center" }} className="tab-main col-md-6 ">

                                            <Row className="py-3 px-0" >
                                                <div className="availabletin">
                                                    <div className="image-container">
                                                        <img src={selectedImagezain} alt="ogfc-img" />
                                                        <div className="overlay"></div>
                                                        <div className="button-container">



                                                            <span style={{ fontWeight: 600 }}>{t("Available SKU’s")}</span>:
                                                            <button onMouseOver={() => handleImageChangezain('/oilproduct-details/ZAIN PRODUCT IMAGE/Zain-Palmolein-1p5L-PET.jpg')}>1.5L PET</button>
                                                            <button onMouseOver={() => handleImageChangezain('/oilproduct-details/ZAIN PRODUCT IMAGE/Zain-Palmolein-1p5L-HDPE.jpg')}>1.5L HDPE</button>

                                                            <button onMouseOver={() => handleImageChangezain('/oilproduct-details/ZAIN PRODUCT IMAGE/Zain-Palmolein-1p8L-HDPE.jpg')}>1.8L</button>
                                                            <button onMouseOver={() => handleImageChangezain('/oilproduct-details/ZAIN PRODUCT IMAGE/Zain-Palmolein-3L.jpg')}>3L</button>
                                                            <button onMouseOver={() => handleImageChangezain('/oilproduct-details/ZAIN PRODUCT IMAGE/Zain-Palmolein-17L Tin.jpg')}>17L Tin</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Row>



                                        </div>
                                    </div>
                                </div>
                            </Tab.Pane>



                            <Tab.Pane eventKey="fourth">
                                <div className="container  py-5">
                                    <div className="row">
                                        <div className="col-md-6 product-details product-details-heading">
                                            <img src="/oilproduct-details/Logo Al-Manal.png" alt="ogfc-img" />
                                            <h1 style={{ fontFamily: t('fontFamilyone') }}>{t("PALM OLEIN")} <br></br>{t("VEGETABLE OIL")}</h1>

                                            <h6 style={{ fontFamily: t('fontFamilyone') }}>{t("Ingredients :")}</h6>
                                            <h6 style={{ fontFamily: t('fontFamilyone') }}>
                                                {t("Refined Palm Olein,Antioxidant(E321)")}<br></br>{t("Vitamin A & D")}


                                            </h6>
                                            <div class="productfree">
                                                <h2 className="rise" style={{ fontFamily: t('fontFamilytwo') }}>{t("FREE FROM TRANS FAT")}</h2>
                                            </div>
                                            <img className="detail-view" src="/oilproduct-details/Palm Olein Table.jpg" alt="ogfc-img" />
                                        </div>
                                        <div style={{ textAlign: "center" }} className="tab-main col-md-6 ">
                                            <Row className="py-3 px-0" >
                                                <div className="availabletin">
                                                    <div className="image-container">
                                                        <img src={selectedImagealmanal} alt="ogfc-img" />
                                                        <div className="overlay"></div>
                                                        <div className="button-container">



                                                            <span style={{ fontWeight: 600 }}>{t("Available SKU’s")}</span>:
                                                            <button onMouseOver={() => handleImageChangealmanal('/oilproduct-details/AL MANAL PRODUCT IMAGE/Al-Manal-Palmolein-1p5L.jpg')}>1.5L</button>
                                                            <button onMouseOver={() => handleImageChangealmanal('/oilproduct-details/AL MANAL PRODUCT IMAGE/Al-Manal-Palmolein-4L.jpg')}>4L</button>
                                                            <button onMouseOver={() => handleImageChangealmanal('/oilproduct-details/AL MANAL PRODUCT IMAGE/Al-Safa-Palmolein-8L.jpg')}>8L</button>
                                                            <button onMouseOver={() => handleImageChangealmanal('/oilproduct-details/AL MANAL PRODUCT IMAGE/Al-Manal-Palmolein-17L Tin.jpg')}>17L</button>
                                                            <button onMouseOver={() => handleImageChangealmanal('/oilproduct-details/AL MANAL PRODUCT IMAGE/Al-Safa-Palmolein-20L.jpg')}>20L</button>

                                                        </div>
                                                    </div>
                                                </div>
                                            </Row>






                                        </div>
                                    </div>
                                </div>
                            </Tab.Pane>



                            <Tab.Pane eventKey="five">

                                <div className="container  py-5">
                                    <div className="row">
                                        <div className="col-md-6 product-details product-details-heading">
                                            <img src="/oilproduct-details/Logo  Shifa.png" alt="ogfc-img" />
                                            <h1 style={{ fontFamily: t('fontFamilyone') }}>{t("PALM OLEIN")} <br></br>{t("VEGETABLE OIL")}</h1>

                                            <h6 style={{ fontFamily: t('fontFamilyone') }}>{t("Ingredients :")}</h6>
                                            <h6 style={{ fontFamily: t('fontFamilyone') }}>
                                                {t("Refined Palm Olein,Antioxidant(E321)")}<br></br>{t("Vitamin A & D")}


                                            </h6>
                                            <div class="productfree">
                                                <h2 className="rise" style={{ fontFamily: t('fontFamilytwo') }}>{t("FREE FROM TRANS FAT")}</h2>
                                            </div>
                                            <img className="detail-view" src="/oilproduct-details/Palm Olein Table.jpg" alt="ogfc-img" />
                                        </div>
                                        <div style={{ textAlign: "center" }} className="tab-main col-md-6 ">
                                            <Row className="py-3 px-0" >
                                                <div className="availabletin">
                                                    <div className="image-container">
                                                        <img src={selectedImageshifa} alt="ogfc-img" />
                                                        <div className="overlay"></div>
                                                        <div className="button-container">



                                                            <span style={{ fontWeight: 600 }}>{t("Available SKU’s")}</span>:
                                                            <button onMouseOver={() => handleImageChangeshifa('/oilproduct-details/SHIFA PRODUCT IMAGES/Shifa Palm Olein 1p8L.jpg')}>1.5L PET</button>
                                                            <button onMouseOver={() => handleImageChangeshifa('/oilproduct-details/SHIFA PRODUCT IMAGES/Shifa Palm Olein 1p8L.jpg')}>1.8L</button>
                                                            <button onMouseOver={() => handleImageChangeshifa('/oilproduct-details/SHIFA PRODUCT IMAGES/Shifa Palm Olein 3L.jpg')}>3L</button>
                                                            <button onMouseOver={() => handleImageChangeshifa('/oilproduct-details/SHIFA PRODUCT IMAGES/Shifa Palm Olein 8L.jpg')}>8L</button>
                                                            <button onMouseOver={() => handleImageChangeshifa('/oilproduct-details/SHIFA PRODUCT IMAGES/Shifa Palm Olein 17L.jpg')}>17L Tin</button>
                                                            <button onMouseOver={() => handleImageChangeshifa('/oilproduct-details/SHIFA PRODUCT IMAGES/Shifa Palm Olein 18L Gross.jpg')}>18L Tin</button>
                                                            <button onMouseOver={() => handleImageChangeshifa('/oilproduct-details/SHIFA PRODUCT IMAGES/Shifa Palm Olein 20L.jpg')}>20L</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Row>


                                        </div>
                                    </div>
                                </div>
                            </Tab.Pane>


                            <Tab.Pane eventKey="six">

                                <div className="container  py-5">
                                    <div className="row">
                                        <div className="col-md-6 product-details product-details-heading">
                                            <img src="/oilproduct-details/Logo  Salalah.png" alt="ogfc-img" />
                                            <h1 style={{ fontFamily: t('fontFamilyone') }}>{t("PALM OLEIN")} <br></br>{t("VEGETABLE OIL")}</h1>

                                            <h6 style={{ fontFamily: t('fontFamilyone') }}>{t("Ingredients :")}</h6>
                                            <h6 style={{ fontFamily: t('fontFamilyone') }}>
                                                {t("Refined Palm Olein,Antioxidant(E321)")}<br></br>{t("Vitamin A & D")}


                                            </h6>
                                            <div class="productfree">
                                                <h2 className="rise" style={{ fontFamily: t('fontFamilytwo') }}>{t("FREE FROM TRANS FAT")}</h2>
                                            </div>
                                            <img className="detail-view" src="/oilproduct-details/Palm Olein Table.jpg" alt="ogfc-img" />
                                        </div>
                                        <div style={{ textAlign: "center" }} className="tab-main col-md-6 ">



                                            <Row className="py-3 px-0" >
                                                <div className="availabletin">
                                                    <div className="image-container">
                                                        <img src={selectedImagesalalah} alt="ogfc-img" />
                                                        <div className="overlay"></div>
                                                        <div className="button-container">



                                                            <span style={{ fontWeight: 600 }}>{t("Available SKU’s")}</span>:
                                                            <button onMouseOver={() => handleImageChangesalalah('/oilproduct-details/SALALAH PRODUCT IMAGES/Salalah Palm Olein 1p5l.jpg')}>1.5L</button>
                                                            <button onMouseOver={() => handleImageChangesalalah('/oilproduct-details/SALALAH PRODUCT IMAGES/Salalah Palm Olein 1p8L.jpg')}>1.8L</button>
                                                            <button onMouseOver={() => handleImageChangesalalah('/oilproduct-details/SALALAH PRODUCT IMAGES/Salalah Palm Olein 3L.jpg')}>3L</button>
                                                            <button onMouseOver={() => handleImageChangesalalah('/oilproduct-details/SALALAH PRODUCT IMAGES/Salalah Palm Olein 4L PET.jpg')}>4L PET</button>


                                                           
                                                            <button onMouseOver={() => handleImageChangesalalah('/oilproduct-details/SALALAH PRODUCT IMAGES/Salalah Palm Olein 5L.jpg')}>5L</button>
                                                            <button onMouseOver={() => handleImageChangesalalah('/oilproduct-details/SALALAH PRODUCT IMAGES/Salalah Palm Olein 8L.jpg')}>8L</button>
                                                            <button onMouseOver={() => handleImageChangesalalah('/oilproduct-details/SALALAH PRODUCT IMAGES/Salalah Palm Olein 10l.jpg')}>10L</button>
                                                            <button onMouseOver={() => handleImageChangesalalah('/oilproduct-details/SALALAH PRODUCT IMAGES/Salalah Palm Olein 18L Tin.jpg')}>18L Tin</button>
                                                            <button onMouseOver={() => handleImageChangesalalah('/oilproduct-details/SALALAH PRODUCT IMAGES/Salalah Palm Olein 20L.jpg')}>20L</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Row>




                                        </div>
                                    </div>
                                </div>





                            </Tab.Pane>
                            <Tab.Pane eventKey="seven">

                                <div className="container  py-5">
                                    <div className="row">
                                        <div className="col-md-6 product-details product-details-heading">
                                            <img src="/oilproduct-details/Logo Al-Barakat.png" alt="ogfc-img" />
                                            <h1 style={{ fontFamily: t('fontFamilyone') }}>{t("PALM OLEIN")} <br></br>{t("VEGETABLE OIL")}</h1>

                                            <h6 style={{ fontFamily: t('fontFamilyone') }}>{t("Ingredients :")}</h6>
                                            <h6 style={{ fontFamily: t('fontFamilyone') }}>
                                                {t("Refined Palm Olein,Antioxidant(E321)")}<br></br>{t("Vitamin A & D")}


                                            </h6>
                                            <div class="productfree">
                                                <h2 className="rise" style={{ fontFamily: t('fontFamilytwo') }}>{t("FREE FROM TRANS FAT")}</h2>
                                            </div>
                                            <img className="detail-view" src="/oilproduct-details/Palm Olein Table.jpg" alt="ogfc-img" />
                                        </div>
                                        <div style={{ textAlign: "center" }} className="tab-main col-md-6 ">

                                            <Row className="py-3 px-0" >
                                                <div className="availabletin">
                                                    <div className="image-container">
                                                        <img src={selectedImagealbarkat} alt="ogfc-img" />
                                                        <div className="overlay"></div>
                                                        <div className="button-container">



                                                            <span style={{ fontWeight: 600 }}>{t("Available SKU’s")}</span>:
                                                            <button onMouseOver={() => handleImageChangealbarkat('/oilproduct-details/AL BARAKAT PRODUCT IMAGE/Al Barakat Palm Olein 750ml.jpg')}>750ML</button>
                                                            <button onMouseOver={() => handleImageChangealbarkat('/oilproduct-details/AL BARAKAT PRODUCT IMAGE/Al Barakat Palm Olein 1p5L PET.jpg')}>1.5L PET</button>
                                                            <button onMouseOver={() => handleImageChangealbarkat('/oilproduct-details/AL BARAKAT PRODUCT IMAGE/Al Barakat Palm Olein 1p5L HDPE.jpg')}>1.5L HDPE</button>
                                                            <button onMouseOver={() => handleImageChangealbarkat('/oilproduct-details/AL BARAKAT PRODUCT IMAGE/Al Barakat Palm Olein 3L.jpg')}>3L</button>

                                                            <button onMouseOver={() => handleImageChangealbarkat('/oilproduct-details/AL BARAKAT PRODUCT IMAGE/Al Barakat Palm Olein 4L PET.jpg')}>4L PET</button>
                                                            <button onMouseOver={() => handleImageChangealbarkat('/oilproduct-details/AL BARAKAT PRODUCT IMAGE/Al Barakat Palm Olein 4L HDPE.jpg')}>4L HDPE</button>
                                                            <button onMouseOver={() => handleImageChangealbarkat('/oilproduct-details/AL BARAKAT PRODUCT IMAGE/Al Barakat Palm Olein 5L.jpg')}>5L</button>
                                                            <button onMouseOver={() => handleImageChangealbarkat('/oilproduct-details/AL BARAKAT PRODUCT IMAGE/Al Barakat Palm Olein 8L.jpg')}>8L</button>
                                                            <button onMouseOver={() => handleImageChangealbarkat('/oilproduct-details/AL BARAKAT PRODUCT IMAGE/Al Barakat Palm Olein 10L.jpg')}>10L</button>
                                                            <button onMouseOver={() => handleImageChangealbarkat('/oilproduct-details/AL BARAKAT PRODUCT IMAGE/Al Barakat Palm Olein 16L.jpg')}>16L</button>
                                                            <button onMouseOver={() => handleImageChangealbarkat('/oilproduct-details/AL BARAKAT PRODUCT IMAGE/Al Barakat Palm Olein 20L.jpg')}>20L</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Row>




                                        </div>
                                    </div>
                                </div>

                            </Tab.Pane>
                            <Tab.Pane eventKey="eight">

                                <div className="container  py-5">
                                    <div className="row">
                                        <div className="col-md-6 product-details product-details-heading">
                                            <img src="/oilproduct-details/Logo  Sultanah.png" alt="ogfc-img" />
                                            <h1 style={{ fontFamily: t('fontFamilyone') }}>{t("PALM OLEIN")} <br></br>{t("VEGETABLE OIL")}</h1>

                                            <h6 style={{ fontFamily: t('fontFamilyone') }}>{t("Ingredients :")}</h6>
                                            <h6 style={{ fontFamily: t('fontFamilyone') }}>
                                                {t("Refined Palm Olein,Antioxidant(E321)")}<br></br>{t("Vitamin A & D")}


                                            </h6>
                                            <div class="productfree">
                                                <h2 className="rise" style={{ fontFamily: t('fontFamilytwo') }}>{t("FREE FROM TRANS FAT")}</h2>
                                            </div>
                                            <img className="detail-view" src="/oilproduct-details/Palm Olein Table.jpg" alt="ogfc-img" />
                                        </div>
                                        <div style={{ textAlign: "center" }} className="tab-main col-md-6 ">
                                            <Row className="py-3 px-0" >
                                                <div className="availabletin">
                                                    <div className="image-container">
                                                        <img src={selectedImagesultanah} alt="ogfc-img" />
                                                        <div className="overlay"></div>
                                                        <div className="button-container">

                                                            <span style={{ fontWeight: 600 }}>{t("Available SKU’s")}</span>:

                                                            <button onMouseOver={() => handleImageChangesultanah('/oilproduct-details/SULTANAH PRODUCT IMAGES/Sultanah Palm olein 1p8L.jpg')}>1.8L</button>
                                                            <button onMouseOver={() => handleImageChangesultanah('/oilproduct-details/SULTANAH PRODUCT IMAGES/Sultanah Palm olein 3L.jpg')}>3L</button>
                                                            <button onMouseOver={() => handleImageChangesultanah('/oilproduct-details/SULTANAH PRODUCT IMAGES/Sultanah Palm olein 5L.jpg')}>5L</button>
                                                            <button onMouseOver={() => handleImageChangesultanah('/oilproduct-details/SULTANAH PRODUCT IMAGES/Sultanah Palm olein 10L.jpg')}>10L</button>

                                                            <button onMouseOver={() => handleImageChangesultanah('/oilproduct-details/SULTANAH PRODUCT IMAGES/Sultanah Palm olein 16L.jpg')}>16L</button>
                                                            <button onMouseOver={() => handleImageChangesultanah('/oilproduct-details/SULTANAH PRODUCT IMAGES/Sultanah Palm olein 18L.jpg')}>18L</button>

                                                        </div>
                                                    </div>
                                                </div>
                                            </Row>






                                        </div>
                                    </div>
                                </div>

                            </Tab.Pane>
                            <Tab.Pane eventKey="nine">

                                <div className="container  py-5">
                                    <div className="row">
                                        <div className="col-md-6 product-details product-details-heading">
                                            <img src="/oilproduct-details/Logo A-Saadah.png" alt="ogfc-img" />
                                            <h1 style={{ fontFamily: t('fontFamilyone') }}>{t("PALM OLEIN")} <br></br>{t("VEGETABLE OIL")}</h1>

                                            <h6 style={{ fontFamily: t('fontFamilyone') }}>{t("Ingredients :")}</h6>
                                            <h6 style={{ fontFamily: t('fontFamilyone') }}>
                                                {t("Refined Palm Olein,Antioxidant(E321)")}<br></br>{t("Vitamin A & D")}


                                            </h6>
                                            <div class="productfree">
                                                <h2 className="rise" style={{ fontFamily: t('fontFamilytwo') }}>{t("FREE FROM TRANS FAT")}</h2>
                                            </div>
                                            <img className="detail-view" src="/oilproduct-details/Palm Olein Table.jpg" alt="ogfc-img" />
                                        </div>
                                        <div style={{ textAlign: "center" }} className="tab-main col-md-6 ">

                                            <Row className="py-3 px-0" >
                                                <div className="availabletin">
                                                    <div className="image-container">
                                                        <img src={selectedImageasaadah} alt="ogfc-img" />
                                                        <div className="overlay"></div>
                                                        <div className="button-container">


                                                            <span style={{ fontWeight: 600 }}>{t("Available SKU’s")}</span>:
                                                            <button onMouseOver={() => handleImageChangeasaadah('/oilproduct-details/ASAADAH PRODUCT IMAGES/ASaadah Palm Olein 1p8L.jpg')}>1.8L</button>
                                                            <button onMouseOver={() => handleImageChangeasaadah('/oilproduct-details/ASAADAH PRODUCT IMAGES/ASaadah Palm Olein 3L.jpg')}>3L</button>
                                                            <button onMouseOver={() => handleImageChangeasaadah('/oilproduct-details/ASAADAH PRODUCT IMAGES/ASaadah Palm Olein 5L.jpg')}>5L</button>
                                                            <button onMouseOver={() => handleImageChangeasaadah('/oilproduct-details/ASAADAH PRODUCT IMAGES/ASaadah Palm Olein 17L Tin.jpg')}>17L Tin</button>

                                                            <button onMouseOver={() => handleImageChangeasaadah('/oilproduct-details/ASAADAH PRODUCT IMAGES/ASaadah Palm Olein 20L.jpg')}>20L</button>

                                                        </div>
                                                    </div>
                                                </div>
                                            </Row>




                                        </div>
                                    </div>
                                </div>

                            </Tab.Pane>
                            <Tab.Pane eventKey="ten">
                                <div className="container  py-5">
                                    <div className="row">
                                        <div className="col-md-6 product-details product-details-heading">
                                            <img src="/oilproduct-details/Logo  Luliya.png" alt="ogfc-img" />
                                            <h1 style={{ fontFamily: t('fontFamilyone') }}>{t("PALM OLEIN")} <br></br>{t("VEGETABLE OIL")}</h1>

                                            <h6 style={{ fontFamily: t('fontFamilyone') }}>{t("Ingredients :")}</h6>
                                            <h6 style={{ fontFamily: t('fontFamilyone') }}>
                                                {t("Refined Palm Olein,Antioxidant(E321)")}<br></br>{t("Vitamin A & D")}


                                            </h6>
                                            <div class="productfree">
                                                <h2 className="rise" style={{ fontFamily: t('fontFamilytwo') }}>{t("FREE FROM TRANS FAT")}</h2>
                                            </div>
                                            <img className="detail-view" src="/oilproduct-details/Palm Olein Table.jpg" alt="ogfc-img" />
                                        </div>
                                        <div style={{ textAlign: "center" }} className="tab-main col-md-6 ">


                                            <Row className="py-3 px-0" >
                                                <div className="availabletin">
                                                    <div className="image-container">
                                                        <img src={selectedImageluliya} alt="ogfc-img" />
                                                        <div className="overlay"></div>
                                                        <div className="button-container">

                                                            <span style={{ fontWeight: 600 }}>{t("Available SKU’s")}</span>:
                                                            <button onMouseOver={() => handleImageChangeluliya('/oilproduct-details/LULIYA PRODUCT IMAGES/Luliya Palm Olein 18L Tin.jpg')}>17L</button>

                                                        </div>
                                                    </div>
                                                </div>
                                            </Row>

                                        </div>
                                    </div>
                                </div>
                            </Tab.Pane>

                        </Tab.Content>
                    </Col>
                </Row>

                <Row>
                    <Col sm={12}>

                        <Nav style={{ padding: '0px' }} variant="pills" className="d-flex justify-content-center">
                            <Nav.Item style={{ padding: '10px 5px ' }}>
                                <Nav.Link eventKey="first" style={{ background: "#fff", padding: "0px" }}>

                                    <img style={{ width: '100%' }} src="/oilproduct-details/Logo  Al-Safa.png" alt="ogfc-img" />
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item style={{ padding: '10px 5px' }}>
                                <Nav.Link eventKey="second" style={{ background: "#fff", padding: "0px" }}>
                                    <img src="/oilproduct-details/Logo  Noof.png" alt="ogfc-img" />
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item style={{ padding: '10px 5px' }}>
                                <Nav.Link eventKey="third" style={{ background: "#fff", padding: "0px" }}>
                                    <img src="/oilproduct-details/Logo Zain.png" alt="ogfc-img" />
                                </Nav.Link>
                            </Nav.Item>

                            <Nav.Item style={{ padding: '10px 5px' }}>
                                <Nav.Link eventKey="fourth" style={{ background: "#fff", padding: "0px" }}>

                                    <img src="/oilproduct-details/Logo Al-Manal.png" alt="ogfc-img" />
                                </Nav.Link>
                            </Nav.Item>

                            <Nav.Item style={{ padding: '10px 5px' }}>
                                <Nav.Link eventKey="five" style={{ background: "#fff", padding: "0px" }}>
                                    <img src="/oilproduct-details/Logo  Shifa.png" alt="ogfc-img" />

                                </Nav.Link>
                            </Nav.Item>

                            <Nav.Item style={{ padding: '10px 5px' }}>
                                <Nav.Link eventKey="six" style={{ background: "#fff", padding: "0px" }}>
                                    <img src="/oilproduct-details/Logo  Salalah.png" alt="ogfc-img" />

                                </Nav.Link>
                            </Nav.Item>



                            <Nav.Item style={{ padding: '10px 5px' }}>
                                <Nav.Link eventKey="seven" style={{ background: "#fff", padding: "0px" }}>

                                    <img src="/oilproduct-details/Logo Al-Barakat.png" alt="ogfc-img" />
                                </Nav.Link>
                            </Nav.Item>

                            <Nav.Item style={{ padding: '10px 5px' }}>
                                <Nav.Link eventKey="eight" style={{ background: "#fff", padding: "0px" }}>

                                    <img src="/oilproduct-details/Logo  Sultanah.png" alt="ogfc-img" />
                                </Nav.Link>
                            </Nav.Item>

                            <Nav.Item style={{ padding: '10px 5px' }}>
                                <Nav.Link eventKey="nine" style={{ background: "#fff", padding: "0px" }}>

                                    <img src="/oilproduct-details/Logo A-Saadah.png" alt="ogfc-img" />
                                </Nav.Link>
                            </Nav.Item>

                            <Nav.Item style={{ padding: '10px 5px' }}>
                                <Nav.Link eventKey="ten" style={{ background: "#fff", padding: "0px" }}>

                                    <img src="/oilproduct-details/Logo  Luliya.png" alt="ogfc-img" />
                                </Nav.Link>
                            </Nav.Item>


                        </Nav>

                    </Col>
                </Row>
            </Tab.Container >

            < Aboutenquiry />
            <Footer />





        </div>
    )
}




export default Oilproductdetail