import React from 'react';
import { Button } from 'react-bootstrap'
import { Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';
import { useState } from 'react';
function Newsandevents() {

  const { t, i18n } = useTranslation();

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalIsOpen1, setModalIsOpen1] = useState(false);
  const [modalIsOpen2, setModalIsOpen2] = useState(false);
  const [modalIsOpen3, setModalIsOpen3] = useState(false);

  const toggleModal = () => {

    setModalIsOpen3(false);
    setModalIsOpen2(false);
    setModalIsOpen1(false);
    setModalIsOpen(!modalIsOpen);
  };
  const toggleModal1 = () => {
    setModalIsOpen3(false);
    setModalIsOpen2(false);
    setModalIsOpen(false);
    setModalIsOpen1(!modalIsOpen1);
  };
  const toggleModal2 = () => {
    setModalIsOpen3(false);
    setModalIsOpen(false);
    setModalIsOpen1(false);
    setModalIsOpen2(!modalIsOpen2);
  };
  const toggleModal3 = () => {
    setModalIsOpen(false);
    setModalIsOpen2(false);
    setModalIsOpen1(false);
    setModalIsOpen3(!modalIsOpen3);
  };

  return (
    <div className='section'>
      <div className='about'>
        <div className='about-header'>
          <h1 style={{ fontFamily: t('fontFamily') }} >{t("OUR NEWS")}</h1>
        </div>
        <div className='newsmain'>
          <div className='container'>
            <Row>


              <Col xs={12} md={6} lg={3} className='ps-3'>

                <div className='nbox'>
                  <img src='/images/news/OGFC Participate Saudi Food Show 2024.jpg' onClick={toggleModal1} alt="ogfc-img"></img>
                  <p style={{ fontFamily: t('fontFamilyone') }}>{t("OGFC Participate Saudi Food Show 2024")}</p>
                </div>

                <Modal
                  isOpen={modalIsOpen1}
                  onRequestClose={toggleModal1}
                  contentLabel="Zoomed Image"
                  className="modal"
                  overlayClassName="overlay"
                >
                  <img src='/images/news/OGFC Participate Saudi Food Show 2024.jpg' alt="Zoomed In" />
                  <button className="close-button" onClick={toggleModal1} style={{ fontFamily: t('fontFamilyone') }}>
                    {t("Close")}
                  </button>
                </Modal>



              </Col>

            <Col xs={12} md={6} lg={3} className='ps-3'>

<div className='nbox'>
  <img src='/images/news/OGFC Participate Gulfood Exhibition 2024.jpg' onClick={toggleModal2} alt="ogfc-img"></img>
  <p style={{ fontFamily: t('fontFamilyone') }}>{t("OGFC Participate Gulfood Exhibition 2024")}</p>
</div>


<Modal
  isOpen={modalIsOpen2}
  onRequestClose={toggleModal2}
  contentLabel="Zoomed Image"
  className="modal"
  overlayClassName="overlay"
>
  <img src='/images/news/OGFC Participate Gulfood Exhibition 2024.jpg' alt="Zoomed In" />
  <button className="close-button" onClick={toggleModal2} style={{ fontFamily: t('fontFamilyone') }}>
    {t("Close")}
  </button>
</Modal>




</Col>

            <Col xs={12} md={6} lg={3} className='ps-3'>
                <div className='nbox'>
                    <img src='/images/news/Oman-Brand-Leadership-Award_In.jpg' onClick={toggleModal3} alt="ogfc-img"></img>
                    <p style={{ fontFamily: t('fontFamilyone') }}>{t("Most Admired Marketing Leaders Award_ World Marketing Congress 2023")}</p>
                  </div>
                  <Modal
                    isOpen={modalIsOpen3}
                    onRequestClose={toggleModal3}
                    contentLabel="Zoomed Image"
                    className="modal"
                    overlayClassName="overlay"
                  >
                    <img src='/images/news/Oman-Brand-Leadership-Award_In.jpg' alt="Zoomed In" />
                    <button className="close-button" onClick={toggleModal3} style={{ fontFamily: t('fontFamilyone') }}>
                      {t("Close")}
                    </button>
                  </Modal>
  
                </Col>

              <Col xs={12} md={6} lg={3} className='ps-3'>

                <div className='nbox'>
                  <img src='/images/news/Leadership.jpg' alt="ogfc-img" onClick={toggleModal}></img>
                  <p style={{ fontFamily: t('fontFamilyone') }}>{t("Oman Brand Leadership Awards-2023")}</p>
                </div>

                <Modal
                  isOpen={modalIsOpen}
                  onRequestClose={toggleModal}
                  contentLabel="Zoomed Image"
                  className="modal"
                  overlayClassName="overlay"
                >
                  <img src='/images/news/Oman-Brand-Leadership-Awards-2023.jpg' alt="Zoomed In" />
                  <button className="close-button" onClick={toggleModal} style={{ fontFamily: t('fontFamilyone') }}>
                    {t("Close")}
                  </button>
                </Modal>


              </Col>


    
            
              <div className='viewmorebutton'>
                <Link to="/news">  <Button style={{ fontFamily: t('fontFamilyone') }}>{t("READ MORE")}</Button></Link>
              </div>
            </Row>
          </div>
        </div>

      </div>


    </div>
  )
}

export default Newsandevents