import React, { useState } from 'react';
import { Carousel, Container } from 'react-bootstrap';
import '../About.css'
import { useTranslation } from 'react-i18next';

function Importslider() {
  const { t, i18n } = useTranslation();
  return (
    <div >

      <div className="headertop" >
        <Carousel className="slider ">
          <Carousel.Item>
            <img className="d-block w-100  " src="/import/importnew.jpg" alt="ogfc-img" />

            <div className="carousel-caption caption-left">
              <div class="left-line4"></div>
              <h1 style={{ fontFamily: t('fontFamilytwo') }} className='linedown'>{t("IMPORT &")} <br></br>{t("EXPORT")}

              </h1>

            </div>

          </Carousel.Item>
        </Carousel>
      </div>



    </div>
  )
}

export default Importslider