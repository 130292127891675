




import React, { useState } from 'react';

import Aboutenquiry from '../About/Aboutenquiry'
import { Tab, Nav } from 'react-bootstrap';

import { Col, Row, Carousel } from 'react-bootstrap'
import { Image } from 'react-bootstrap';
import Footer from "../Includes/Footer";
import { useTranslation } from 'react-i18next';
function Shortening() {

    const { t, i18n } = useTranslation();



    const [selectedSize, setSelectedSize] = useState('20Kg');
    const [selectedZain, setSelectedZain] = useState('Z-20Kg');






    const handleSizeChange = (size) => {
        setSelectedSize(size);
    };
    const handleSizeChangeZain = (sizez) => {
        setSelectedZain(sizez);
    };





    return (
        <div>

            <div className="headertop" >
                <Carousel className="slider ">
                    <Carousel.Item>
                        <img className="d-block w-100  " src="/oilproduct-details/SHORTENING/BANNER/shorteningnew.jpg" alt="Slide 1" />

                        <div className="carousel-caption caption-left">
                            <div class="left-line2"></div>
                            <h1 className='linedown shortening' style={{ fontFamily: t('fontFamilytwo') }} >{t("SHORTENING")}
                            </h1>

                        </div>

                    </Carousel.Item>
                </Carousel>
            </div>








            <Tab.Container defaultActiveKey="first">

                <Row>
                    <Col sm={12}>
                        <Tab.Content>

                            <Tab.Pane eventKey="first">
                                <div className="container  py-5">


                                    <div className="row">
                                        <div className="col-md-6 product-details product-details-heading">
                                            <img style={{

                                                border: '1px solid #f2f2f2',
                                                borderRadius: '30px'
                                            }} src="/oilproduct-details/SHORTENING/LOGO/Logo  Al-Safa.png" alt="ogfc-img" />
                                            <h1 style={{ fontFamily: t('fontFamilyone') }}>{t("SHORTENING")}</h1>

                                            <h6 style={{ fontFamily: t('fontFamilyone') }}>{t("Ingredients :")}</h6>
                                            <h6 style={{ fontFamily: t('fontFamilyone') }}>{t("Refined Palm Olein and its fractions, Antioxidant (E319)")} </h6>

                                            <div class="productfree">
                                                <h2 className="rise" style={{ fontFamily: t('fontFamilytwo') }}>{t("FREE FROM TRANS FAT")}</h2>
                                            </div>
                                            <img className="detail-view" src="/oilproduct-details/SHORTENING/TABLE/Shortening Nutritional Table1.jpg" alt="ogfc-img" />
                                        </div>
                                        <div style={{ textAlign: "center" }} className="tab-main col-md-6 ">
                                            <Image src={getImageForSize(selectedSize)} alt="ogfc-img" />
                                            <Row className="py-3 px-0" >
                                                <div className="availabletin">
                                                    <span class="availablehead" style={{ fontWeight: 600 }}>{t("Available SKU’s")}</span>:
                                                    <button onClick={() => handleSizeChange('20Kg')}>20Kg</button>

                                                </div>
                                            </Row>

                                        </div>
                                    </div>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="second">
                                <div className="container  py-5">


                                    <div className="row">
                                        <div className="col-md-6 product-details product-details-heading">
                                            <img style={{

                                                border: '1px solid #f2f2f2',
                                                borderRadius: '30px'
                                            }} src="/oilproduct-details/SHORTENING/LOGO/Logo Zain.png" alt="ogfc-img" />
                                            <h1 style={{ fontFamily: t('fontFamilyone') }}>{t("SHORTENING")}</h1>

                                            <h6 style={{ fontFamily: t('fontFamilyone') }}>{t("Ingredients :")}</h6>
                                            <h6 style={{ fontFamily: t('fontFamilyone') }}>{t("Refined Palm Olein and its fractions, Antioxidant (E319)")} </h6>

                                            <div class="productfree">
                                                <h2 className="rise" style={{ fontFamily: t('fontFamilytwo') }}>{t("FREE FROM TRANS FAT")}</h2>
                                            </div>
                                            <img className="detail-view" src="/oilproduct-details/SHORTENING/TABLE/Shortening Nutritional Table1.jpg" alt="ogfc-img" />
                                        </div>
                                        <div style={{ textAlign: "center" }} className="tab-main col-md-6 ">
                                            <Image src={getImageForZain(selectedZain)} alt="ogfc-img" />
                                            <Row className="py-3 px-0" >
                                                <div className="availabletin">
                                                    <span class="availablehead" style={{ fontWeight: 600 }}>{t("Available SKU’s")}</span>:
                                                    <button onClick={() => handleSizeChangeZain('20Kg')}>20Kg</button>

                                                </div>
                                            </Row>

                                        </div>
                                    </div>
                                </div>
                            </Tab.Pane>





                        </Tab.Content>
                    </Col>
                </Row>

                <Row>
                    <Col sm={12}>

                        <Nav style={{ padding: '0px' }} variant="pills" className="d-flex justify-content-center">


                            <Nav.Item style={{ padding: '10px 5px ' }}>
                                <Nav.Link eventKey="first" style={{ background: "#fff", padding: "0px" }}>

                                    <img style={{
                                        width: '100%',

                                    }} src="/oilproduct-details/SHORTENING/LOGO/Logo  Al-Safa.png" alt="ogfc-img" />
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item style={{ padding: '10px 5px ' }}>
                                <Nav.Link eventKey="second" style={{ background: "#fff", padding: "0px" }}>

                                    <img style={{
                                        width: '100%',

                                    }} src="/oilproduct-details/SHORTENING/LOGO/Logo Zain.png" alt="ogfc-img" />
                                </Nav.Link>
                            </Nav.Item>



                        </Nav>

                    </Col>
                </Row>
            </Tab.Container >

            < Aboutenquiry />
            <Footer />




        </div>
    )
}

<>
    <h1>Super Fry</h1>
    <span style={{ fontWeight: '600', marginRight: '10px', }}>Available SKU’s </span>
</>



const getImageForSize = (size) => {
    switch (size) {
        case '20Kg':
            return '/oilproduct-details/SHORTENING/PRODUCT IMAGES/AL SAFA PRODUCT IMAGE/Al Safa Shortening 20Kg-back.jpg';

    }
};
const getImageForZain = (sizez) => {
    switch (sizez) {
        case 'Z-20Kg':
            return '/oilproduct-details/SHORTENING/PRODUCT IMAGES/ZAIN PRODUCT IMAGE/Zain Shortening 20Kg.jpg';

    }
};

export default Shortening