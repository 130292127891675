import React from 'react'
import { useState, useEffect } from 'react';

import { Navbar, Nav, Container } from 'react-bootstrap';
import '../Main.css'
import { Link, useNavigate } from 'react-router-dom';
import Language from '../../Langhook/Language';
import { useTranslation } from 'react-i18next';

function Topbar() {
  const navigate = useNavigate()
  const { t, i18n } = useTranslation();
  const [showSidebar, setShowSidebar] = useState(false);


  const handleToggleSidebar = () => {
    setShowSidebar(true);
    
    setShowSidebar(false);

  };









  return (
    <div>

      <Navbar className="navbar-background fixed-top w-100" expand="lg" >
        <Container fluid>
          <Navbar.Brand className='logo'>
            <Link to="/" > <img src='/images/logomain2.png' alt="ogfc-img"></img></Link>

          </Navbar.Brand>
          <div className='navbar-toggle-close'></div>
          <Navbar.Toggle onClick={handleToggleSidebar} aria-controls="basic-navbar-nav"

          />
          <Navbar.Collapse id="basic-navbar-nav">

            <Nav className="navlinks ms-auto">


              <div class="dropdown">
                <button class="dropbtn" style={{ fontFamily: t('fontFamilyone') }}>{t("ABOUT OGFC")}</button>
                <div class="dropdown-content">
                  <Link to="/about-us" onClick={() => { navigate('/about-us', { replace: true }); }} ><span style={{ fontFamily: t('fontFamilyone') }}>{t("ABOUT US")}</span></Link>
                  {/* <Link style={{ fontFamily: t('fontFamilyone') }} to="/board-of-directors" onClick={() => { navigate('/board-of-directors', { replace: true }); }}>{t("BOARD OF DIRECTORS")}  </Link> */}
                  <Link style={{ fontFamily: t('fontFamilyone') }} to="/mission-quality" onClick={() => { navigate('/board-of-directors', { replace: true }); }}>{t("VISION AND MISSION")}</Link>
                  <Link style={{ fontFamily: t('fontFamilyone') }} to="/quality-certification" onClick={() => { navigate('/quality-certification', { replace: true }); }}>{t("CERTIFICATIONS")}</Link>
                </div>
              </div>
              <Link style={{ fontFamily: t('fontFamilyone') }} to="/importexport" onClick={() => { navigate('/importexport', { replace: true }); }}>{t("IMPORT & EXPORT")}</Link>
              <div class="dropdown">
                <Link style={{ fontFamily: t('fontFamilyone') }} to="/ourproduct" onClick={() => { navigate('/ourproduct', { replace: true }); }}><button class="dropbtn">{t("PRODUCTS")}</button></Link>
                <div class="dropdown-content">
                  <Link style={{ fontFamily: t('fontFamilyone') }} to="/oil-products" onClick={() => { navigate('/oil-products', { replace: true }); }} >{t("OIL PRODUCTS")}</Link>
                  <Link style={{ fontFamily: t('fontFamilyone') }} to="/non-oilproducts" onClick={() => { navigate('/non-oilproducts', { replace: true }); }}>{t("NON-OIL PRODUCTS")}</Link>
                </div>
              </div>


              <Link style={{ fontFamily: t('fontFamilyone') }} to="/private-brands" onClick={() => { navigate('/private-brands', { replace: true }); }} >{t("PRIVATE BRANDS")}</Link>
              <Link style={{ fontFamily: t('fontFamilyone') }} to="/file/OGFC BROCHURE_2024.pdf" target="_blank" download>{t("BROCHURE")}</Link>
              <div class="dropdown">
                <button style={{ fontFamily: t('fontFamilyone') }} class="dropbtn">{t("HEALTH TIPS & RECIPES")}</button>
                <div class="dropdown-content">
                  <Link style={{ fontFamily: t('fontFamilyone') }} to="/health-tips" onClick={() => { navigate('/health-tips', { replace: true }); }}>{t("HEALTH TIPS")}</Link>
                  <Link style={{ fontFamily: t('fontFamilyone') }} to="/recipe" onClick={() => { navigate('/recipe', { replace: true }); }}>{t("RECIPES")}</Link>
                </div>
              </div>

              <Link style={{ fontFamily: t('fontFamilyone') }} to="/careers" onClick={() => { navigate('/careers', { replace: true }); }}>{t("CAREERS")}</Link>
              <Link style={{ fontFamily: t('fontFamilyone') }} to="/gallery" onClick={() => { navigate('/gallery', { replace: true }); }}>{t("GALLERY")}</Link>
              <Link style={{ fontFamily: t('fontFamilyone') }} to="/contact-us" onClick={() => { navigate('/contact-us', { replace: true }); }}>{t("CONTACT US")}</Link>
              <div className='langclass'> <Language /></div>

            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>


    
    </div >
  )
}

export default Topbar