import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

function Boardclient() {

  const { t, i18n } = useTranslation();

  const [isHovered1, setIsHovered1] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const [isHovered3, setIsHovered3] = useState(false);
  const [isHovered4, setIsHovered4] = useState(false);
  const [isHovered5, setIsHovered5] = useState(false);
  const handleMouseEnter1 = () => {
    setIsHovered1(true);

  }
  const handleMouseLeave1 = () => {
    setIsHovered1(false);
  };
  const handleMouseEnter2 = () => {
    setIsHovered2(true);

  }
  const handleMouseLeave2 = () => {
    setIsHovered2(false);
  };

 const handleMouseEnter3 = () => {
    setIsHovered3(true);

  }
  const handleMouseLeave3 = () => {
    setIsHovered3(false);
  };



  const handleMouseEnter4 = () => {
    setIsHovered4(true);

  }
  const handleMouseLeave4 = () => {
    setIsHovered4(false);
  };


  const handleMouseEnter5 = () => {
    setIsHovered5(true);

  }
  const handleMouseLeave5 = () => {
    setIsHovered5(false);
  };
  return (
    <>
      <div className="client">
        <img className='client-banner' src="/board/clientback.jpg" alt="ogfc-img"></img>
        <div className='clienimages'>
          <Container>
            <Row className='p-0 m-0 justify-content-center'>
              <Col xs={6} md={6} lg={4} className='divname pb-5'>


                <div
                  className="image-board "
                  onMouseEnter={handleMouseEnter1}
                  onMouseLeave={handleMouseLeave1}
                >

                  <div className='board-img'>
                    <img src='/board/image1.jpg' alt="ogfc-img" />
                    <h2 style={{ fontFamily: t('fontFamilyone') }}>{t("Khalid Mustahil Ahmed Al Mashani")}</h2>
                    <h6 style={{ fontFamily: t('fontFamilyone') }}>{t("Chairman")}</h6>
                  </div>

                  {isHovered1 && <div className="popup">
                    <div className="boardpopup">
                      <p style={{ fontFamily: t('fontFamilyone') }}>{t("Khalid Mustahil Ahmed Al Mashani is the Chairman of Bank Muscat. Sheikh Khalid has a Masters Degree in Finance from the Oxford University, U.K. He is also a Board member of Dhofar International Dev. & Investment Holding Co. SAOG.(DIDIC) and Dhofar Cattle Feed Co.SAOG (DCF).")}</p>

                    </div>

                  </div>}
                </div>
              </Col>
              <Col xs={6} md={6} lg={4} className='divname '>



                <div
                  className="image-board "
                  onMouseEnter={handleMouseEnter2}
                  onMouseLeave={handleMouseLeave2}
                >

                  <div className='board-img'>
                    <img src='/board/image2.jpg' alt="ogfc-img" />
                    <h2 style={{ fontFamily: t('fontFamilyone') }}>{t("Salim Abdullah Faraj Al Awadi")}</h2>
                    <h6 style={{ fontFamily: t('fontFamilyone') }}>{t("Vice Chairman")}</h6>
                  </div>

                  {isHovered2 && <div className="popup">
                    <div className="boardpopup">
                      <p style={{ fontFamily: t('fontFamilyone') }}>{t("Salim Abdullah Faraj Al Awadi is a noted industrialist in Oman and has Masters Degree in Finance from U.K. He is the Chairman of Dhofar Poultry Company SAOG and Director in Dhofar International Dev & Investment Holding Co. SAOG (DIDIC) & Dhofar Cattle Feed SAOG. He is also the Deputy CEO for Al Omaniya Financial Services SAOG.")}</p>

                    </div>

                  </div>}
                </div>



              </Col>
              <Col xs={6} md={6} lg={4} className='divname '>

                <div
                  className="image-board "
                  onMouseEnter={handleMouseEnter3}
                  onMouseLeave={handleMouseLeave3}
                >
                  <div className='board-img'>
                    <img src='/board/image3.jpg' alt="ogfc-img" />
                    <h2 style={{ fontFamily: t('fontFamilyone') }}>{t("Salim Taman Musallam Al Maashani")}</h2>
                    <h6 style={{ fontFamily: t('fontFamilyone') }}>{t("Managing Director")}</h6>
                  </div>


                  {isHovered3 && <div className="popup">
                    <div className="boardpopup">
                      <p style={{ fontFamily: t('fontFamilyone') }}>{t("Salim Taman Musallam Al Maashani is a Graduate in Business Administration from Western International University, Arizona. He is a noted personality in the Industrial & Service Sector in Sultanate of Oman and was in the Director Board of Bank Muscat SAOG, Oman International Bank, Dhofar Insurance Co.SAOG, Dhofar International Dev. & Investment Holding Co. SAOG . He is also the Chairman of Integration Capital & Trade LLC and MD&CEO of Dhofar Poultry SAOG.")}</p>

                    </div>

                  </div>}
                </div>




              </Col>


              <Col xs={6} md={6} lg={4} className='divname boardsecond'>

                <div
                  className="image-board"
                  onMouseEnter={handleMouseEnter4}
                  onMouseLeave={handleMouseLeave4}
                >

                  <div className='board-img'>
                    <img src='/board/image4.jpg' alt="ogfc-img" />
                    <h2 style={{ fontFamily: t('fontFamilyone') }}>{t("Omar Ahmed Abdullah Al Sheikh")}</h2>
                    <h6 style={{ fontFamily: t('fontFamilyone') }}>{t("Director")}</h6>
                  </div>

                  {isHovered4 && <div className="popup-data">
                    <div className="boardpopup-data">
                      <p style={{ fontFamily: t('fontFamilyone') }}>{t("Omar Ahmed Abdullah Al Sheikh is a noted industrialist in Oman and is serving as Director in Dhofar International Dev & Investment Holding Co. SAOG (DIDIC), and many other industrial establishments. He is also the Deputy CEO of Dhofar Insurance Company SAOG")}</p>

                    </div>

                  </div>}
                </div>


              </Col>


              <Col xs={6} md={6} lg={4} className='divname boardsecond '>


                <div
                  className="image-board "
                  onMouseEnter={handleMouseEnter5}
                  onMouseLeave={handleMouseLeave5}
                >

                  <div className='board-img'>
                    <img src='/board/image5.jpg' alt="ogfc-img" />
                    <h2 style={{ fontFamily: t('fontFamilyone') }}>{t("Salim Rabia Juma An Bait Arafa")}</h2>
                    <h6 style={{ fontFamily: t('fontFamilyone') }}> {t("Director")}</h6>
                  </div>

                  {isHovered5 && <div className="popup-data">
                    <div className="boardpopup-data">
                      <p style={{ fontFamily: t('fontFamilyone') }}>{t("Salim Rabia Juma An Bait Arafa has Higher Diploma in Legal Studies from Sidi Mohammed Bin Abdullah University (USMBA), Kingdom of Morocco. He is working as counselor at Ministry of foreign affairs and has held various positions in Oman embassies abroad.")}</p>

                    </div>

                  </div>}
                </div>




              </Col>

            </Row>
          </Container >
        </div>



      </div>
    </>
  )
}

export default Boardclient
