
import { BiArrowToTop } from "react-icons/bi";
import React, { useRef, useEffect, useState } from 'react';
import { Button, Row, Col, Container } from "react-bootstrap";
import HomeIcon from '@mui/icons-material/Home';
import AddIcCallIcon from '@mui/icons-material/AddIcCall';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import LanguageIcon from '@mui/icons-material/Language';
import { Link } from "react-router-dom";
import SimpleForm from "../Simpleform/Simpleform";
import { useTranslation } from 'react-i18next';
import axios from "axios";
function Footer() {
  const email1 = 'info@omanigulffood.com';
  const { t, i18n } = useTranslation();
  const ref = useRef();
  const [isVisible, setIsVisible] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [error, setError] = useState(false)

  const initialformData = {

    email: '',
  }
  const [formData, setformData] = useState(initialformData)
  const handlechange = (e) => {
    const { name, value } = e.target;
    setformData({ ...formData, [name]: value, })


  }





  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(false)
    console.log('formdatas=', formData);

    const userPayload = {
      email: formData.email,

    };

    try {
      const response = await axios.post('https://omanifoods.tectuz.com/api/email', userPayload);
      console.log('API Response:', response.data);

      setformData(initialformData);
    }
    catch (error) {
      setError(true)
      setError(error.response.data)





      console.error('Error storing data:', error);

      console.log('Response data:', error.response.data); // Log the response data to see if it provides more details about the error.
    }

  };

  const handleScroll = () => {
    if (window.pageYOffset > 100) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  };
  const handleButtonClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  window.addEventListener('scroll', handleScroll);
  return (
    <div>
      <div className="footerlist">
        <Container>
          <Row>
            <Col xs={12} sm={12} md={4} lg={4}>
              <div className="footermain">
                <img className="log" src="/images/footer-sliderlogo.png" alt="ogfc-img" ></img>
                <Link to="/"><img src="/images/bottomlogo2.png"  ></img></Link>
                <div className="footerlinks">
                  <Link to="/about-us" style={{ textDecoration: 'none', }}> <h6 style={{ fontFamily: t('fontFamilyone') }}>{t("ABOUT US")}</h6></Link>
                  <Link to="/careers" style={{ textDecoration: 'none', }}> <h6 style={{ fontFamily: t('fontFamilyone') }}>{t("CAREERS")}</h6></Link>
                  <Link to="/contact-us" style={{ textDecoration: 'none', }}> <h6 style={{ fontFamily: t('fontFamilyone') }}>{t("CONTACT US")}</h6></Link>
                </div>
                <div className="socialmedia">
                  <ul>
                    <li>
                      <img src="/social/facebook.png" alt="ogfc-img"></img>



                    </li>
                    <li>
                      <img src="/social/insta.png" alt="ogfc-img"></img>
                    </li>
                    <li>

                      <img src="/social/youtube.png" alt="ogfc-img"></img>
                    </li>
                    <li>

                      <img src="/social/linkedin.png" alt="ogfc-img"></img>
                    </li>

                  </ul>
                </div>
              </div>
            </Col>
            <Col xs={12} sm={12} md={4} lg={4} className="footertelephone">
              <div style={{ color: "#fff" }}>
                <ul>
                  <li style={{ fontFamily: t('fontFamilyone') }}>
                    <HomeIcon variant="Filled" sx={{ marginRight: '10px', color: '#fff;' }} />
                    {t("P.O. Box 43, P.C. 217, Raysut Industrial Estate, Salalah, Sultanate of Oman")}
                  </li>
                  <li>
                    <AddIcCallIcon variant="Filled" sx={{ marginRight: '10px', color: '#fff;' }} />
                    +968 99484859 / 23219142
                  </li>
                  <Link style={{ textDecoration: 'none', color: '#fff' }}>
                    <li>

                      <LanguageIcon variant="Filled" sx={{ marginRight: '10px', color: '#fff;' }} />
                      www.omanigulffood.com
                    </li>
                  </Link>
                  <Link to={`mailto:${email1}`} style={{ textDecoration: 'none', color: '#fff' }} >
                    <li>
                      <AlternateEmailIcon variant="Filled" sx={{ marginRight: '10px', color: '#fff;' }} />
                      info@omanigulffood.com
                    </li>
                  </Link>
                </ul>
              </div>
              <form onSubmit={handleSubmit} className="my-2">
                <div className="search2">

                  <input name="email" value={formData.email} onChange={handlechange} style={{ fontFamily: t('fontFamilyone') }} className="inputsearch2 w-50" type="text" placeholder={t("Enter Your Email")}></input>
                  <button style={{ fontFamily: t('fontFamilyone') }}>{t("Subscribe Now")}</button>
                  {error ? (<p>{error.message}</p>) : (<p>{error.message}</p>)}
                </div>
              </form>
            </Col>
            <Col xs={12} sm={12} md={4} lg={4} className="footertelephone">
              <h5 style={{ fontWeight: "bold", fontFamily: t('fontFamilyone') }}>{t("PRODUCTS")}</h5>
              <ul>
                <Link to="/palmolein-details" style={{ textDecoration: 'none', color: '#fff' }}>  <li style={{ fontFamily: t('fontFamilyone') }}>{t("PALM OLEIN")}</li></Link>
                <Link to="/sunfloweroil-details" style={{ textDecoration: 'none', color: '#fff' }}>  <li style={{ fontFamily: t('fontFamilyone') }}>{t("SUNFLOWER OIL")}</li></Link>
                <Link to="/cornoil-details" style={{ textDecoration: 'none', color: '#fff' }}>  <li style={{ fontFamily: t('fontFamilyone') }}>{t("CORN OIL")}</li></Link>
                <Link to="/cooking-oil" style={{ textDecoration: 'none', color: '#fff' }}>  <li style={{ fontFamily: t('fontFamilyone') }}>{t("COOKING OIL")}</li></Link>
                <Link to="/shortening" style={{ textDecoration: 'none', color: '#fff' }}>  <li style={{ fontFamily: t('fontFamilyone') }}>{t("SHORTENING")}</li></Link>
                <Link to="/non-oilproducts" style={{ textDecoration: 'none', color: '#fff' }}>  <li style={{ fontFamily: t('fontFamilyone') }}>{t("NON-OIL PRODUCTS")}</li></Link>
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="bottomfooter">
        <p style={{ fontFamily: t('fontFamilyone') }}>{t("Copyright 2023 © Omani Gulf Food Company All Rights Reserved. Design: Sysbreeze")} </p>
      </div>
    
      {/* <SimpleForm /> */}
      {
        showButton && (
          <button className="scroll-top-button" onClick={handleButtonClick}>
            <BiArrowToTop />
          </button>
        )
      }
    </div >
  )
}

export default Footer