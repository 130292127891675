import React from 'react'


import { Carousel,  Button } from 'react-bootstrap';
import '../About.css'
import Aboutenquiry from '../About/Aboutenquiry';

import { Link } from 'react-router-dom';
import Footer from '../Includes/Footer';
import { useTranslation } from 'react-i18next';


function Qualitymanagement() {
    const { t, i18n } = useTranslation();

    return (
        <div>


            <div className="headertop" >
                <Carousel className="slider ">
                    <Carousel.Item>
                        <img className="d-block w-100  " src="/Quality/qualitynew.jpg"  alt="ogfc-img" />

                        <div className="carousel-caption caption-left">
                            <div class="left-line3"></div>
                            <h1 className='linedown' style={{ textShadow: '1px 1px 2px black, 0 0 1em black, 0 0 0.2em black',fontFamily: t('fontFamilytwo') }}>{t("MISSION-VISION")}<br></br>{t("MISSION-slider")}
                            </h1>
                            <p  className='linedown' style={{ textShadow: '1px 1px 2px black, 0 0 1em black, 0 0 0.2em black',fontFamily: t('fontFamilyone') }}>{t("To provide healthy and quality food products")}<br></br>{t("across the region with superior")}
                                <br></br>{t("customer service and delight")}.
                            </p>
                        </div>

                    </Carousel.Item>
                </Carousel>
            </div>


            <div className='container py-5'><div className='row'>
                <div className='col-md-12'>
                <div className='quality' style={{ textAlign: 'center' }}>
                        <h1 style={{  fontFamily: t('fontFamilytwo') }}>{t("VISION")}</h1>
                    </div>
                    <div className='quality-details justify-content-center'>

                        <p style={{  fontFamily: t('fontFamilyone') }}>{t("Our vision is to create unforgettable experiences by offering delicious food, exceptional customer service and making a positive difference in our community and environment.")}</p>
                    </div>
                    <br></br>

                    <div className='quality'>
                        <h1 style={{  fontFamily: t('fontFamilytwo') }}>{t("MISSION")}</h1>
                    </div>
                    <div className='quality-details'>
                        <h6 style={{  fontFamily: t('fontFamilyone') }}>{t("TO BE A SOCIALLY RESPONSIBLE COMPANY THAT")}:</h6>
                        <ul style={{  fontFamily: t('fontFamilyone') }}>
                            <li>{t("Delights its consumers by giving high quality products that provides value for money")}</li>
                            <li>{t("Exceeds the consumers expectations with its most hygienic, nutritious and delicious products")}</li>
                            <li>{t("Provides an enriching, fulfilling and rewarding job environment for its employees")}</li>
                            <li>{t("Values long-term relationships with its suppliers, distributors and agents")}</li>
                            <li>{t("Serves and supports the community and awards re-assuring returns to its shareholders")}</li>
                            <li>{t("To minimize the waste generation through using eco-friendly products and processes.")}</li>
                        </ul>
                    </div>
                   
                </div>
            </div>
            </div>
            <img style={{ width: '100%' }} src="/Quality/qimage2.jpg"  alt="ogfc-img" />


            <div className='container py-5'><div className='row'>
                <div className='col-md-12'>
                    <div className='quality'>
                        <h1 style={{  fontFamily: t('fontFamilytwo') }}>{t("QUALITY MANAGEMENT")}</h1>
                    </div>
                    <div className='quality-details'>
                        <h6 style={{  fontFamily: t('fontFamilyone') }}>{t("TO BE A SOCIALLY RESPONSIBLE COMPANY THAT")}:</h6>
                        <ul style={{  fontFamily: t('fontFamilyone') }}>
                            <li>{t("Our Vision, Mission and Action is rooted in Total Quality Management")}</li>
                            <li>{t("Starting from identification of Consumer needs, Optimized planning for achieving the same, meeting customer requirements at highest satisfaction level with the lowest financial cost")}</li>
                            <li>{t("Adhering to cGMP, SOPs and HACCP practices")}</li>
                            <li>{t("To produce the best quality and hygienic products at the most competitive prices")}</li>
                            <li>{t("WE BELIEVE – QUALITY IS NOT AN END BUT OUR WAY OF LIFE!")}</li>

                        </ul>
                    </div>
                </div>
            </div>
            </div>

            <div className=' about-board mission-about' style={
                {
                    backgroundColor: '#ffe3c5'
                }
            }>
                <div className='container '>
                    <div className='container row'>

                        <div className='col-12 col-sm-12 col-md-6 col-lg-4'>
                            <div className='about-company-board'>
                                <h1  style={{  fontFamily: t('fontFamilytwo') }}>
                                    {t("Quality")}<br></br>
                                    {t("Certification")}
                                </h1>

                            </div>
                        </div>
                        <div className='col-12 col-sm-12 col-md-6 col-lg-8 about-company-board-para'>
                            <p style={{  fontFamily: t('fontFamilyone') }}>
                                {t("Our Manufacturing plants are up-to-date with most important certification in our industry.")}
                            </p>
                            <></>
                            <Link to="/quality-certification"><Button style={{  fontFamily: t('fontFamilyone') }}>
                                {t("DISCOVER NOW")}</Button></Link>
                        </div>
                    </div></div>
            </div>
            <Aboutenquiry />
            <Footer />
           
        </div>
    )
}

export default Qualitymanagement