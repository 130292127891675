import React from 'react'

import { Carousel, Col, Row, Container } from 'react-bootstrap'
import '../About.css'
import { useState } from 'react'
import Aboutenquiry from '../About/Aboutenquiry'

import Footer from '../Includes/Footer'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

function Nonoilproducts() {
    const [showPopuphot, setShowPopuphot] = useState(false);
    const [showPopuptomato, setShowPopuptomato] = useState(false);
    const [showPopupcocunut, setShowPopupcocunut] = useState(false);


    const { t, i18n } = useTranslation();



    const handleClickhot = () => {
        setShowPopuptomato(false);
        setShowPopupcocunut(false);
        setShowPopuphot(true);
    };
    const handleClicktomato = () => {
        setShowPopupcocunut(false);
        setShowPopuphot(false)
        setShowPopuptomato(true);
    };
    const handleClickcocunut = () => {
        setShowPopuphot(false)
        setShowPopuptomato(false)
        setShowPopupcocunut(true);
    };
    return (
        <div>

            <div className="headertop" >
                <Carousel className="slider ">
                    <Carousel.Item>
                        <img className="d-block w-100  " src="/products/non-oilproducts/non-oilbanner.jpg" alt="ogfc-img" />

                        <div className="carousel-caption caption-left">
                            <div class="left-line3"></div>
                            <h1 style={{ fontFamily: t('fontFamilytwo') }} className='linedown ourproducts' >{t("NON-OIL")}<br></br>{t("PRODUCTS")}

                            </h1>
                            <p style={{ fontFamily: t('fontFamilyone'), color: "#000" }} className='linedown non-oilpara' >

                                {t("OGFC offers an array of products such as Hot Sauce")}<br></br> {t("Ketchup, Rice, Salt, Tuna and more")}.<br></br> {t("We maintain high standards to ensure that")} <br></br>{t("you receive the best quality products")}.




                            </p>
                            <div className='non-oilproduct'>
                                <Link to="/contact-us">  <button type="button" class="btn btn-primary nonoilproduct" style={{ fontFamily: t('fontFamilyone') }}>{t("ENQUIRE NOW")}</button></Link>
                            </div>
                        </div>

                    </Carousel.Item>
                </Carousel>
            </div>



            <div className='nonoil-products py-5'>
                <Container >
                    <Row className='p-0 m-0 '>
                        <Col xs={12} md={4} lg={4} className='divname nonoil-product'>
                            <div className='nonoil-img'>
                                <img onClick={handleClickhot} src="/products/non-oilproducts/Hot-Sauce1.jpg" alt="ogfc-img"></img>
                                <h1 style={{ fontFamily: t('fontFamilyone') }}>{t("HOT SAUCE")}</h1>
                            </div>

                            {showPopuphot && (
                                <div className="boardpopupnon">
                                    <div className='d-flex'>
                                        <img style={{ width: '200px' }} className='non-popup' src='/products/non-oilproducts/hotsause/sause1.jpg' alt="ogfc-img"></img>
                                        <img style={{ width: '200px' }} className='non-popup' src='/products/non-oilproducts/hotsause/sause2.jpg ' alt="ogfc-img"></img>
                                    </div>
                                    <button onClick={() => setShowPopuphot(false)}>{t("Close")}</button>
                                </div>
                            )}


                        </Col>
                        <Col xs={12} sm={12} md={4} lg={4} className='divname nonoil-product'>
                            <div className='nonoil-img'>
                                <img onClick={handleClicktomato} src="/products/non-oilproducts/Ketchup1.jpg" alt="ogfc-img"></img>
                                <h1 style={{ fontFamily: t('fontFamilyone') }}>{t("TOMATO KETCHUP")}</h1>
                            </div>
                            {showPopuptomato && (
                                <div className="boardpopupnon">
                                    <div className='d-flex'>
                                        <img style={{ width: '200px' }} className='non-popup' src='/products/non-oilproducts/ketchup/Ketchup-9g.jpg' alt="ogfc-img"></img>
                                        <img style={{ width: '200px' }} className='non-popup' src='/products/non-oilproducts/ketchup/Ketchup-340g.jpg' alt="ogfc-img"></img>
                                        <img style={{ width: '200px' }} className='non-popup' src='/products/non-oilproducts/ketchup/Ketchup-5L.jpg' alt="ogfc-img"></img>
                                    </div>
                                    <button onClick={() => setShowPopuptomato(false)}>{t("Close")}</button>
                                </div>
                            )}
                        </Col>
                        <Col xs={12} sm={12} md={4} lg={4} className='divname nonoil-product'>

                            <div className='nonoil-img'>
                                <img src="/products/non-oilproducts/basmathirice1.jpg" alt="ogfc-img"></img>
                                <h1 style={{ fontFamily: t('fontFamilyone') }}>{t("BASMATI RICE")}</h1>
                            </div>

                        </Col>

                        <Col xs={12} sm={12} md={4} lg={4} className='divname nonoil-product'>

                            <div className='nonoil-img'>

                                <img src="/products/non-oilproducts/palakkadanmattarice1.jpg" alt="ogfc-img"></img>
                                <h1 style={{ fontFamily: t('fontFamilyone') }}>{t("PALAKKADAN MATTA RICE")}</h1>
                            </div>



                        </Col>
                        <Col xs={12} sm={12} md={4} lg={4} className='divname nonoil-product'>

                            <div className='nonoil-img'>

                                <img src="/products/non-oilproducts/Diya Matta Rice 20Kg.jpg" alt="ogfc-img"></img>
                                <h1 style={{ fontFamily: t('fontFamilyone') }}>{t("PALAKKADAN MATTA RICE")}</h1>
                            </div>



                        </Col>
                        <Col xs={12} sm={12} md={4} lg={4} className='divname nonoil-product'>
                            <div className='nonoil-img'>

                                <img src="/products/non-oilproducts/longgrainbiriryani1.jpg" alt="ogfc-img"></img>
                                <h1 style={{ fontFamily: t('fontFamilyone') }}>{t("LONG GRAIN BIRYANI RICE")}</h1>
                            </div>



                        </Col>
                        <Col xs={12} sm={12} md={4} lg={4} className='divname nonoil-product'>
                            <div className='nonoil-img'>

                                <img src="/products/non-oilproducts/goldensellarice1.jpg" alt="ogfc-img" ></img>
                                <h1 style={{ fontFamily: t('fontFamilyone') }}>{t("GOLDEN SELLA RICE")}</h1>
                            </div>



                        </Col>

                        <Col xs={12} sm={12} md={4} lg={4} className='divname nonoil-product'>

                            <div className='nonoil-img'>

                                <img src="/products/non-oilproducts/sonamassori1.jpg" alt="ogfc-img"></img>
                                <h1 style={{ fontFamily: t('fontFamilyone') }}>{t("SONA MASOORI RICE")}</h1>
                            </div>


                        </Col>
                        <Col xs={12} sm={12} md={4} lg={4} className='divname nonoil-product'>

                            <div className='nonoil-img'>

                                <img src="/products/non-oilproducts/salt1.jpg" alt="ogfc-img"></img>
                                <h1 style={{ fontFamily: t('fontFamilyone') }}>{t("SALT")}</h1>
                            </div>



                        </Col>
                        <Col xs={12} sm={12} md={4} lg={4} className='divname nonoil-product'>

                            <div className='nonoil-img'>

                                <img src="/products/non-oilproducts/tunasolid1.jpg" alt="ogfc-img"></img>


                                <h1 style={{ fontFamily: t('fontFamilyone') }}>{t("TUNA SOLID")}</h1>
                            </div>

                        </Col>

                        <Col xs={12} sm={12} md={4} lg={4} className='divname nonoil-product'>

                            <div className='nonoil-img'>

                                <img src="/products/non-oilproducts/tunachunks1.jpg" alt="ogfc-img"></img>


                                <h1 style={{ fontFamily: t('fontFamilyone') }}>{t("TUNA CHUNKS")}</h1>
                            </div>


                        </Col>
                        <Col xs={12} sm={12} md={4} lg={4} className='divname nonoil-product'>
                            <div className='nonoil-img'>

                                <img src="/products/non-oilproducts/water1.jpg" alt="ogfc-img"></img>


                                <h1 style={{ fontFamily: t('fontFamilyone') }}>{t("WATER")}</h1>
                            </div>

                        </Col>
                        <Col xs={12} sm={12} md={4} lg={4} className='divname nonoil-product'>
                            <div className='nonoil-img'>

                                <img src="/products/non-oilproducts/Vinegar 473ml.jpg" alt="ogfc-img"></img>


                                <h1 style={{ fontFamily: t('fontFamilyone') }}>{t("VINEGAR 473ML")}</h1>
                            </div>

                        </Col>
                        <Col xs={12} sm={12} md={4} lg={4} className='divname nonoil-product'>
                            <div className='nonoil-img'>

                                <img src="/products/non-oilproducts/Vinegar 1G.jpg" alt="ogfc-img"></img>


                                <h1 style={{ fontFamily: t('fontFamilyone') }}>{t("VINEGAR 1G")}</h1>
                            </div>

                        </Col>

                        <Col xs={12} sm={12} md={4} lg={4} className='divname nonoil-product'>

                            <div className='nonoil-img'>

                                <img src="/products/non-oilproducts/cocunutmilkpowder-new.jpg" onClick={handleClickcocunut} alt="ogfc-img"></img>


                                <h1 style={{ fontFamily: t('fontFamilyone') }}>{t("COCONUT MILK POWDER")}</h1>
                            </div>



                            {showPopupcocunut && (
                                <div className="boardpopupnon">
                                    <div className='d-flex'>
                                        <img style={{ width: '200px' }} className='non-popup' src='/products/non-oilproducts/CMP 150G.jpg' alt="ogfc-img"></img>
                                        <img style={{ width: '200px' }} className='non-popup' src='/products/non-oilproducts/CMP 300G.jpg' alt="ogfc-img"></img>
                                        <img style={{ width: '200px' }} className='non-popup' src='/products/non-oilproducts/CMP 1Kg.jpg' alt="ogfc-img"></img>
                                    </div>
                                    <button onClick={() => setShowPopupcocunut(false)}>{t("Close")}</button>
                                </div>
                            )}
                        </Col>
                    </Row>
                </Container >

            </div>
            <Aboutenquiry />
            <Footer />


        </div >
    )
}

export default Nonoilproducts