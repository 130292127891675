


import React, { useState } from 'react';

import Aboutenquiry from '../About/Aboutenquiry'
import { Tab, Nav } from 'react-bootstrap';

import { Col, Row, Carousel } from 'react-bootstrap'

import Footer from "../Includes/Footer";
import { useTranslation } from 'react-i18next';

function Ghee() {


    const { t, i18n } = useTranslation();




    const [selectedImageasafwah, setSelectedImageasafwah] = useState('/oilproduct-details/GHEE/ASAFWAH PRODUCT IMAGES/ASafwah Ghee 1Kg.jpg');


    const handleImageChangeasafwah = (imageName) => {
        setSelectedImageasafwah(imageName);
    };







    return (
        <div>


            <div className="headertop" >
                <Carousel className="slider ">
                    <Carousel.Item>
                        <img className="d-block w-100  " src="/oilproduct-details/GHEE/BANNER/gheenew.jpg" alt="ogfc-img" />

                        <div className="carousel-caption caption-left">
                            <div class="left-line2"></div>
                            <h1 className='linedown' style={{ fontFamily: t('fontFamilytwo') }}>{t("GHEE")}
                            </h1>

                        </div>

                    </Carousel.Item>
                </Carousel>
            </div>









            <Tab.Container defaultActiveKey="first">

                <Row>
                    <Col sm={12}>
                        <Tab.Content>

                            <Tab.Pane eventKey="first">
                                <div className="container  py-5">


                                    <div className="row">
                                        <div className="col-md-6 product-details product-details-heading">
                                            <img style={{

                                                border: '1px solid #f2f2f2',
                                                borderRadius: '30px'
                                            }} src="/oilproduct-details/GHEE/LOGO/Logo  A'Safwah.png" alt="ogfc-img" />
                                            <h1 style={{ fontFamily: t('fontFamilyone') }}>{t("GHEE")}</h1>
                                            <h6 style={{ fontFamily: t('fontFamilyone') }}>{t("Ingredients :")}</h6>



                                            <h6 style={{ fontFamily: t('fontFamilyone') }}>{t("Palmoil and it's fractions,")}<br></br>
                                                {t("Ghee Flavour, Colour (Beta Carotene-E160A),")}<br></br>
                                                {t("Antioxidant (E319/E321)")}
                                            </h6>

                                            <div class="productfree">
                                                <h2 className="rise" style={{ fontFamily: t('fontFamilytwo') }}>{t("FREE FROM TRANS FAT")}</h2>
                                            </div>
                                            <img className="detail-view" src="/oilproduct-details/GHEE/TABLE/Ghee Table.jpg" alt="ogfc-img" />
                                        </div>
                                        <div style={{ textAlign: "center" }} className="tab-main col-md-6 ">

                                            <div className="availabletin">


                                                <div className="image-container">
                                                    <img src={selectedImageasafwah} alt="ogfc-img" />
                                                    <div className="overlay"></div>
                                                    <div className="button-container">



                                                        <span style={{ fontWeight: 600 }}>Available SKU’s</span>:


                                                        <button onMouseOver={() => handleImageChangeasafwah('/oilproduct-details/GHEE/ASAFWAH PRODUCT IMAGES/ASafwah Ghee 1Kg.jpg')}>1Kg</button>
                                                        <button onMouseOver={() => handleImageChangeasafwah('/oilproduct-details/GHEE/ASAFWAH PRODUCT IMAGES/ASafwah Ghee 2Kg.jpg')}>2Kg</button>
                                                        <button onMouseOver={() => handleImageChangeasafwah('/oilproduct-details/GHEE/ASAFWAH PRODUCT IMAGES/ASafwah Ghee 4kg.jpg')}>4Kg</button>
                                                        <button onMouseOver={() => handleImageChangeasafwah('/oilproduct-details/GHEE/ASAFWAH PRODUCT IMAGES/ASafwah Ghee 16Kg.jpg')}>16Kg</button>
                                                    </div>
                                                </div>

                                            </div>





                                        </div>
                                    </div>
                                </div>
                            </Tab.Pane>





                        </Tab.Content>
                    </Col>
                </Row>

                <Row>
                    <Col sm={12}>

                        <Nav style={{ padding: '0px' }} variant="pills" className="d-flex justify-content-center">


                            <Nav.Item style={{ padding: '10px 5px ' }}>
                                <Nav.Link eventKey="first" style={{ background: "#fff", padding: "0px" }}>

                                    <img style={{
                                        width: '100%',
                                        border: '1px solid #f2f2f2',
                                        borderRadius: '30px'
                                    }} src="/oilproduct-details/GHEE/LOGO/Logo  A'Safwah.png" alt="ogfc-img" />
                                </Nav.Link>
                            </Nav.Item>



                        </Nav>

                    </Col>
                </Row>
            </Tab.Container >

            < Aboutenquiry />
            <Footer />





        </div>
    )
}

<>
    <h1>Super Fry</h1>
    <span style={{ fontWeight: '600', marginRight: '10px', }}>Available SKU’s </span>
</>


















export default Ghee