import React, { useRef, useEffect, useState } from 'react';
import { Button, Container, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function Homeabout() {
  const { t, i18n } = useTranslation();
  return (
    <div className='section'>

      <Container>
        <Row>
          <Col>
            <div className='about'>
              <div className='about-header'>
                <h1 style={{ fontFamily: t('fontFamily') }} >{t("ABOUT OGFC")}</h1>

              </div>
              <div className='about-para'>

                <p style={{ fontFamily: t('fontFamilyone'), textAlign: 'center' }}><span style={{ fontWeight: "bold" }}>{t("Omani Gulf Food Company SPC")}</span>{t("homefirstpara")}</p>
              </div>
              <div className='viewmorebutton'>
                <Link to="/about-us"><Button style={{ fontFamily: t('fontFamilyone') }}>{t("READ MORE")}</Button></Link>
              </div>
            </div>
          </Col>
        </Row>
      </Container>




    </div>
  )
}

export default Homeabout