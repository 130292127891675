
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import '../fonts.css'
import { useTranslation } from 'react-i18next';
function Homeslidercontent() {
  const { t, i18n } = useTranslation();
  return (
    <div className='section'>
      <Container>
        <Row>
          <Col>
            <div className="image-text">

              <img
                className=""
                src='/images/slider-below-logo.png'
                alt="ogfc-img"
              />
              <h6 style={{ wordSpacing: "6px", fontFamily: t('fontFamilytwo') }} className='linedown mb-3 sliderone'>
                {t("WELCOME TO")}
                <br></br>
                <span style={{ fontFamily: t('fontFamilytwo') }} className='slidertwo' >{t("OMANI GULF FOOD COMPANY")}</span></h6>
                <p style={{ fontFamily: t('fontFamilyone'), textAlign: 'center' }}><span style={{ fontWeight: "bold"}}>{t("( Subsidiary of Dhofar Foods and Investment SAOG )")}</span></p>

              <div className='fixedlogo'>

                <img
                  className=""
                  src='/images/homefixedlogo/Omani Logo2.png'
                  alt="ogfc-img"
                />
              </div>
            </div >
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Homeslidercontent
