
import React from 'react';

import Aboutenquiry from '../About/Aboutenquiry'
import { Link } from 'react-router-dom'
import { Button } from 'react-bootstrap'

import { Accordion } from 'react-bootstrap';
import { Carousel } from "react-bootstrap";
import Footer from "../Includes/Footer";
import { useTranslation } from 'react-i18next';
function Recipes() {
    const { t, i18n } = useTranslation();
    console.log('t',i18n.language);
    



    return (
        <div>

            <div className="headertop" >
                <Carousel className="slider ">
                    <Carousel.Item>
                        <img className="d-block w-100" src="/recipes/Recipesmall.jpg" alt="ogfc-img" />

                        <div  className="carousel-caption caption-left">
                            <div class="left-liner"></div>
                            <h1 className='linedown' style={{ color: "#000", fontFamily: t('fontFamilytwo') }} > {t("RECIPE")}</h1>
                            <h1 className='linedown ourproductshead' ></h1>

                        </div>

                    </Carousel.Item>
                </Carousel>
            </div>



            <div className="container py-5 ">
                <div className="row">

                    <div className="col-xs-12 col-sm-12   col-md-12  col-lg-5   recipes-img">
                        <img className="mb-5" src=" /recipes/r.jpg" alt="ogfc-img"></img>
                        <img className="my-5" src="/recipes/Recipe-Image-2.jpg" alt="ogfc-img"></img>
                        <img className="my-5" src="/recipes/Recipe-Image-3.jpg" alt="ogfc-img"></img>
                        <img className="my-5" src="/recipes/Recipe-Image-4.jpg" alt="ogfc-img"></img>


                    </div>

                    <div className="col-xs-12   col-sm-12 col-md-12   col-lg-7  px-lg-5  px-md-0 px-sm-0 ">
                        <h4  className={` recipes-head ${ i18n.language === 'ar' ? 'rtl-text':'ltr-text'}`}  style={{ fontFamily: t('fontFamilytwo') }}>{t("ARABIC CHICKEN MANDI")}</h4>
                        <Accordion className="pb-4 "  >
                            <Accordion.Item >


                                <Accordion.Header  className={`p-0 ${ i18n.language === 'ar' ? 'rtl-text':'ltr-text'}` }  style={{ fontFamily: t('fontFamilytwo') }}>{t("INGREDIENTS")}</Accordion.Header>
                                <Accordion.Body   className=" px-5 collapse show health-res">
                                    <ul  className={`${ i18n.language === 'ar' ? 'rtl-text':'ltr-text'}`} style={{ paddingLeft: '2rem', fontFamily: t('fontFamilyone') }}>
                                        <li >{t("MANDI-INGR-1")}</li>
                                        <li>{t("MANDI-INGR-2")}</li>

                                        <li>{t("MANDI-INGR-3")}</li>
                                        <li> {t("MANDI-INGR-4")}</li>
                                        <li>{t("MANDI-INGR-5")}</li>
                                        <li>{t("MANDI-INGR-6")}</li>
                                        <li>{t("MANDI-INGR-7")}</li>
                                        <li>{t("MANDI-INGR-8")}</li>
                                        <li>{t("MANDI-INGR-9")}</li>

                                        <li>{t("MANDI-INGR-11")}</li>
                                        <li>{t("MANDI-INGR-12")}</li>
                                        <li>{t("MANDI-INGR-13")}</li>
                                        <li>{t("MANDI-INGR-14")}</li>
                                        <li> {t("MANDI-INGR-15")}</li>
                                        <li>{t("MANDI-INGR-16")}</li>
                                    </ul>

                                    <h4 className={` inc-recipes ${ i18n.language === 'ar' ? 'rtl-text':'ltr-text'}`}   style={{ fontFamily: t('fontFamilytwo') }}>{t("INGR-MANDI-POWDER")}</h4>
                                    <ul className={`${ i18n.language === 'ar' ? 'rtl-text':'ltr-text'}`} style={{ paddingLeft: '2rem', fontFamily: t('fontFamilyone') }}>
                                        <li>{t("MANDI-POWDER-1")}</li>
                                        <li>{t("MANDI-POWDER-2")}</li>
                                        <li>{t("MANDI-POWDER-3")}</li>
                                        <li>{t("MANDI-POWDER-4")}</li>
                                        <li>{t("MANDI-POWDER-5")}</li>

                                    </ul>
                                </Accordion.Body>
                            </Accordion.Item>


                            <Accordion.Item >
                                <Accordion.Header  className={`${ i18n.language === 'ar' ? 'rtl-text':'ltr-text'}`} style={{ fontFamily: t('fontFamilytwo') }}>{t("Instructions")}</Accordion.Header>
                                <Accordion.Body className="px-5  health-res">
                                    <Accordion>

                                        <h1  className={` inc-recipes ${ i18n.language === 'ar' ? 'rtl-text':'ltr-text'}`}  style={{ fontFamily: t('fontFamilytwo') }}>{t("For mandi spice powder")}</h1>
                                        <ol  className={` ${ i18n.language === 'ar' ? 'rtl-text':'ltr-text'}`} style={{ fontFamily: t('fontFamilyone') }}>
                                            <li>{t("To make the Mandi spice powder, dry roast the cardamom pods, cloves, black pepper, nutmeg dry ginger powder and bay leaves in an on medium heat for 4-6 minutes.")}</li>
                                            <li>{t("Turn off the heat, and allow it to cool.")} </li>
                                            <li>{t("Transfer the spices in a mixer jar, and grind into a fine powder using a mixer grinder.")}</li>


                                        </ol>
                                        <h1   className={`inc-recipes ${ i18n.language === 'ar' ? 'rtl-text':'ltr-text'}`}   style={{ fontFamily: t('fontFamilytwo') }}>{t("For the Chicken")}</h1>

                                        <ol  className={` ${ i18n.language === 'ar' ? 'rtl-text':'ltr-text'}`}   style={{ fontFamily: t('fontFamilyone') }}>
                                            <li>{t("Heat a large saucepan on medium heat and add SAHI Cooking Oil.")}</li>
                                            <li>{t("Once the oil is hot, add finely chopped onion and sauté till translucent. This will take about 2-3 minutes.")}</li>
                                            <li>{t("To the softened onions, add the ginger paste and garlic paste and sauté till the raw smell goes away.")}</li>
                                            <li>{t("Next, add bay leaves, cinnamon, cardamom, cloves and black pepper and mix everything well.")}</li>
                                            <li>{t("In a mixer jar combine, tomato and green chillies and grind into a puree and add it to the pan.")}</li>
                                            <li>{t("When the oil starts to separate, add the chicken and mix. Add 4 cups of water and the Mandi spice powder according to your taste.   Mix, cover and let it cook for about 15 minutes. After 15 minutes, turn off the heat.")} </li>


                                            <li>{t("In a small mixing bowl, add soft butter and Mandi spice powder and some salt, mix well and keep aside.")}</li>
                                            <li>{t("Preheat the oven to 200 degrees centigrade. Remove the chicken from the stock and place it onto a baking tray and brush with butter and mandi spice powder mixture.")} </li>

                                            <li>{t("Bake for 20 to 25 minutes or until the skin of the chicken turns to a nice golden brown colour.")}</li>
                                        </ol>
                                        <h1  className={`inc-recipes  ${ i18n.language === 'ar' ? 'rtl-text':'ltr-text'}`}  style={{ fontFamily: t('fontFamilytwo') }} >{t("For the rice")}</h1>

                                        <ol   className={` ${ i18n.language === 'ar' ? 'rtl-text':'ltr-text'}`}  style={{ fontFamily: t('fontFamilyone') }}>
                                            <li>
                                                {t("Wash and soak basmati rice for at least 20 minutes.")}


                                            </li>
                                            <li>{t("Heat olive oil in a large pan on medium heat, and fry the drained rice, stirring continuously for about 10 to 15 minutes.")} </li>
                                            <li>{t("Add this pan roasted rice to the chicken stock. Now slide a large piece of aluminium foil on top of the saucepan and then cover it with the lid. Cook the rice on medium low flame for about 10 minutes.")}</li>
                                            <li>{t("Remove the lid and check if there is any trace of water left. If water is left, then cover and cook for another 3 to 5 minutes. Now fluff the rice with a fork.")}</li>

                                            <li>{t("Take A’SAFWAH Ghee in a small bowl and place it in the middle of the rice.")}</li>
                                            <li>{t("Heat a piece of charcoal on direct flame until it is hot, this will take about 5 minutes.")}</li>

                                            <li>{t("Once the charcoal is burning hot, place it in the bowl of ghee that is in the rice. Immediately slide back the aluminium foil and secure with lid.")}</li>

                                            <li>{t("Open it only at the time of serving, as it gives a nice Smokey flavour to the rice. To serve, spread rice onto a large serving tray and place a piece of baked chicken on the top.")}</li>

                                            <li>{t("Serve Arabian Chicken Mandi with Raw Mango raita and pickled onions for a delicious weeknight dinner.")} </li>
                                        </ol>

                                    </Accordion>

                                </Accordion.Body>
                            </Accordion.Item>



                        </Accordion>

                        <h4  className={`recipes-head  ${ i18n.language === 'ar' ? 'rtl-text':'ltr-text'}`} style={{ fontFamily: t('fontFamilytwo') }}>{t("LambAnd Date Stew")}</h4>
                        <Accordion className="pb-4  health-res">
                            <Accordion.Item >
                                <Accordion.Header  className={` ${ i18n.language === 'ar' ? 'rtl-text':'ltr-text'}`} style={{ fontFamily: t('fontFamilytwo') }}>{t("INGREDIENTS")}</Accordion.Header>
                                <Accordion.Body className="px-5">
                                    <ul className={` ${ i18n.language === 'ar' ? 'rtl-text':'ltr-text'}`} style={{ paddingLeft: '2rem', fontFamily: t('fontFamilyone') }}>




                                        <li>{t("6 table spoon SUNDROPS Sunflower Oil")}</li>
                                        <li>{t("4 Onions, thinly sliced")}</li>
                                        <li>{t("2 tablespoon finely chopped fresh root Ginger")}</li>
                                        <li>{t("2kg Boneless lamb shoulder, cut into 5cm chunks")}</li>
                                        <li>{t("4 tsp ground cumin")}</li>


                                        <li>{t("2 tsp each Paprika and ground Coriander")}</li>
                                        <li>{t("2 Cinnamon sticks")}</li>
                                        <li>{t("850ml Pasta")}</li>
                                        <li>{t("700g Sweet potatoes, cut into chunks")}</li>
                                        <li>{t("350g Pitted dates")}</li>

                                    </ul>

                                </Accordion.Body>
                            </Accordion.Item>


                            <Accordion.Item >
                                <Accordion.Header className={` ${ i18n.language === 'ar' ? 'rtl-text':'ltr-text'}`}  style={{ fontFamily: t('fontFamilytwo') }}>{t("Instructions")}</Accordion.Header>
                                <Accordion.Body className="px-5  health-res">


                                    <ol className={` ${ i18n.language === 'ar' ? 'rtl-text':'ltr-text'}`}  style={{ fontFamily: t('fontFamilyone') }}>
                                        <li>{t("Heat the SUNDROPS Sunflower Oil in a large, deep pan. Add the onions, then gently fry until softened, about 5 mins. Stir in the ginger, add the meat in batches, and then fry on all sides until lightly coloured. Return all the meat to the pan, stir in the spices and cinnamon sticks, then cook for 1 minutes.")}</li>
                                        <li>{t("Add the pasta and 800ml water, then bring to the boil, stirring. Season well, then cover and simmer for 1½ hrs, until the lamb is tender.")} </li>
                                        <li>{t("Add the sweet potatoes, stir well, cover again, then cook for 20 mins or until the potatoes are just tender. Stir in the dates and heat through for 5 mins. Taste and add more seasoning if necessary. To serve, spoon the tagine into a serving dish and scatter with the almonds and coriander.")}</li>


                                    </ol>
                                </Accordion.Body>
                            </Accordion.Item>



                        </Accordion>







                    </div>
                </div>





















            </div>



            <div>
                <div className='productcompny' style={{ backgroundColor: '#dfdfdf' }}>
                    <div className='' style={{
                        backgroundColor: '#fff', padding: '40px 0px'
                    }}>
                        < div className='container' >

                            <div className='row'>

                                <div className='col-md-5'>
                                    <div className='about-company-head'>
                                        <h1 style={{ fontFamily: t('fontFamilytwo') }}>{t("Trusted By More Than 300+ Popular Private Labels Across Gulf Region")}</h1>
                                        <Link style={{ fontFamily: t('fontFamilyone') }} to="/ourproduct"> <Button >{t("DISCOVER NOW")}</Button></Link>
                                    </div>
                                </div>
                                <div className='col-md-7'>
                                    <ul className='aboutbrands'>
                                        <li><img src='/aboutimages/brand1.jpg' alt="ogfc-img"></img></li>
                                        <li><img src='/aboutimages/brand6.jpg' alt="ogfc-img"></img></li>
                                        <li><img src='/aboutimages/brand2.jpg' alt="ogfc-img"></img></li>
                                        <li><img src='/aboutimages/brand3.jpg' alt="ogfc-img"></img></li>
                                        <li><img src='/aboutimages/brand4.jpg' alt="ogfc-img"></img></li>
                                        <li><img src='/aboutimages/brand5.jpg' alt="ogfc-img"></img></li>
                                        <li><img src='/aboutimages/brand7.jpg' alt="ogfc-img"></img></li>
                                    </ul>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div >
            <Aboutenquiry />
            <Footer />





        </div>
    )
}

export default Recipes






