import React from 'react';
import { Carousel } from "react-bootstrap";
import Footer from '../Includes/Footer';
import Aboutenquiry from '../About/Aboutenquiry';

import { useTranslation } from 'react-i18next';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';
import { useState } from 'react';

function Newssub() {
  const { t, i18n } = useTranslation();

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalIsOpen1, setModalIsOpen1] = useState(false);
  const [modalIsOpen2, setModalIsOpen2] = useState(false);
  const [modalIsOpen3, setModalIsOpen3] = useState(false);
  const [modalIsOpen4, setModalIsOpen4] = useState(false);
  const [modalIsOpen5, setModalIsOpen5] = useState(false);
  const [modalIsOpen6, setModalIsOpen6] = useState(false);
  const [modalIsOpen7, setModalIsOpen7] = useState(false);
  const [modalIsOpen8, setModalIsOpen8] = useState(false);



  const toggleModal = () => {

    setModalIsOpen3(false);
    setModalIsOpen2(false);
    setModalIsOpen1(false);
    setModalIsOpen4(false);
     setModalIsOpen5(false);
     setModalIsOpen6(false);
     setModalIsOpen7(false);
     setModalIsOpen8(false);
    setModalIsOpen(!modalIsOpen);
  };
  const toggleModal1 = () => {
    setModalIsOpen3(false);
    setModalIsOpen2(false);
    setModalIsOpen(false);
    setModalIsOpen4(false);
     setModalIsOpen5(false);
     setModalIsOpen6(false);
     setModalIsOpen7(false);
    setModalIsOpen1(!modalIsOpen1);
    setModalIsOpen8(false);

  };
  const toggleModal2 = () => {
    setModalIsOpen3(false);
    setModalIsOpen(false);
    setModalIsOpen1(false);
    setModalIsOpen4(false);
     setModalIsOpen5(false);
     setModalIsOpen6(false);
     setModalIsOpen7(false);
    setModalIsOpen2(!modalIsOpen2);
    setModalIsOpen8(false);

  };
  const toggleModal3 = () => {
    setModalIsOpen(false);
    setModalIsOpen2(false);
    setModalIsOpen1(false);
    setModalIsOpen4(false);
     setModalIsOpen5(false);
     setModalIsOpen6(false);
     setModalIsOpen7(false);
    setModalIsOpen3(!modalIsOpen3);
    setModalIsOpen8(false);

  };

  const toggleModal4 = () => {
    setModalIsOpen(false);
    setModalIsOpen2(false);
    setModalIsOpen1(false);
    setModalIsOpen3(false);
    setModalIsOpen5(false);
    setModalIsOpen6(false);
    setModalIsOpen7(false);
    setModalIsOpen8(false);
    setModalIsOpen4(!modalIsOpen4);
  };


  const toggleModal5 = () => {
    setModalIsOpen(false);
    setModalIsOpen2(false);
    setModalIsOpen1(false);
    setModalIsOpen3(false);
    setModalIsOpen4(false);
    setModalIsOpen7(false);
    setModalIsOpen6(false);
    setModalIsOpen5(!modalIsOpen5);
    setModalIsOpen8(false);

  };


  const toggleModal6 = () => {
    setModalIsOpen(false);
    setModalIsOpen2(false);
    setModalIsOpen1(false);
    setModalIsOpen3(false);
    setModalIsOpen4(false);
    setModalIsOpen5(false);
    setModalIsOpen6(!modalIsOpen6);
    setModalIsOpen7(false);
    setModalIsOpen8(false);

  };

  const toggleModal7 = () => {
    setModalIsOpen(false);
    setModalIsOpen2(false);
    setModalIsOpen1(false);
    setModalIsOpen3(false);
    setModalIsOpen4(false);
    setModalIsOpen5(false);
    setModalIsOpen6(false);
    setModalIsOpen7(!modalIsOpen7);
    setModalIsOpen8(false);

  };


  const toggleModal8 = () => {
    setModalIsOpen(false);
    setModalIsOpen2(false);
    setModalIsOpen1(false);
    setModalIsOpen3(false);
    setModalIsOpen4(false);
    setModalIsOpen5(false);
    setModalIsOpen6(false);
    setModalIsOpen8(!modalIsOpen8);
    setModalIsOpen7(false);

  };

  return (
    <div >



      <div className="headertop" >
        <Carousel className="slider ">
          <Carousel.Item>
       
            <img className="d-block w-100" src="/gallery/promotionbanner.jpg" alt="ogfc-img" />

            <div className="carousel-gallery" >

              <h1 className='linedown' style={{ color: "#fff", fontFamily: t('fontFamilytwo') }} > {t("NEWS")}</h1>

            </div>

          </Carousel.Item>
        </Carousel>
      </div>
      <div className='container'>

        <div className='section'>
          <div className='row'>

            
          <div className='col-sm-12 col-md-6 col-lg-4'>
            <div className='media-news'>
              <img src='/images/news/OGFC Participate Saudi Food Show 2024.jpg' alt="ogfc-img" onClick={toggleModal8}></img>
              <p style={{ fontFamily: t('fontFamilyone') }}>{t("OGFC Participate Saudi Food Show 2024")}</p>
            </div>

            <Modal
              isOpen={modalIsOpen8}
              onRequestClose={toggleModal8}
              contentLabel="Zoomed Image"
              className="modal"
              overlayClassName="overlay"
            >
              <img src='/images/news/OGFC Participate Saudi Food Show 2024.jpg' alt="Zoomed In" />
              <button className="close-button" onClick={toggleModal8} style={{ fontFamily: t('fontFamilyone') }}>
                {t("Close")}
              </button>
            </Modal>
          </div>


          <div className='col-sm-12 col-md-6 col-lg-4'>
            <div className='media-news'>
              <img src='/images/news/OGFC Participate Gulfood Exhibition 2024.jpg' alt="ogfc-img" onClick={toggleModal7}></img>
              <p style={{ fontFamily: t('fontFamilyone') }}>{t("OGFC Participate Gulfood Exhibition 2024")}</p>
            </div>

            <Modal
              isOpen={modalIsOpen7}
              onRequestClose={toggleModal7}
              contentLabel="Zoomed Image"
              className="modal"
              overlayClassName="overlay"
            >
              <img src='/images/news/OGFC Participate Gulfood Exhibition 2024.jpg' alt="Zoomed In" />
              <button className="close-button" onClick={toggleModal7} style={{ fontFamily: t('fontFamilyone') }}>
                {t("Close")}
              </button>
            </Modal>
          </div>
     


<div className='col-sm-12 col-md-6 col-lg-4'>
            <div className='media-news'>
              <img src='/images/news/Oman-Brand-Leadership-Award_In.jpg' alt="ogfc-img" onClick={toggleModal6}></img>
              <p style={{ fontFamily: t('fontFamilyone') }}>{t("Most Admired Marketing Leaders Award_ World Marketing Congress 2023")}</p>
            </div>

            <Modal
              isOpen={modalIsOpen6}
              onRequestClose={toggleModal6}
              contentLabel="Zoomed Image"
              className="modal"
              overlayClassName="overlay"
            >
              <img src='/images/news/Oman-Brand-Leadership-Award_In.jpg' alt="Zoomed In" />
              <button className="close-button" onClick={toggleModal6} style={{ fontFamily: t('fontFamilyone') }}>
                {t("Close")}
              </button>
            </Modal>
          </div>




            <div className='col-sm-12 col-md-6 col-lg-4'>
            
              <div className='media-news'>
                <img src='/gallery/MEDIA NEWS/Certificate Showing Image.jpg' alt="ogfc-img" onClick={toggleModal4}></img>
                <p style={{ fontFamily: t('fontFamilyone') }}>{t("Oman Brand Leadership Awards-2023")}</p>
              </div>

              <Modal
                isOpen={modalIsOpen4}
                onRequestClose={toggleModal4}
                contentLabel="Zoomed Image"
                className="modal"
                overlayClassName="overlay"
              >
                <img src='/gallery/MEDIA NEWS/Certificate Showing Image.jpg' alt="Zoomed In" />
                <button className="close-button" onClick={toggleModal4} style={{ fontFamily: t('fontFamilyone') }}>
                  {t("Close")}
                </button>
              </Modal>
            </div>

            <div className='col-sm-12 col-md-6 col-lg-4'>
            
              <div className='media-news'>
                <img src='/gallery/MEDIA NEWS/Oman-Brand-Leadership-Award_Lin.jpg' alt="ogfc-img" onClick={toggleModal5}></img>
                <Link to="https://www.linkedin.com/feed/update/urn:li:activity:7093498190582153216/" target="_blank"><p>https://www.linkedin.com/feed/update/
                  <br></br>
                  urn:li:activity:7093498190582153216/</p></Link>
              </div>

              <Modal
                isOpen={modalIsOpen5}
                onRequestClose={toggleModal5}
                contentLabel="Zoomed Image"
                className="modal"
                overlayClassName="overlay"
              >
                <img src='/gallery/MEDIA NEWS/Oman-Brand-Leadership-Award_Lin.jpg' alt="Zoomed In" />
                <button className="close-button" onClick={toggleModal5} style={{ fontFamily: t('fontFamilyone') }}>
                  {t("Close")}
                </button>
              </Modal>
            </div>





            <div className='col-sm-12 col-md-6 col-lg-4'>
           
              <div className='media-news'>
                <img src='/images/news/Leadership.jpg' alt="ogfc-img" onClick={toggleModal}></img>
                <p style={{ fontFamily: t('fontFamilyone') }}>{t("Oman Brand Leadership Awards-2023")}</p>
              </div>

              <Modal
                isOpen={modalIsOpen}
                onRequestClose={toggleModal}
                contentLabel="Zoomed Image"
                className="modal"
                overlayClassName="overlay"
              >
                <img src='/images/news/Oman-Brand-Leadership-Awards-2023.jpg' alt="Zoomed In" />
                <button className="close-button" onClick={toggleModal} style={{ fontFamily: t('fontFamilyone') }}>
                  {t("Close")}
                </button>
              </Modal>
            </div>


            <div className='col-sm-12 col-md-6 col-lg-4'>
           


              <div className='media-news'>
                <img src='/images/news/news2.jpg' onClick={toggleModal1} alt="ogfc-img"></img>
                <p style={{ fontFamily: t('fontFamilyone') }}>{t("Quality Leadership Award @ The Middle East Quality Leadership Awards")}</p>
              </div>

              <Modal
                isOpen={modalIsOpen1}
                onRequestClose={toggleModal1}
                contentLabel="Zoomed Image"
                className="modal"
                overlayClassName="overlay"
              >
                <img src='/images/news/Quality-Leadership-Award-@-The-Middle-East-Quality-Leadership-Awards.jpg' alt="Zoomed In" />
                <button className="close-button" onClick={toggleModal1} style={{ fontFamily: t('fontFamilyone') }}>
                  {t("Close")}
                </button>
              </Modal>

            </div>
            <div className='col-sm-12 col-md-6 col-lg-4'>
           

              <div className='media-news'>
                <img src='/images/news/news3.jpg' onClick={toggleModal2} alt="ogfc-img"></img>
                <p style={{ fontFamily: t('fontFamilyone') }}>{t("Meeting with Mr.Thomas Mielke , Managing Director, Oil World")}</p>
              </div>


              <Modal
                isOpen={modalIsOpen2}
                onRequestClose={toggleModal2}
                contentLabel="Zoomed Image"
                className="modal"
                overlayClassName="overlay"
              >
                <img src='/images/news/Meeting-with-Mr.Thomas-Mielke-,-Managing-Director,-Oil-World.jpg' alt="Zoomed In" />
                <button className="close-button" onClick={toggleModal2} style={{ fontFamily: t('fontFamilyone') }}>
                  {t("Close")}
                </button>
              </Modal>


            </div>
            <div className='col-sm-12 col-md-6 col-lg-4'>


            
              <div className='media-news'>
                <img src='/images/news/newslast.jpg' onClick={toggleModal3} alt="ogfc-img"></img>
                <p style={{ fontFamily: t('fontFamilyone') }}>{t("OGFC Participate Gulfood Exhibition 2023")}</p>
              </div>



              <Modal
                isOpen={modalIsOpen3}
                onRequestClose={toggleModal3}
                contentLabel="Zoomed Image"
                className="modal"
                overlayClassName="overlay"
              >
                <img src='/images/news/OGFC-Participate-Gulfood-Exhibition-2023.jpg' alt="Zoomed In" />
                <button className="close-button" onClick={toggleModal3} style={{ fontFamily: t('fontFamilyone') }}>
                  {t("Close")}
                </button>
              </Modal>




            </div>


          </div>



        </div>


      </div>


      <Aboutenquiry />
      <Footer />



    </div >
  )
}

export default Newssub