
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function Ogfcproducts() {

    const { t, i18n } = useTranslation();
    return (
        <div className='section'>


            <Container>
                <Row>
                    <Col>
                        <div className='about'>
                            <div className='about-header'>
                                <h1 style={{ fontFamily: t('fontFamilytwo') }}>{t("FROM THE HOUSE OF OGFC")}</h1>
                            </div>
                            <div className='about-para'>
                                <p style={{ fontFamily: t('fontFamilyone') }}>{t("Within a relatively short span of time, OGFC built 35 Brand of vibrant portfolio achieved impressive market standup")}</p>
                            </div>
                            <div className='productlist'>
                                <ul class="menu">
                                    <Link to="/palmolein-details">  <li className='nonoilp'><a href="#">
                                        <img src="images/products/palm.jpg"></img>


                                        <h6 style={{ fontFamily: t('fontFamilyone') }}>{t("PALM OLEIN")}

                                        </h6>


                                    </a>


                                    </li></Link>
                                    <Link to="/sunfloweroil-details">   <li className='nonoilp'>
                                        <a href="#"><img src="images/products/Sunfloweroilimage.jpg"></img>
                                            <h6 style={{ fontFamily: t('fontFamilyone') }}>{t("SUNFLOWER OIL")}</h6>


                                        </a>
                                    </li></Link>
                                    <Link to="/cornoil-details">    <li className='nonoilp'><a href="#"><img src="images/products/Cornoilimage.jpg"></img>
                                        <h6 style={{ fontFamily: t('fontFamilyone') }}>{t("CORN OIL")}</h6>
                                    </a></li></Link>
                                    <Link to="/cooking-oil"> <li className='nonoilp'><a href="#"><img src="images/products/bi.jpg"></img>

                                        <h6 style={{ fontFamily: t('fontFamilyone') }}>{t("COOKING OIL")}</h6>
                                    </a></li></Link>
                                    <Link to="/shortening">  <li className='nonoilp'><a href="#"><img src="images/products/shortening.jpg"></img>

                                        <h6 style={{ fontFamily: t('fontFamilyone') }}>{t("SHORTENING")}</h6>
                                    </a></li></Link>
                                </ul>

                            </div>

                        </div>



                    </Col>
                </Row>
            </Container>



        </div>
    )
}

export default Ogfcproducts