
import React, { useState } from 'react';

import Aboutenquiry from '../About/Aboutenquiry'
import { Tab, Nav } from 'react-bootstrap';

import { Col, Row, Carousel } from 'react-bootstrap'

import Footer from "../Includes/Footer";
import { useTranslation } from 'react-i18next';
function Sunfloweroilproductdetails() {
    const { t, i18n } = useTranslation();


















    const [selectedImagesundrop, setSelectedImagesundrop] = useState('/SUNFLOWER OIL/PRODUCTS IMAGES/SUNDROPS/Sundrops Sunflower Oil 750ml.jpg');

    const [selectedImagesahi, setSelectedImagesahi] = useState('/SUNFLOWER OIL/PRODUCTS IMAGES/SAHI/Sahi Sunflower Oil 750ml.jpg');
    const [selectedImagetaibah, setSelectedImagetaibah] = useState('/SUNFLOWER OIL/PRODUCTS IMAGES/TAIBAH/Taibah Sunflower Oil 750ml.jpg');
    const [selectedImagesama, setSelectedImagesama] = useState('/SUNFLOWER OIL/PRODUCTS IMAGES/SAMA/Sama Sunflower oil 750ml.jpg');
    const [selectedImagealtayebat, setSelectedImagealtayebat] = useState('/SUNFLOWER OIL/PRODUCTS IMAGES/AL TAYEBAT/Al Tayebat Sunflower Oil 1p5L.jpg');
    const [selectedImagejabalshams, setSelectedImagejabalshams] = useState('/SUNFLOWER OIL/PRODUCTS IMAGES/JABAL SHAMS/Jabal Shams Sunflower Oil 1p8L.jpg');
    const [selectedImagealmassa, setSelectedImagealmassa] = useState('/SUNFLOWER OIL/PRODUCTS IMAGES/JABAL SHAMS/Jabal Shams Sunflower Oil 1p8L.jpg');
    const [selectedImagesofia, setSelectedImagesofia] = useState('/SUNFLOWER OIL/PRODUCTS IMAGES/SOFIA/Sofia Sunflower Oil 3L.jpg');
    const [selectedImagediyah, setSelectedImagediyah] = useState('/SUNFLOWER OIL/PRODUCTS IMAGES/SOFIA/Sofia Sunflower Oil 3L.jpg');


    const handleImageChangesundrop = (imageName) => {
        setSelectedImagesundrop(imageName);
    };
    const handleImageChangesahi = (imageName) => {
        setSelectedImagesahi(imageName);
    };
    const handleImageChangetaibah = (imageName) => {
        setSelectedImagetaibah(imageName);
    };
    const handleImageChangesama = (imageName) => {
        setSelectedImagesama(imageName);
    };
    const handleImageChangealtayebat = (imageName) => {
        setSelectedImagealtayebat(imageName);
    };
    const handleImageChangejabalshams = (imageName) => {
        setSelectedImagejabalshams(imageName);
    };
    const handleImageChangealmassa = (imageName) => {
        setSelectedImagealmassa(imageName);
    };
    const handleImageChangesofia = (imageName) => {
        setSelectedImagesofia(imageName);
    };
    const handleImageChangediyah = (imageName) => {
        setSelectedImagediyah(imageName);
    };













    return (
        <div>


            <div className="headertop" >
                <Carousel className="slider ">
                    <Carousel.Item>
                        <img className="d-block w-100  " src="/SUNFLOWER OIL/BANNER/sunflowernew.jpg" alt="ogfc-img" />

                        <div className="carousel-caption caption-left">
                            <div class="left-line2"></div>
                            <h1 className='linedown' style={{ fontFamily: t('fontFamilytwo') }}>{t("SUNFLOWER OIL")}
                            </h1>

                        </div>

                    </Carousel.Item>
                </Carousel>
            </div>








            <Tab.Container defaultActiveKey="eight">

                <Row>
                    <Col sm={12}>
                        <Tab.Content>








                            <Tab.Pane eventKey="eight">

                                <div className="container  py-5">
                                    <div className="row">
                                        <div className="col-md-6 product-details product-details-heading">
                                            <img style={{
                                                border: '1px solid #f2f2f2',
                                                borderRadius: '30px'
                                            }} src="/SUNFLOWER OIL/LOGO/Sundrops Logo.png" alt="ogfc-img" />
                                            <h1 style={{ fontFamily: t('fontFamilyone') }}>{t("SUNFLOWER OIL")}</h1>

                                            <h6 style={{ fontFamily: t('fontFamilyone') }}>{t("Ingredients :")}</h6>


                                            <h6 style={{ fontFamily: t('fontFamilyone') }}>{t("Refined Sunflower Oil,Antioxidant (E319)")} <br></br>   {t("Vitamin A & D")}
                                            </h6>
                                            <div class="productfree">
                                                <h2 className="rise" style={{ fontFamily: t('fontFamilytwo') }}>{t("FREE FROM TRANS FAT")}</h2>
                                            </div>
                                            <img className="detail-view" src="/SUNFLOWER OIL/TABLE/Sunflower Oil Table.jpg" alt="Slide 1" />
                                        </div>
                                        <div style={{ textAlign: "center" }} className="tab-main col-md-6 ">


                                            <div className="availabletin">


                                                <div className="image-container">
                                                    <img src={selectedImagesundrop} alt="ogfc-img" />
                                                    <div className="overlay"></div>
                                                    <div className="button-container">




                                                        <span style={{ fontWeight: 600 }}>{t("Available SKU’s")}</span>:
                                                        <button onMouseOver={() => handleImageChangesundrop('/SUNFLOWER OIL/PRODUCTS IMAGES/SUNDROPS/Sundrops Sunflower Oil 750ml.jpg')}>750ML</button>
                                                        <button onMouseOver={() => handleImageChangesundrop('/SUNFLOWER OIL/PRODUCTS IMAGES/SUNDROPS/Sundrops Sunflower Oil 1L.jpg')}>1L</button>
                                                        <button onMouseOver={() => handleImageChangesundrop('/SUNFLOWER OIL/PRODUCTS IMAGES/SUNDROPS/Sundrops Sunflower Oil 1p5L.jpg')}>1.5L</button>
                                                        <button onMouseOver={() => handleImageChangesundrop('/SUNFLOWER OIL/PRODUCTS IMAGES/SUNDROPS/Sundrops Sunflower Oil 1p8L.jpg')}>1.8L</button>

                                                        <button onMouseOver={() => handleImageChangesundrop('/SUNFLOWER OIL/PRODUCTS IMAGES/SUNDROPS/Sundrops Sunflower Oil 3L.jpg')}>3L</button>
                                                        <button onMouseOver={() => handleImageChangesundrop('/SUNFLOWER OIL/PRODUCTS IMAGES/SUNDROPS/Sundrops Sunflower Oil 4L.jpg')}>4L</button>

                                                        <button onMouseOver={() => handleImageChangesundrop('/SUNFLOWER OIL/PRODUCTS IMAGES/SUNDROPS/Sundrops Sunflower Oil 5L.jpg')}>5L</button>
                                                        <button onMouseOver={() => handleImageChangesundrop('/SUNFLOWER OIL/PRODUCTS IMAGES/SUNDROPS/Sundrops Sunflower Oil 9L.jpg')}>9L</button>
                                                        <button onMouseOver={() => handleImageChangesundrop('/SUNFLOWER OIL/PRODUCTS IMAGES/SUNDROPS/Sundrops Sunflower Oil 17L.jpg')}>17L Tin</button>
                                                        <button onMouseOver={() => handleImageChangesundrop('/SUNFLOWER OIL/PRODUCTS IMAGES/SUNDROPS/Sundrops Sunflower Oil 20L.jpg')}>20L</button>


                                                    </div>
                                                </div>

                                            </div>



                                        </div>
                                    </div>
                                </div>

                            </Tab.Pane>



                            <Tab.Pane eventKey="nine">

                                <div className="container  py-5">
                                    <div className="row">
                                        <div className="col-md-6 product-details product-details-heading">
                                            <img style={{
                                                border: '1px solid #f2f2f2',
                                                borderRadius: '30px'
                                            }} src="/SUNFLOWER OIL/LOGO/Taibah Logo.png" alt="Slide 1" />
                                            <h1 style={{ fontFamily: t('fontFamilyone') }}>{t("SUNFLOWER OIL")}</h1>

                                            <h6 style={{ fontFamily: t('fontFamilyone') }}>{t("Ingredients :")}</h6>


                                            <h6 style={{ fontFamily: t('fontFamilyone') }}>{t("Refined Sunflower Oil,Antioxidant (E319)")} <br></br>   {t("Vitamin A & D")}
                                            </h6>
                                            <div class="productfree">
                                                <h2 className="rise" style={{ fontFamily: t('fontFamilytwo') }}>{t("FREE FROM TRANS FAT")}</h2>
                                            </div>
                                            <img className="detail-view" src="/SUNFLOWER OIL/TABLE/Sunflower Oil Table.jpg" alt="Slide 1" />
                                        </div>
                                        <div style={{ textAlign: "center" }} className="tab-main col-md-6 ">


                                            <div className="availabletin">


                                                <div className="image-container">
                                                    <img src={selectedImagetaibah} alt="ogfc-img" />
                                                    <div className="overlay"></div>
                                                    <div className="button-container">



                                                        <span style={{ fontWeight: 600 }}>{t("Available SKU’s")}</span>:
                                                        <button onMouseOver={() => handleImageChangetaibah('/SUNFLOWER OIL/PRODUCTS IMAGES/TAIBAH/Taibah Sunflower Oil 750ml.jpg')}>750ML</button>
                                                        <button onMouseOver={() => handleImageChangetaibah('/SUNFLOWER OIL/PRODUCTS IMAGES/TAIBAH/Taibah Sunflower Oil 1p5L.jpg')}>1.5L</button>
                                                        <button onMouseOver={() => handleImageChangetaibah('/SUNFLOWER OIL/PRODUCTS IMAGES/TAIBAH/Taibah Sunflower Oil 1p8L.jpg')}>1.8L</button>
                                                        <button onMouseOver={() => handleImageChangetaibah('/SUNFLOWER OIL/PRODUCTS IMAGES/TAIBAH/Taibah Sunflower Oil 5L.jpg')}>5L</button>


                                                    </div>
                                                </div>

                                            </div>



                                        </div>
                                    </div>
                                </div>

                            </Tab.Pane>


                            <Tab.Pane eventKey="six">

                                <div className="container  py-5">
                                    <div className="row">
                                        <div className="col-md-6 product-details product-details-heading">
                                            <img style={{
                                                border: '1px solid #f2f2f2',
                                                borderRadius: '30px'
                                            }} src="/SUNFLOWER OIL/LOGO/Sahi Logo.png" alt="Slide 1" />
                                            <h1 style={{ fontFamily: t('fontFamilyone') }}>{t("SUNFLOWER OIL")}</h1>

                                            <h6 style={{ fontFamily: t('fontFamilyone') }}>{t("Ingredients :")}</h6>


                                            <h6 style={{ fontFamily: t('fontFamilyone') }}>{t("Refined Sunflower Oil,Antioxidant (E319)")} <br></br>   {t("Vitamin A & D")}
                                            </h6>
                                            <div class="productfree">
                                                <h2 className="rise" style={{ fontFamily: t('fontFamilytwo') }}>{t("FREE FROM TRANS FAT")}</h2>
                                            </div>
                                            <img className="detail-view" src="/SUNFLOWER OIL/TABLE/Sunflower Oil Table.jpg" alt="Slide 1" />
                                        </div>
                                        <div style={{ textAlign: "center" }} className="tab-main col-md-6 ">





                                            <div className="availabletin">


                                                <div className="image-container">
                                                    <img src={selectedImagesahi} alt="ogfc-img" />
                                                    <div className="overlay"></div>
                                                    <div className="button-container">




                                                        <span style={{ fontWeight: 600 }}>{t("Available SKU’s")}</span>:
                                                        <button onMouseOver={() => handleImageChangesahi('/SUNFLOWER OIL/PRODUCTS IMAGES/SAHI/Sahi Sunflower Oil 750ml.jpg')}>750ML</button>
                                                        <button onMouseOver={() => handleImageChangesahi('/SUNFLOWER OIL/PRODUCTS IMAGES/SAHI/Sahi Sunflower Oil 1p5L.jpg')}>1.5L</button>
                                                        <button onMouseOver={() => handleImageChangesahi('/SUNFLOWER OIL/PRODUCTS IMAGES/SAHI/Sahi Sunflower Oil 1p5L.jpg')}>1.8L</button>
                                                        <button onMouseOver={() => handleImageChangesahi('/SUNFLOWER OIL/PRODUCTS IMAGES/SAHI/Sahi Sunflower Oil 3L.jpg')}>3L</button>
                                                        <button onMouseOver={() => handleImageChangesahi('/SUNFLOWER OIL/PRODUCTS IMAGES/SAHI/Sahi Sunflower Oil 5L.jpg')}>5L</button>
                                                        <button onMouseOver={() => handleImageChangesahi('/SUNFLOWER OIL/PRODUCTS IMAGES/SAHI/Sahi Sunflower Oil 18L.jpg')}>18L</button>

                                                    </div>
                                                </div>

                                            </div>



                                        </div>
                                    </div>
                                </div>



                            </Tab.Pane>


                            <Tab.Pane eventKey="five">

                                <div className="container  py-5">
                                    <div className="row">
                                        <div className="col-md-6 product-details product-details-heading">
                                            <img style={{
                                                border: '1px solid #f2f2f2',
                                                borderRadius: '30px'
                                            }} src="/SUNFLOWER OIL/LOGO/Logo  Sama.png" alt="Slide 1" />
                                            <h1 style={{ fontFamily: t('fontFamilyone') }}>{t("SUNFLOWER OIL")}</h1>

                                            <h6 style={{ fontFamily: t('fontFamilyone') }}>{t("Ingredients :")}</h6>


                                            <h6 style={{ fontFamily: t('fontFamilyone') }}>{t("Refined Sunflower Oil,Antioxidant (E319)")} <br></br>   {t("Vitamin A & D")}
                                            </h6>
                                            <div class="productfree">
                                                <h2 className="rise" style={{ fontFamily: t('fontFamilytwo') }}>{t("FREE FROM TRANS FAT")}</h2>
                                            </div>
                                            <img className="detail-view" src="/SUNFLOWER OIL/TABLE/Sunflower Oil Table.jpg" alt="Slide 1" />
                                        </div>
                                        <div style={{ textAlign: "center" }} className="tab-main col-md-6 ">



                                            <div className="availabletin">


                                                <div className="image-container">
                                                    <img src={selectedImagesama} alt="ogfc-img" />
                                                    <div className="overlay"></div>
                                                    <div className="button-container">

                                                        <span style={{ fontWeight: 600 }}>{t("Available SKU’s")}</span>:
                                                        <button onMouseOver={() => handleImageChangesama('/SUNFLOWER OIL/PRODUCTS IMAGES/SAMA/Sama Sunflower oil 750ml.jpg')}>750ML</button>
                                                        <button onMouseOver={() => handleImageChangesama('/SUNFLOWER OIL/PRODUCTS IMAGES/SAMA/Sama Sunflower oil 1p8L.jpg')}>1.8L</button>


                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>



                            </Tab.Pane>
                            <Tab.Pane eventKey="second">
                                <div className="container  py-5">
                                    <div className="row">
                                        <div className="col-md-6 product-details product-details-heading">
                                            <img style={{
                                                border: '1px solid #f2f2f2',
                                                borderRadius: '30px'
                                            }} src="/SUNFLOWER OIL/LOGO/Al Tayebat Logo.png" alt="Slide 1" />
                                            <h1 style={{ fontFamily: t('fontFamilyone') }}>{t("SUNFLOWER OIL")}</h1>

                                            <h6 style={{ fontFamily: t('fontFamilyone') }}>{t("Ingredients :")}</h6>


                                            <h6 style={{ fontFamily: t('fontFamilyone') }}>{t("Refined Sunflower Oil,Antioxidant (E319)")} <br></br>   {t("Vitamin A & D")}
                                            </h6>
                                            <div class="productfree">
                                                <h2 className="rise" style={{ fontFamily: t('fontFamilytwo') }}>{t("FREE FROM TRANS FAT")}</h2>
                                            </div>
                                            <img className="detail-view" src="/SUNFLOWER OIL/TABLE/Sunflower Oil Table.jpg" alt="Slide 1" />
                                        </div>
                                        <div style={{ textAlign: "center" }} className="tab-main col-md-6 ">


                                            <div className="availabletin">


                                                <div className="image-container">
                                                    <img src={selectedImagealtayebat} alt="ogfc-img" />
                                                    <div className="overlay"></div>
                                                    <div className="button-container">

                                                        <span style={{ fontWeight: 600 }}>{t("Available SKU’s")}</span>:
                                                        <button onMouseOver={() => handleImageChangealtayebat('/SUNFLOWER OIL/PRODUCTS IMAGES/AL TAYEBAT/Al Tayebat Sunflower Oil 1p5L.jpg')}>1.5L</button>



                                                    </div>
                                                </div>

                                            </div>





                                        </div>
                                    </div>
                                </div>
                            </Tab.Pane>




                            <Tab.Pane eventKey="third">
                                <div className="container  py-5">
                                    <div className="row">
                                        <div className="col-md-6 product-details product-details-heading">
                                            <img style={{
                                                border: '1px solid #f2f2f2',
                                                borderRadius: '30px'
                                            }} src="/SUNFLOWER OIL/LOGO/Jabal Shams  Logo.png" alt="Slide 1" />
                                            <h1 style={{ fontFamily: t('fontFamilyone') }}>{t("SUNFLOWER OIL")}</h1>

                                            <h6 style={{ fontFamily: t('fontFamilyone') }}>{t("Ingredients :")}</h6>


                                            <h6 style={{ fontFamily: t('fontFamilyone') }}>{t("Refined Sunflower Oil,Antioxidant (E319)")} <br></br>   {t("Vitamin A & D")}
                                            </h6>
                                            <div class="productfree">
                                                <h2 className="rise" style={{ fontFamily: t('fontFamilytwo') }}>{t("FREE FROM TRANS FAT")}</h2>
                                            </div>
                                            <img className="detail-view" src="/SUNFLOWER OIL/TABLE/Sunflower Oil Table.jpg" alt="Slide 1" />
                                        </div>
                                        <div style={{ textAlign: "center" }} className="tab-main col-md-6 ">



                                            <div className="availabletin">


                                                <div className="image-container">
                                                    <img src={selectedImagejabalshams} alt="ogfc-img" />
                                                    <div className="overlay"></div>
                                                    <div className="button-container">



                                                        <span style={{ fontWeight: 600 }}>{t("Available SKU’s")}</span>:
                                                        <button onMouseOver={() => handleImageChangejabalshams('/SUNFLOWER OIL/PRODUCTS IMAGES/JABAL SHAMS/Jabal Shams Sunflower Oil 1p8L.jpg')}>1.8L</button>
                                                        <button onMouseOver={() => handleImageChangejabalshams('/SUNFLOWER OIL/PRODUCTS IMAGES/JABAL SHAMS/Jabal Shams Sunflower Oil 4L.jpg')}>4L</button>


                                                    </div>
                                                </div>

                                            </div>




                                        </div>
                                    </div>
                                </div>
                            </Tab.Pane>






                            <Tab.Pane eventKey="first">
                                <div className="container  py-5">


                                    <div className="row">
                                        <div className="col-md-6 product-details product-details-heading">
                                            <img style={{
                                                border: '1px solid #f2f2f2',
                                                borderRadius: '30px'
                                            }} src="/SUNFLOWER OIL/LOGO/Al Massa Logo.png" alt="Slide 1" />
                                            <h1 style={{ fontFamily: t('fontFamilyone') }}>{t("SUNFLOWER OIL")}</h1>

                                            <h6 style={{ fontFamily: t('fontFamilyone') }}>{t("Ingredients :")}</h6>


                                            <h6 style={{ fontFamily: t('fontFamilyone') }}>{t("Refined Sunflower Oil,Antioxidant (E319)")} <br></br>   {t("Vitamin A & D")}
                                            </h6>
                                            <div class="productfree">
                                                <h2 className="rise" style={{ fontFamily: t('fontFamilytwo') }}>{t("FREE FROM TRANS FAT")}</h2>
                                            </div>
                                            <img className="detail-view" src="/SUNFLOWER OIL/TABLE/Sunflower Oil Table.jpg" alt="Slide 1" />
                                        </div>
                                        <div style={{ textAlign: "center" }} className="tab-main col-md-6 ">


                                            <div className="availabletin">


                                                <div className="image-container">
                                                    <img src={selectedImagealmassa} alt="ogfc-img" />
                                                    <div className="overlay"></div>
                                                    <div className="button-container">



                                                        <span style={{ fontWeight: 600 }}>{t("Available SKU’s")}</span>:

                                                        <button onMouseOver={() => handleImageChangealmassa('/SUNFLOWER OIL/PRODUCTS IMAGES/AL MASSA/Al Massa Sunflower Oil 5L.jpg')}>5L</button>

                                                    </div>
                                                </div>

                                            </div>




                                        </div>
                                    </div>
                                </div>
                            </Tab.Pane>
















































                            <Tab.Pane eventKey="fourth">
                                <div className="container  py-5">
                                    <div className="row">
                                        <div className="col-md-6 product-details product-details-heading">
                                            <img style={{
                                                border: '1px solid #f2f2f2',
                                                borderRadius: '30px'
                                            }} src="/SUNFLOWER OIL/LOGO/Logo  Diyah.png" alt="Slide 1" />
                                            <h1 style={{ fontFamily: t('fontFamilyone') }}>{t("SUNFLOWER OIL")}</h1>

                                            <h6 style={{ fontFamily: t('fontFamilyone') }}>{t("Ingredients :")}</h6>


                                            <h6 style={{ fontFamily: t('fontFamilyone') }}>{t("Refined Sunflower Oil,Antioxidant (E319)")} <br></br>   {t("Vitamin A & D")}
                                            </h6>
                                            <div class="productfree">
                                                <h2 className="rise" style={{ fontFamily: t('fontFamilytwo') }}>{t("FREE FROM TRANS FAT")}</h2>
                                            </div>
                                            <img className="detail-view" src="/SUNFLOWER OIL/TABLE/Sunflower Oil Table.jpg" alt="Slide 1" />
                                        </div>
                                        <div style={{ textAlign: "center" }} className="tab-main col-md-6 ">


                                            <div className="availabletin">


                                                <div className="image-container">
                                                    <img src={selectedImagediyah} alt="ogfc-img" />
                                                    <div className="overlay"></div>
                                                    <div className="button-container">

                                                        <span style={{ fontWeight: 600 }}>{t("Available SKU’s")}</span>:
                                                        <button onMouseOver={() => handleImageChangediyah('/SUNFLOWER OIL/PRODUCTS IMAGES/DIYAH/Diyah Sunflower Oil 1p8L.jpg')}>1.8L</button>


                                                    </div>
                                                </div>

                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </Tab.Pane>





                            <Tab.Pane eventKey="seven">

                                <div className="container  py-5">
                                    <div className="row">
                                        <div className="col-md-6 product-details product-details-heading">
                                            <img style={{
                                                border: '1px solid #f2f2f2',
                                                borderRadius: '30px'
                                            }} src="/SUNFLOWER OIL/LOGO/Sofia Logo.png" alt="Slide 1" />
                                            <h1 style={{ fontFamily: t('fontFamilyone') }}>{t("SUNFLOWER OIL")}</h1>

                                            <h6 style={{ fontFamily: t('fontFamilyone') }}>{t("Ingredients :")}</h6>


                                            <h6 style={{ fontFamily: t('fontFamilyone') }}>{t("Refined Sunflower Oil,Antioxidant (E319)")} <br></br>   {t("Vitamin A & D")}
                                            </h6>
                                            <div class="productfree">
                                                <h2 className="rise" style={{ fontFamily: t('fontFamilytwo') }}>{t("FREE FROM TRANS FAT")}</h2>
                                            </div>

                                            <img className="detail-view" src="/SUNFLOWER OIL/TABLE/Sunflower Oil Table.jpg" alt="Slide 1" />
                                        </div>
                                        <div style={{ textAlign: "center" }} className="tab-main col-md-6 ">

                                            <div className="availabletin">


                                                <div className="image-container">
                                                    <img src={selectedImagesofia} alt="ogfc-img" />
                                                    <div className="overlay"></div>
                                                    <div className="button-container">


                                                        <span style={{ fontWeight: 600 }}>{t("Available SKU’s")}</span>:
                                                        <button onMouseOver={() => handleImageChangesofia('/SUNFLOWER OIL/PRODUCTS IMAGES/SOFIA/Sofia Sunflower Oil 3L.jpg')}>3L</button>
                                                        <button onMouseOver={() => handleImageChangesofia('/SUNFLOWER OIL/PRODUCTS IMAGES/SOFIA/Sofia Sunflower Oil 8L.jpg')}>8L</button>


                                                    </div>
                                                </div>

                                            </div>




                                        </div>
                                    </div>
                                </div>

                            </Tab.Pane>




                        </Tab.Content>
                    </Col>
                </Row>

                <Row>
                    <Col sm={12}>

                        <Nav style={{ padding: '0px' }} variant="pills" className="d-flex justify-content-center">
                            <Nav.Item style={{ padding: '10px 5px' }}>
                                <Nav.Link eventKey="eight" style={{ background: "#fff", padding: "0px" }}>

                                    <img style={{
                                        width: '100%',
                                        border: '1px solid #f2f2f2',
                                        borderRadius: '30px'
                                    }} src="/SUNFLOWER OIL/LOGO/Sundrops Logo.png" alt="Slide 1" />
                                </Nav.Link>
                            </Nav.Item>






                            <Nav.Item style={{ padding: '10px 5px' }}>
                                <Nav.Link eventKey="six" style={{ background: "#fff", padding: "0px" }}>
                                    <img style={{
                                        width: '100%',
                                        border: '1px solid #f2f2f2',
                                        borderRadius: '30px'
                                    }} src="/SUNFLOWER OIL/LOGO/Sahi Logo.png" alt="Slide 1" />

                                </Nav.Link>
                            </Nav.Item>


                            <Nav.Item style={{ padding: '10px 5px' }}>
                                <Nav.Link eventKey="nine" style={{ background: "#fff", padding: "0px" }}>

                                    <img style={{
                                        width: '100%',
                                        border: '1px solid #f2f2f2',
                                        borderRadius: '30px'
                                    }} src="/SUNFLOWER OIL/LOGO/Taibah Logo.png" alt="Slide 1" />
                                </Nav.Link>
                            </Nav.Item>




                            <Nav.Item style={{ padding: '10px 5px' }}>
                                <Nav.Link eventKey="five" style={{ background: "#fff", padding: "0px" }}>
                                    <img style={{
                                        width: '100%',
                                        border: '1px solid #f2f2f2',
                                        borderRadius: '30px'
                                    }} src="/SUNFLOWER OIL/LOGO/Logo  Sama.png" alt="Slide 1" />

                                </Nav.Link>
                            </Nav.Item>


                            <Nav.Item style={{ padding: '10px 5px' }}>
                                <Nav.Link eventKey="second" style={{ background: "#fff", padding: "0px" }}>
                                    <img style={{
                                        width: '100%',
                                        border: '1px solid #f2f2f2',
                                        borderRadius: '30px'
                                    }} src="/SUNFLOWER OIL/LOGO/Al Tayebat Logo.png" alt="Slide 1" />
                                </Nav.Link>
                            </Nav.Item>






                            <Nav.Item style={{ padding: '10px 5px' }}>
                                <Nav.Link eventKey="third" style={{ background: "#fff", padding: "0px" }}>
                                    <img style={{
                                        width: '100%',
                                        border: '1px solid #f2f2f2',
                                        borderRadius: '30px'
                                    }} src="/SUNFLOWER OIL/LOGO/Jabal Shams  Logo.png" alt="Slide 1" />
                                </Nav.Link>
                            </Nav.Item>





                            <Nav.Item style={{ padding: '10px 5px ' }}>
                                <Nav.Link eventKey="first" style={{ background: "#fff", padding: "0px" }}>

                                    <img style={{
                                        width: '100%',
                                        border: '1px solid #f2f2f2',
                                        borderRadius: '30px'
                                    }} src="/SUNFLOWER OIL/LOGO/Al Massa Logo.png" alt="Slide 1" />
                                </Nav.Link>
                            </Nav.Item>






                            <Nav.Item style={{ padding: '10px 5px' }}>
                                <Nav.Link eventKey="seven" style={{ background: "#fff", padding: "0px" }}>

                                    <img style={{
                                        width: '100%',
                                        border: '1px solid #f2f2f2',
                                        borderRadius: '30px'
                                    }} src="/SUNFLOWER OIL/LOGO/Sofia Logo.png" alt="Slide 1" />
                                </Nav.Link>
                            </Nav.Item>




                            <Nav.Item style={{ padding: '10px 5px' }}>
                                <Nav.Link eventKey="fourth" style={{ background: "#fff", padding: "0px" }}>

                                    <img style={{
                                        width: '100%',
                                        border: '1px solid #f2f2f2',
                                        borderRadius: '30px'
                                    }} src="/SUNFLOWER OIL/LOGO/Logo  Diyah.png" alt="Slide 1" />
                                </Nav.Link>
                            </Nav.Item>











                        </Nav>

                    </Col>
                </Row>
            </Tab.Container >

            < Aboutenquiry />
            <Footer />




        </div >
    )
}



export default Sunfloweroilproductdetails