import { BiArrowToTop } from "react-icons/bi";
import React, { useRef, useEffect, useState } from 'react';

import Aboutenquiry from '../About/Aboutenquiry'
import { Tab, Nav } from 'react-bootstrap';

import { Col, Row, Carousel } from 'react-bootstrap'
import { Image } from 'react-bootstrap';
import Footer from "../Includes/Footer";
import { useTranslation } from "react-i18next";
function Fryingoil() {
    const { t, i18n } = useTranslation();




    const [selectedSize, setSelectedSize] = useState('17L');






    const handleSizeChange = (size) => {
        setSelectedSize(size);
    };





    return (
        <div>


            <div className="headertop" >
                <Carousel className="slider ">
                    <Carousel.Item>
                        <img className="d-block w-100  " src="/oilproduct-details/FRYING OIL/BANNER/fryingnew.jpg" alt="ogfc-img" />

                        <div className="carousel-caption caption-left">
                            <div class="left-line2"></div>
                            <h1 className='linedown' style={{ fontFamily: t('fontFamilytwo') }} >{t("FRYING OIL")}
                            </h1>

                        </div>

                    </Carousel.Item>
                </Carousel>
            </div>








            <Tab.Container defaultActiveKey="first">

                <Row>
                    <Col sm={12}>
                        <Tab.Content>

                            <Tab.Pane eventKey="first">
                                <div className="container  py-5">


                                    <div className="row">
                                        <div className="col-md-6 product-details product-details-heading">
                                            <img style={{

                                                border: '1px solid #f2f2f2',
                                                borderRadius: '30px'
                                            }} src="/oilproduct-details/FRYING OIL/LOGO/Logo Super Fry.png" alt="ogfc-img" />
                                            <h1 style={{ fontFamily: t('fontFamilyone') }}>{t("FRYING OIL")}</h1>

                                            <h6 style={{ fontFamily: t('fontFamilyone') }}>{t("Ingredients :")}</h6>
                                            <h6 style={{ fontFamily: t('fontFamilyone') }}>{t("D.F Olein, Refined Sunflower Oil,")}<br></br>

                                                {t("Emulsifier (E471), Antioxidant (E321), Antifoaming Agent (E900),")}<br></br>
                                                {t("Vitamin A & D")}

                                            </h6>

                                            <div class="productfree">
                                                <h2 className="rise" style={{ fontFamily: t('fontFamilytwo') }}>{t("FREE FROM TRANS FAT")}</h2>
                                            </div>
                                            <img className="detail-view" src="/oilproduct-details/FRYING OIL/TABLE/Frying Oil Table.jpg" alt="ogfc-img" />
                                        </div>
                                        <div style={{ textAlign: "center" }} className="tab-main col-md-6 ">
                                            <Image src={getImageForSize(selectedSize)} alt="ogfc-img" />
                                            <Row className="py-3 px-0" >
                                                <div className="availabletin">
                                                    <span class="availablehead" style={{ fontWeight: 600 }}>{t("Available SKU’s")}</span>:
                                                    <button onClick={() => handleSizeChange('17L')}>17L</button>

                                                </div>
                                            </Row>

                                        </div>
                                    </div>
                                </div>
                            </Tab.Pane>





                        </Tab.Content>
                    </Col>
                </Row>

                <Row>
                    <Col sm={12}>

                        <Nav style={{ padding: '0px' }} variant="pills" className="d-flex justify-content-center">


                            <Nav.Item style={{ padding: '10px 5px ' }}>
                                <Nav.Link eventKey="first" style={{ background: "#fff", padding: "0px" }}>

                                    <img style={{
                                        width: '100%',
                                        border: '1px solid #f2f2f2',
                                        borderRadius: '30px'
                                    }} src="/oilproduct-details/FRYING OIL/LOGO/Logo Super Fry.png" alt="ogfc-img" />
                                </Nav.Link>
                            </Nav.Item>



                        </Nav>

                    </Col>
                </Row>
            </Tab.Container >

            < Aboutenquiry />
            <Footer />





        </div>
    )
}

<>
    <h1>Super Fry</h1>
    <span style={{ fontWeight: '600', marginRight: '10px', }}>Available SKU’s </span>
</>



const getImageForSize = (size) => {
    switch (size) {
        case '17L':
            return '/oilproduct-details/FRYING OIL/SUPER FRY PRODUCT IMAGES/Super Fry Frying Oil 17L.jpg';

    }
};














export default Fryingoil