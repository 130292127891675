import React from 'react'
import Productslider from './Productslider'


import Productdetails from './Productdetails'


function Ourproduct() {

    return (
        <div>


            <Productslider />

            <Productdetails />



        </div>
    )
}

export default Ourproduct