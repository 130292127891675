
import '../Product.css'

import React, { useRef, useEffect, useState } from 'react';
import { Button } from "react-bootstrap";
import { Link } from 'react-router-dom';

import Footer from '../Includes/Footer';
import { useTranslation } from 'react-i18next';
function Productdetails() {
    const { t, i18n } = useTranslation();

    return (
        <div >
            <div className='container py-5'>
                <div className='row'>
                    <div className='col-md-12 product-para'>
                        <p style={{ fontFamily: t('fontFamilytwo') }}><span style={{ fontWeight: 'bold', textAlign: 'center' }}>{t("Omani Gulf Food Company-para")}</span>{t("our-head")}</p>


                    </div>

                </div>

                <div className='row'>
                    <div className='col-md-6'>
                        <div className='product-image'>
                            <img src="/products/Product1.jpg" alt="ogfc-img"></img>
                            <div className='content-img'>
                                <h1 style={{ color: "345d4a", fontFamily: t('fontFamilyone') }}>{t("OIL PRODUCTS")}</h1>
                                <Link style={{ fontFamily: t('fontFamilyone') }} to="/oil-products">   <Button style={{ backgroundColor: "#345d4a", borderColor: "#345d4a" }}>{t("ALL PRODUCTS")}</Button></Link>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='product-image'>
                            <img src="/products/Product2.jpg" alt="ogfc-img"></img>
                            <div className='content-img'>
                                <h1 style={{ fontFamily: t('fontFamilyone') }}>{t("NON-OIL PRODUCTS")}</h1>
                                <Link style={{ fontFamily: t('fontFamilyone') }} to="/non-oilproducts">    <Button style={{ backgroundColor: "#435f73", borderColor: "#435f73" }}>{t("ALL PRODUCTS")}</Button></Link>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div >
                <div className='productcompny' style={{ backgroundColor: '#dfdfdf' }}>
                    <div className='' style={{
                        backgroundColor: '#fff', padding: '40px 0px'
                    }}>
                        < div className='container' >

                            <div className='row'>

                                <div className='col-md-5'>
                                    <div className='about-company-head'>
                                        <h1 style={{ fontFamily: t('fontFamilytwo') }}>{t("Trusted By More Than 300+ Popular Private Labels Across Gulf Region")}.</h1>
                                        <Link to="/ourproduct"> <Button style={{ fontFamily: t('fontFamilyone') }}>{t("DISCOVER NOW")}</Button></Link>
                                    </div>
                                </div>
                                <div className='col-md-7'>
                                    <ul className='aboutbrands'>
                                        <li><img src='/aboutimages/brand1.jpg' alt="ogfc-img"></img></li>
                                        <li><img src='/aboutimages/brand6.jpg' alt="ogfc-img"></img></li>
                                        <li><img src='/aboutimages/brand2.jpg' alt="ogfc-img"></img></li>
                                        <li><img src='/aboutimages/brand3.jpg' alt="ogfc-img"></img></li>
                                        <li><img src='/aboutimages/brand4.jpg' alt="ogfc-img"></img></li>
                                        <li><img src='/aboutimages/brand5.jpg' alt="ogfc-img"></img></li>
                                        <li><img src='/aboutimages/brand7.jpg' alt="ogfc-img"></img></li>
                                    </ul>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div >

            <Footer />




        </div >

    )
}

export default Productdetails