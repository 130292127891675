import React from 'react';
import Aboutenquiry from "../About/Aboutenquiry";
import { Link } from 'react-router-dom'
import { Button, Col, Row, Form, Carousel } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import Footer from "../Includes/Footer";


function Careersmain() {
  const { t, i18n } = useTranslation();
  return (
    <div>
      <div className="headertop" >
        <Carousel className="slider ">
          <Carousel.Item>
            <img className="d-block w-100  " src="/careers/careersmainnew.jpg" alt="ogfc-img" />
            <div className="carousel-caption caption-left">
              <div class="left-liner"></div>
              <h1 className='linedown' style={{ fontFamily: t('fontFamilytwo'), color: "#000" }}  >{t("CAREERS")}
              </h1>
            </div>
          </Carousel.Item>
        </Carousel>
      </div>
      <div>
        <div className='productcompny' style={{ backgroundColor: '#dfeaa5;' }}>
          <div className='' style={{
            backgroundColor: '#dbe4a3', padding: '40px 0px'
          }}>
            <div className='container' >
              <div className='row'>
                <div className='careersmain-head'>
                  <h1 style={{ fontFamily: t('fontFamilytwo') }}>{t("CURRENT OPENING")}</h1>
                  <p style={{ fontFamily: t('fontFamilyone') }}>{t("We always welcome applications from who want to help make difference for both customers and the world")}</p>
                  <div className='careers-opening'>
                    <span className='address-head' style={{ fontFamily: t('fontFamilytwo') }}>{t("CONTACT US")}</span>
                  </div>

                  <p style={{ fontFamily: t('fontFamilyone'), paddingBottom: '0px' }}>{t("Please send your CV to:")}</p>
                  <address>
                    <span className='address-head' style={{ fontFamily: t('fontFamilytwo') }}>{t("HR DEPARTMENT")}</span> <br></br>
                    <span style={{ fontFamily: t('fontFamilyone') }} className='address-headname'>{t("SAID MOHEMMED AL MASHANI")}</span> <br>
                    </br><span style={{ fontFamily: t('fontFamilyone') }}>{t("Manager - Admin & Services")}</span><br></br>
                    <span style={{ fontFamily: t('fontFamilyone') }}>{t("Email-e")}said@omanigulffood.com{t("Email")}</span>
                  </address>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
      <div>
        <div className='productcompny' style={{ backgroundColor: '#dfdfdf' }}>
          <div className='' style={{
            backgroundColor: '#fff', padding: '40px 0px'
          }}>
            < div className='container' >
              <div className='row'>
                <div className='col-md-5'>
                  <div className='about-company-head'>
                    <h1 style={{ fontFamily: t('fontFamilytwo') }}>{t("Trusted By More Than 300+ Popular Private Labels Across Gulf Region")}</h1>
                    <Link style={{ fontFamily: t('fontFamilyone') }} to="/ourproduct"> <Button >{t("DISCOVER NOW")}</Button></Link>
                  </div>
                </div>
                <div className='col-md-7'>
                  <ul className='aboutbrands'>
                    <li><img src='/aboutimages/brand1.jpg' alt="ogfc-img"></img></li>
                    <li><img src='/aboutimages/brand6.jpg' alt="ogfc-img"></img></li>
                    <li><img src='/aboutimages/brand2.jpg' alt="ogfc-img"></img></li>
                    <li><img src='/aboutimages/brand3.jpg' alt="ogfc-img"></img></li>
                    <li><img src='/aboutimages/brand4.jpg' alt="ogfc-img"></img></li>
                    <li><img src='/aboutimages/brand5.jpg' alt="ogfc-img"></img></li>
                    <li><img src='/aboutimages/brand7.jpg' alt="ogfc-img"></img></li>
                  </ul>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div >
      <Aboutenquiry />
      <Footer />
    </div>
  )
}

export default Careersmain