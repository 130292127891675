
import React, { useState } from 'react';

import Aboutenquiry from '../About/Aboutenquiry'
import { Tab, Nav } from 'react-bootstrap';

import { Col, Row, Carousel } from 'react-bootstrap'
import { Image } from 'react-bootstrap';
import Footer from "../Includes/Footer";
import { useTranslation } from 'react-i18next';
function Dfolein() {
    const { t, i18n } = useTranslation();



    const [selectedSize, setSelectedSize] = useState('1.5L');






    const handleSizeChange = (size) => {
        setSelectedSize(size);
    };




    return (
        <div>


            <div className="headertop" >
                <Carousel className="slider ">
                    <Carousel.Item>
                        <img className="d-block w-100  " src="/oilproduct-details/D.F OLEIN/BANNER/dfnew.jpg" alt="ogfc-img" />

                        <div className="carousel-caption caption-left">
                            <div class="left-line2"></div>
                            <h1 className='linedown' style={{ fontFamily: t('fontFamilytwo') }} >{t("DOUBLE FRACTIONATED OLEIN")}
                            </h1>

                        </div>

                    </Carousel.Item>
                </Carousel>
            </div>




            <Tab.Container defaultActiveKey="first">

                <Row>
                    <Col sm={12}>
                        <Tab.Content>

                            <Tab.Pane eventKey="first">
                                <div className="container  py-5">


                                    <div className="row">
                                        <div className="col-md-6 product-details product-details-heading">
                                            <img style={{
                                                border: '1px solid #f2f2f2',
                                                borderRadius: '30px'
                                            }} src="/oilproduct-details/D.F OLEIN/LOGO/Logo  Al Waha.png" alt="ogfc-img" />
                                            <h1 style={{ fontFamily: t('fontFamilyone') }}>{t("DOUBLE FRACTIONATED OLEIN")}</h1>
                                            <h6 style={{ fontFamily: t('fontFamilyone') }}>{t("Ingredients :")}</h6>
                                            <h6 style={{ fontFamily: t('fontFamilyone') }}>{t("Double Fractionated Olein, Antioxidant (E319),")}<br></br>{t("Vitamin A & D")}
                                            </h6>
                                            <div class="productfree">
                                                <h2 className="rise" style={{ fontFamily: t('fontFamilytwo') }}>{t("FREE FROM TRANS FAT")}</h2>
                                            </div>
                                            <img className="detail-view" src="/oilproduct-details/D.F OLEIN/TABLE/DF Olein Table.jpg" alt="ogfc-img" />
                                        </div>
                                        <div style={{ textAlign: "center" }} className="tab-main col-md-6 ">
                                            <Image src={getImageForSize(selectedSize)} alt="" />
                                            <Row className="py-3 px-0" >
                                                <div className="availabletin">
                                                    <span class="availablehead" style={{ fontWeight: 600 }}>{t("Available SKU’s")}</span>:
                                                    <button onClick={() => handleSizeChange('1.5L')}>1.5L</button>

                                                </div>
                                            </Row>

                                        </div>
                                    </div>
                                </div>
                            </Tab.Pane>





                        </Tab.Content>
                    </Col>
                </Row>

                <Row>
                    <Col sm={12}>

                        <Nav style={{ padding: '0px' }} variant="pills" className="d-flex justify-content-center">


                            <Nav.Item style={{ padding: '10px 5px ' }}>
                                <Nav.Link eventKey="first" style={{ background: "#fff", padding: "0px" }}>

                                    <img style={{
                                        width: '100%',
                                        border: '1px solid #f2f2f2',
                                        borderRadius: '30px'
                                    }} src="/oilproduct-details/D.F OLEIN/LOGO/Logo  Al Waha.png" alt="ogfc-img" />
                                </Nav.Link>
                            </Nav.Item>



                        </Nav>

                    </Col>
                </Row>
            </Tab.Container >

            < Aboutenquiry />
            <Footer />





        </div>
    )
}
<>
    <h1>Super Fry</h1>
    <span style={{ fontWeight: '600', marginRight: '10px', }}>Available SKU’s</span>
</>



const getImageForSize = (size) => {
    switch (size) {
        case '1.5L':
            return '/oilproduct-details/D.F OLEIN/AL WAHA/Al Waha D F olein 1p5L.jpg';

    }
};

export default Dfolein