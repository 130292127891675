import React from 'react';
import { useTranslation } from 'react-i18next';
import './Language.css'
import i18n from './i18jn';
function Language() {
  const { t, i18n } = useTranslation();
  


  const changeLanguage = () => {
    const newLanguage = i18n.language === 'en' ? 'ar' : 'en';
    i18n.changeLanguage(newLanguage);
  };
  return (

    <div>
    <button style={{fontFamily: t('fontFamilyone')}} className="langbutton" onClick={changeLanguage}>{t('عربي')}</button>
   
  </div>
    
  )
}

export default Language
