import React from 'react'
import Aboutslider from './Aboutslider'
import Topbar from '../Includes/Topbar'
import Aboutabout from './Aboutabout'
import Aboutcompany from './Aboutcompany'
import Aboutmission from './Aboutmission'
import Aboutceo from './Aboutceo'
import Aboutboard from './Aboutboard'
import Aboutenquiry from './Aboutenquiry'
import Footer from '../Includes/Footer'




function Aboutogfc() {
  return (
    <div>

      <Aboutslider />
      <Aboutabout />
      <Aboutcompany />
      <Aboutmission />
      <Aboutceo />
      <Aboutboard />
      <Aboutenquiry />
      <Footer />
    </div>
  )
}

export default Aboutogfc
