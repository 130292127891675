import React from 'react'

import { Carousel, Container, Col, Row, Button } from 'react-bootstrap';
import Aboutenquiry from '../About/Aboutenquiry';
import { Link } from 'react-router-dom';

import Footer from '../Includes/Footer';
import { useTranslation } from 'react-i18next';


function Oilproducts() {
    const { t, i18n } = useTranslation();

    return (
        <div>


            <div className="headertop" >
                <Carousel className="slider ">
                    <Carousel.Item>
                        <img className="d-block w-100  " src="/oilproducts/oilslidernew.jpg" alt="ogfc-img" />

                        <div className="carousel-caption caption-left">
                            <div class="left-line5"></div>
                            <h1 style={{ fontFamily: t('fontFamilytwo') }} className='linedown ourproductshead' >{t("OIL")}<br></br>{t("PRODUCTS")}

                            </h1>
                            <p style={{ fontFamily: t('fontFamilyone') }} className='linedown'>{t("Trusted by 300+ world-class")}<br></br>{t("brands in all sizes")}</p>
                            <div className='non-oilproduct'>
                                <Link to="/contact-us"> <button type="button" class="btn btn-primary nonoilproduct" style={{ fontFamily: t('fontFamilyone') }}>{t("ENQUIRE NOW")}</button></Link>
                            </div>
                        </div>

                    </Carousel.Item>
                </Carousel>


            </div>


            <div className="">
                <div className='pt-5'>
                    <Container>
                        <Row className='p-0 mb-4 '>
                            <Col xs={12} md={6} lg={4} className='p-3 oilproducts-company companycertificates'>
                                <img src="/oilproducts/Palm-Oleinnew.jpg" alt="ogfc-img"></img>
                                <div className='oilproducts-viewall'>
                                    <h1 style={{ fontFamily: t('fontFamilyone') }}>{t("PALM OLEIN")}</h1>
                                    < Link to="/palmolein-details" style={{ fontFamily: t('fontFamilyone') }}> <Button className='oil-button'>{t("ALL PRODUCTS")}</Button></Link>
                                </div>


                            </Col>
                            <Col xs={12} md={6} lg={4} className='p-3 companycertificates'>
                                <img src="/oilproducts/Sunflower-Oilnew.jpg" alt="ogfc-img" ></img>
                                <div className='oilproducts-viewall'>
                                    <h1 style={{ fontFamily: t('fontFamilyone') }}>{t("SUNFLOWER OIL")}</h1>
                                    < Link to="/sunfloweroil-details" style={{ fontFamily: t('fontFamilyone') }}> <Button className='oil-button'>{t("ALL PRODUCTS")}</Button></Link>
                                </div>

                            </Col>
                            <Col xs={12} md={6} lg={4} className='p-3 companycertificates'>
                                <img src="/oilproducts/Corn-Oilnew.jpg" alt="ogfc-img"></img>
                                <div className='oilproducts-viewall'>
                                    <h1 style={{ fontFamily: t('fontFamilyone') }}>{t("CORN OIL")}</h1>
                                    < Link to="/cornoil-details" style={{ fontFamily: t('fontFamilyone') }} > <Button className='oil-button'>{t("ALL PRODUCTS")}</Button></Link>

                                </div>
                            </Col>

                            <Col xs={12} md={6} lg={4} className='p-3 companycertificates'>
                                <img src="/oilproducts/Cooking-Oilnew.jpg" alt="ogfc-img"></img>
                                <div className='oilproducts-viewall'>
                                    <h1 style={{ fontFamily: t('fontFamilyone') }}>{t("COOKING OIL")}</h1>
                                    < Link to="/cooking-oil" style={{ fontFamily: t('fontFamilyone') }}> <Button className='oil-button'>{t("ALL PRODUCTS")}</Button></Link>
                                </div>

                            </Col>
                            <Col xs={12} md={6} lg={4} className='p-3 companycertificates'>
                                <img src="/oilproducts/Cornblend-Oilnew.jpg" alt="ogfc-img"></img>
                                <div className='oilproducts-viewall'>
                                    <h1 style={{ fontFamily: t('fontFamilyone') }}>{t("CORNBLEND OIL")} </h1>

                                    < Link to="/cornblend-oil" > <Button className='oil-button' style={{ fontFamily: t('fontFamilyone') }}>{t("ALL PRODUCTS")}</Button></Link>
                                </div>
                            </Col>
                            <Col xs={12} md={6} lg={4} className='p-3 companycertificates'>
                                <img src="/oilproducts/Frying-oil.jpg" alt="ogfc-img"></img>
                                <div className='oilproducts-viewall'>
                                    <h1 style={{ fontFamily: t('fontFamilyone') }}>{t("FRYING OIL")}</h1>

                                    < Link to="/frying-oil" > <Button className='oil-button' style={{ fontFamily: t('fontFamilyone') }}>{t("ALL PRODUCTS")}</Button></Link>
                                </div>
                            </Col>

                            <Col xs={12} md={6} lg={4} className='p-3 companycertificates'>
                                <img src="/oilproducts/D-F-Olein.jpg" alt="ogfc-img"></img>
                                <div className='oilproducts-viewall'>
                                    <h1 style={{ fontFamily: t('fontFamilyone') }}>{t("DOUBLE FRACTIONATED OLEIN")}</h1>
                                    < Link to="/df-olein" > <Button className='oil-button' style={{ fontFamily: t('fontFamilyone') }}>{t("ALL PRODUCTS")}</Button></Link>
                                </div>

                            </Col>
                            <Col xs={12} md={6} lg={4} className='p-3 companycertificates'>
                                <img src="/oilproducts/Gheenew.jpg" alt="ogfc-img" ></img>
                                <div className='oilproducts-viewall'>
                                    <h1 style={{ fontFamily: t('fontFamilyone') }}>{t("GHEE")}</h1>
                                    < Link to="/ghee" > <Button className='oil-button' style={{ fontFamily: t('fontFamilyone') }}>{t("ALL PRODUCTS")}</Button></Link>
                                </div>
                            </Col>
                            <Col xs={12} md={6} lg={4} className='p-3 companycertificates'>
                                <img src="/oilproducts/Shortening-back.jpg" alt="ogfc-img"></img>
                                <div className='oilproducts-viewall'>
                                    <h1 style={{ fontFamily: t('fontFamilyone') }}>{t("SHORTENING")}</h1>
                                    < Link to="/shortening" > <Button className='oil-button' style={{ fontFamily: t('fontFamilyone') }}>{t("ALL PRODUCTS")}</Button></Link>
                                </div>
                            </Col>
                        </Row>
                    </Container >
                </div>



            </div>
            <Aboutenquiry />
            <Footer />

        </div >
    )
}

export default Oilproducts