import React from 'react'


import Aboutenquiry from '../About/Aboutenquiry'
import Importslider from './Importslider'
import Aboutcompany from '../About/Aboutcompany'
import Importabout from './Importabout'

import Footer from '../Includes/Footer'


function Importexport() {
    return (
        <div>

            <Importslider />
            <Importabout />
            <Aboutcompany />


            <Aboutenquiry />
            <Footer />

        </div>
    )
}

export default Importexport