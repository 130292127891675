
import React from 'react'

import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
function Productbrand() {





  const { t, i18n } = useTranslation();

  return (
    <div>

      <div class="parallax">
        <div class="parallax__bgone"></div>
        <div class="parallax__content quote-head">
          <div>

            <div className='about-header'>
              <Container>
                <Row>
                  <Col>

                    <h2 style={{ fontFamily: t('fontFamily') }} >{t("OGFC ADDS LIFE TO LIFE !")}</h2>

                    <h2 style={{ fontFamily: t('fontFamily') }} >{t("SUPERIOR QUALITY OIL FOR A HEALTHY LIFE !")}</h2>
                  </Col>
                </Row>
              </Container>
            </div>

          </div>
        </div>

      </div>
    </div>
  )
}

export default Productbrand

