
import React from 'react';

import Aboutenquiry from '../About/Aboutenquiry'
import { Link } from 'react-router-dom'
import { Button } from 'react-bootstrap'

import { Carousel } from "react-bootstrap";
import './health.css'
import Footer from "../Includes/Footer";
import { useTranslation } from 'react-i18next';

function Healthtips() {

    const { t, i18n } = useTranslation();

    return (
        <div>

            <div className="headertop" >
                <Carousel className="slider ">
                    <Carousel.Item>
                        <img style={{ fontFamily: t('fontFamilytwo') }} className="d-block w-100  " src="/HEALTH TIPS/health.jpg" alt="ogfc-img" />

                        <div className="carousel-caption caption-left">
                            <div class="left-line"></div>
                            <h1 className='linedown healthtips' style={{ color: '#000', fontFamily: t('fontFamilytwo') }}>{t("Choose Health")}<br></br>{t("Choose Life")}
                            </h1>
                            <p className='linedown healthpara' style={{ color: '#000', fontFamily: t('fontFamilyone') }}>{t("He who has health has hope, and he who has hope has everything.")}</p>

                            {/* <p className='linedown healthpara' style={{ color: '#000',fontFamily: t('fontFamilyone') }}>{t("He who has health has hope, and")}<br></br>{t("He who has hope has everything")}</p> */}
                        </div>

                    </Carousel.Item>
                </Carousel>
            </div>




            <div className="container my-5">

                <div className="row">
                    <div className="col-12 col-lg-6">
                        <div className="health-box d-flex">
                            <div className="health-box-img">
                                <img src="/HEALTH TIPS/img1..jpg" alt="ogfc-img"></img>
                            </div>
                            <div className="health-box-img-second ">
                                <h1 style={{ fontFamily: t('fontFamilytwo') }} >{t("Choose the colour of your veggies")}</h1>
                                <p style={{ fontFamily: t('fontFamilyone') }}>{t("Nature color-coded our food! Choosing dark green vegetables insures calcium and other nutrients, while red and orange veggies give us beta carotene, lycopene and other antioxidants. The allium family of vegetables includes onions, garlic, leeks, scallions and chives, and may be instrumental in addressing elevated blood pressure and high cholesterol, as well as bacteria and yeast infections.")}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6">
                        <div className="health-box d-flex">
                            <div className="health-box-img">
                                <img src="/HEALTH TIPS/img2..jpg" alt="ogfc-img"></img>
                            </div>
                            <div className="health-box-img-second ">
                                <h1 style={{ fontFamily: t('fontFamilytwo') }}>{t("Discover fiber-rich fruit")}</h1>
                                <p style={{ fontFamily: t('fontFamilyone') }}>{t("Choose whole fresh fruit instead of juice to get the full intended benefit of nature's original fast food. For optimum digestion, eat fruits at one meal and vegetables at another. Keep in mind that anything that contains seeds is a fruit, botanically speaking. The means that tomatoes, cucumbers and squashes are actually fruits and combine successfully with other fruits for most people.")}</p>
                            </div>
                        </div>
                    </div>
                </div>




                <div className="row">
                    <div className="col-12 col-lg-6">
                        <div className="health-box d-flex">
                            <div className="health-box-img">
                                <img src="/HEALTH TIPS/img3..jpg" alt="ogfc-img"></img>
                            </div>
                            <div className="health-box-img-second ">
                                <h1 style={{ fontFamily: t('fontFamilytwo') }}>{t("Go with the grains")}</h1>
                                <p style={{ fontFamily: t('fontFamilyone') }}>{t("Whole grains, of course. And be adventuresome! Try some quinoa, amaranth and millet along with barley, kamut and spelt berries. All of these are simple to prepare in a crockpot and are simply delicious! Brown rice, whole wheat and oats, as well as stoneground cornmeal are always good choices, as they provide essential vitamins and minerals.")}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6">
                        <div className="health-box d-flex">
                            <div className="health-box-img">
                                <img src="/HEALTH TIPS/img4..jpg" alt="ogfc-img"></img>
                            </div>
                            <div className="health-box-img-second ">
                                <h1 style={{ fontFamily: t('fontFamilytwo') }}>{t("Watch sodium intake")}</h1>
                                <p style={{ fontFamily: t('fontFamilyone') }}>{t("Remember that many foods naturally contain sodium without adding any salt. If you are serving a recipe that's fairly high in sodium, be sure to balance it with side dishes that are lower in sodium.")}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 col-lg-6">
                        <div className="health-box d-flex">
                            <div className="health-box-img">
                                <img src="/HEALTH TIPS/img5..jpg" alt="ogfc-img"></img>
                            </div>
                            <div className="health-box-img-second ">
                                <h1 style={{ fontFamily: t('fontFamilytwo') }}>{t("Make legumes a mainstay")} </h1>
                                <p style={{ fontFamily: t('fontFamilyone') }}>{t("Legumes comprise the large food category in which there are thousands of varieties of dried beans, peas, peanuts and lentils. Richer in protein than any other plant food, legumes are low in fat, contain B vitamins, and lots of minerals. The fiber in beans helps to stabilize blood sugar and should be included in each day's menu.")}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6">
                        <div className="health-box d-flex">
                            <div className="health-box-img">
                                <img src="/HEALTH TIPS/img6..jpg" alt="ogfc-img"></img>
                            </div>
                            <div className="health-box-img-second ">
                                <h1 style={{ fontFamily: t('fontFamilytwo') }}>{t("Get a little nutty")}</h1>
                                <p style={{ fontFamily: t('fontFamilyone') }}>{t("Studies show that nuts are helpful in safeguarding the heart. Raw nuts are best, and all nuts and seeds should be stored in the refrigerator or freezer.")}</p>
                            </div>
                        </div>
                    </div>
                </div>






                <div className="row">
                    <div className="col-12 col-lg-6">
                        <div className="health-box d-flex">
                            <div className="health-box-img">
                                <img src="/HEALTH TIPS/img7..jpg" alt="ogfc-img"></img>
                            </div>
                            <div className="health-box-img-second ">
                                <h1 style={{ fontFamily: t('fontFamilytwo') }}>{t("Max the flax")}</h1>
                                <p style={{ fontFamily: t('fontFamilyone') }}>{t("Two tablespoons a day of ground flaxseed will help prevent constipation and provide 4 grams of omega-3 essential fatty acid. Omega-3 has been found to be beneficial in the prevention and treatment of depression, to assist with normalizing the triglycerides, and it gives the immune system a boost.")}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6">
                        <div className="health-box d-flex">
                            <div className="health-box-img">
                                <img src="/HEALTH TIPS/img8..jpg" alt="ogfc-img"></img>
                            </div>
                            <div className="health-box-img-second ">
                                <h1 style={{ fontFamily: t('fontFamilytwo') }}>{t("Check your oil")}</h1>
                                <p style={{ fontFamily: t('fontFamilyone') }}>{t("Fat is an essential part of our diet, but health depends upon using the right kind of fat. Avoid hydrogenated oils as they contain harmful Trans fatty acids.")}</p>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="row">
                    <div className="col-12 col-lg-6">
                        <div className="health-box d-flex">
                            <div className="health-box-img">
                                <img src="/HEALTH TIPS/img9..jpg" alt="ogfc-img"></img>
                            </div>
                            <div className="health-box-img-second ">
                                <h1 style={{ fontFamily: t('fontFamilytwo') }}>{t("Get calcium")}</h1>
                                <p style={{ fontFamily: t('fontFamilyone') }}>{t("Dark leafy greens like kale, collards and turnip greens provide the best source, but almonds, sesame seeds, figs and carob powder are rich in calcium as well.")}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6">
                        <div className="health-box d-flex">
                            <div className="health-box-img">
                                <img src="/HEALTH TIPS/img10..jpg" alt="ogfc-img"></img>
                            </div>
                            <div className="health-box-img-second ">
                                <h1 style={{ fontFamily: t('fontFamilytwo') }}>{t("Shun the sugar")}</h1>
                                <p style={{ fontFamily: t('fontFamilyone') }}>{t("Keep your intake of even healthy sweets to a minimum, serving desserts only a couple days per week. Whenever possible, use whole-food sweeteners like dates, or other fresh or dried fruits.")}</p>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="row">
                    <div className="col-12 col-lg-6">
                        <div className="health-box d-flex">
                            <div className="health-box-img">
                                <img src="/HEALTH TIPS/img11..jpg" alt="ogfc-img"></img>
                            </div>
                            <div className="health-box-img-second ">
                                <h1 style={{ fontFamily: t('fontFamilytwo') }}>{t("The berry best to you!")}</h1>
                                <p style={{ fontFamily: t('fontFamilyone') }}>{t("Strawberries, blueberries, blackberries: - Scientists are learning that berries are contributing to the prevention of everything from heart disease to cancer to age-related brain decline, and they contain fiber, folate, and significant amounts of vitamin C.")}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6">
                        <div className="health-box d-flex">
                            <div className="health-box-img">
                                <img src="/HEALTH TIPS/img12..jpg" alt="ogfc-img"></img>
                            </div>
                            <div className="health-box-img-second ">
                                <h1 style={{ fontFamily: t('fontFamilytwo') }}>{t("A time to eat, a time to drink")}</h1>
                                <p style={{ fontFamily: t('fontFamilyone') }}>{t("The right foods eaten at the right time provide optimum assimilation of nutrients and the best safeguard against disease. For the most favourable digestion, refrain from drinking with meals any more than one-half cup of liquid. Eat well at breakfast, and wait at least five hours before eating lunch, being sure to drink several glasses of water between meals. A light evening meal may be taken five hours later, though for many, no supper is preferable, to give the digestive system a good rest. To encourage weight loss, adopt a 2-meal-a-day program, by just omitting supper. You'll be thrilled with the results!")}



                                </p>

                            </div>
                        </div>
                    </div>
                </div>











            </div>







            <div>
                <div className='productcompny' style={{ backgroundColor: '#dfdfdf' }}>
                    <div className='' style={{
                        backgroundColor: '#fff', padding: '40px 0px'
                    }}>
                        < div className='container' >

                            <div className='row'>

                                <div className='col-md-5'>
                                    <div className='about-company-head'>

                                        <h1 style={{ fontFamily: t('fontFamilytwo') }}>{t("Trusted By More Than 300+ Popular Private Labels Across Gulf Region")}</h1>
                                        <Link style={{ fontFamily: t('fontFamilyone') }} to="/ourproduct"> <Button style={{ fontFamily: t('fontFamilyone') }}>{t("DISCOVER NOW")}</Button></Link>
                                    </div>
                                </div>
                                <div className='col-md-7'>
                                    <ul className='aboutbrands'>
                                        <li><img src='/aboutimages/brand1.jpg' alt="ogfc-img"></img></li>
                                        <li><img src='/aboutimages/brand6.jpg' alt="ogfc-img"></img></li>
                                        <li><img src='/aboutimages/brand2.jpg' alt="ogfc-img"></img></li>
                                        <li><img src='/aboutimages/brand3.jpg' alt="ogfc-img"></img></li>
                                        <li><img src='/aboutimages/brand4.jpg' alt="ogfc-img"></img></li>
                                        <li><img src='/aboutimages/brand5.jpg' alt="ogfc-img"></img></li>
                                        <li><img src='/aboutimages/brand7.jpg' alt="ogfc-img"></img></li>
                                    </ul>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div >
            <Aboutenquiry />

            <Footer />






        </div>
    )
}

export default Healthtips
