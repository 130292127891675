import React from 'react'
import { Carousel, Container, Col, Row, Button } from 'react-bootstrap';

import Aboutcompany from '../About/Aboutcompany';
import Aboutenquiry from '../About/Aboutenquiry';
import { useTranslation } from 'react-i18next';
import Footer from '../Includes/Footer';
function Certification() {

    const { t, i18n } = useTranslation();
    return (
        <div>

            <div className="headertop" >
                <Carousel className="slider ">
                    <Carousel.Item>
                        <img className="d-block w-100  " src="/certification/certification.jpg" alt="ogfc-img" />

                        <div className="carousel-caption caption-left">
                            <div class="left-line4"></div>
                            <h1 style={{ fontFamily: t('fontFamilytwo') }} className='linedown qualityhead'>{t("QUALITY")}<br></br>{t("CERTIFICATION")}
                            </h1>

                        </div>

                    </Carousel.Item>
                </Carousel>
            </div>
            <div className='about-board  certification-img' style={{ padding: '0px' }}>

                <img className="certi" style={{ width: '100%' }} src="/certification/ourcertificates.jpg" alt="ogfc-img"></img>
                <div className='container certification-about '>
                    <div className='container row'>

                        <div className=' col-12   col-sm-12  col-md-5 col-lg-4'>
                            <div className='quality-certi'>

                                <h1 style={{ fontFamily: t('fontFamilytwo') }}>{t("Our Certificates")}



                                </h1>
                                <p className='px-1' style={{ fontFamily: t('fontFamilyone') }} >
                                    {t("Our manufacturing plant is the state of art technology with most advanced processing equipments & machinery.")}

                                </p>
                            </div>
                        </div>
                        <div className='col-12   col-sm-12 col-md-7 col-lg-8 about-company-board-para m-auto'>

                            <img src="/certification/Logo-main.png" alt="ogfc-img"></img>

                        </div>
                    </div></div>
            </div>



            <div className="certi-div">



                <div className='pt-5 pb-5'>
                    <Container>
                        <Row className='pb-0 mb-4 '>
                            <Col xs={12} md={4} lg={4} xl={4} className='companycertificates'>
                                <img src="/certification/220001.jpg" alt="ogfc-img"></img>
                                <div className='certify-viewall'>

                                </div>


                            </Col>
                            <Col xs={12} md={4} lg={4} xl={4} className='companycertificates'>
                                <img src="/certification/90011.jpg" alt="ogfc-img"></img>
                                <div className='certify-viewall'>

                                </div>

                            </Col>
                            <Col xs={12} md={4} lg={4} xl={4} className='companycertificates'>
                                <img src="/certification/140011.jpg" alt="ogfc-img" ></img>
                                <div className='certify-viewall'>

                                </div>

                            </Col>
                        </Row>
                        <Row className='pb-0 m-0'>
                            <Col xs={12} md={4} lg={4} xl={4} className='companycertificates'>
                                <img src="/certification/HACCP1.jpg" alt="ogfc-img"></img>
                                <div className='certify-viewall'>

                                </div>

                            </Col>
                            <Col xs={12} md={4} lg={4} xl={4} className='companycertificates'>
                                <img src="/certification/halal1.jpg" alt="ogfc-img"></img>
                                <div className='certify-viewall'>

                                </div>

                            </Col>
                            <Col xs={12} md={4} lg={4} xl={4} className='companycertificates'>
                                <img src="/certification/socialdevelopment.jpg" alt="ogfc-img"></img>
                                <div className='certify-viewall'>

                                </div>

                            </Col>
                        </Row>
                    </Container >
                </div>



            </div>
            <Aboutcompany />
            <Aboutenquiry />
            <Footer />

        </div >
    )
}

export default Certification