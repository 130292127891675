
import 'bootstrap/dist/css/bootstrap.min.css';

import 'video-react/dist/video-react.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
// import {HashRouter} from 'react-router-dom';
import { BrowserRouter } from 'react-router-dom';
import Productbrand from './Components/fonts.css';
import { I18nextProvider } from 'react-i18next';
import i18n from './Langhook/i18jn';





const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
     <I18nextProvider i18n={i18n}>
    <BrowserRouter >

      <App />
    </BrowserRouter>
    </I18nextProvider>
  </React.StrictMode>
);

