import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

function Productnonoil() {

  const { t, i18n } = useTranslation();
  const options = {
    margin: 0,
    responsiveClass: true,
    nav: false,
    dots: false,
    autoplay: false,
   

    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 2,
      },
      700: {
        items: 4,
      },
      1000: {
        items: 4,

      }
    },
  };


  return (
    <div className='section'>

      <h3 className="nonoil" style={{ fontFamily: t('fontFamily') }}>{t("NON-OIL PRODUCTS")}</h3>
      <OwlCarousel className='owl-theme' {...options}>
        <div class='item'>
          <div className='boxproduct'>
            <Link to="/non-oilproducts"> <img src="/images/nonoilproducts/Hot-Sauce.jpg" alt="ogfc-img"></img></Link>
          </div>
        </div>
        <div class='item'>
          <div className='boxproduct'>
            <Link to="/non-oilproducts">  <img src="/images/nonoilproducts/Ketchup.jpg" alt="ogfc-img"></img></Link>
          </div>
        </div>
        <div class='item'>
          <div className='boxproduct'>
            <Link to="/non-oilproducts">  <img src="/images/nonoilproducts/Basmati-rice.jpg" alt="ogfc-img"></img></Link>
          </div>
        </div>
        <div class='item'>
          <div className='boxproduct'>
            <Link to="/non-oilproducts">     <img src="/images/nonoilproducts/Tuna-Chunks.jpg" alt="ogfc-img"></img></Link>
          </div>
        </div>

      </OwlCarousel>


    </div>

  )
}

export default Productnonoil
