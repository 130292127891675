import React, { useState } from 'react';
import Popup from './Manufacturingpara';
import { useTranslation } from 'react-i18next';
import { Player, ControlBar, PosterImage } from 'video-react';

function Aboutabout() {
  const { t, i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [isVideo, setIsVideo] = useState(false);
  const togglePopup = () => {
    setIsOpen(!isOpen);
  }
  const togglePopupVideo = () => {
    setIsVideo(!isVideo);
  }
  return (

    <div className='aboutabout'>
      <div className='container'>
        <div className='row'>
          <div className='col-xs-12   col-sm-12 col-md-12   col-lg-6'>
            <div className='about-second-para '>
              <h1 style={{ fontFamily: t('fontFamilytwo') }} >{t("Manufacturing Nutritious Edible Oil")}</h1>
              <p style={{ fontFamily: t('fontFamilyone') }}>{t("Omani Gulf Food Company SPC is a leading oil manufacturer across gulf region with active footprints across KSA/UAE/OMAN and Africa with commendable brand recall and consumer choice.")}
                <br></br>
                <br></br>       <input style={{ fontFamily: t('fontFamilyone') }}
                  className='manu-popup'
                  type="button"
                  value={t("READ MORE")}
                  onClick={togglePopup}
                />
                {isOpen && <Popup
                  content={<>
                    <p style={{ fontFamily: t('fontFamilyone') }}>
                      {t("Omani Gulf Food Company SPC is a leading oil manufacturer across gulf region with active footprints across KSA/UAE/OMAN and Africa with commendable brand recall and consumer choice and promoted by three prominent business leaders in Oman - Dhofar Foods and Investment SAOG, Dhofar Insurance Co. and Dhofar International Development and Investments Holding Co. Our team is passionate about providing highest quality edible oils to consumers for a better life by adding convenience to their daily routine and healthy living. Our edible oil brands are widely available in the market under the popular names of Al Safa , Noof, Zain, Salalah, Taibah , Sundrops, Sahi, Bahjah, Diyah, Sunlife, Sultanah & A’Safwah .We are also into private labelling for many retail majors, hypermarkets and supermarkets. We use multi model distribution channels across Middle East & African regions. The company continues to expand its business vertical to meet emerging customer needs by venturing into distribution of other Fast Moving Consumer Goods.")}
                    </p>
                  </>}
                  handleClose={togglePopup}
                />}
              </p>
            </div>
          </div>
          <div className='col-xs-12   col-sm-12 col-md-12   col-lg-6 m-auto'>
            <div className='about-img'>
              <img src='aboutimages/Company-about.png'  onClick={togglePopupVideo} alt='ogfc-image' style={{marginBottom:"100px"}}></img>
              {isVideo &&

              
            <Player controls={true} autoPlay={true}
            // poster="/gallery/poster.png"
            src="/aboutimages/OGFC VIDEO2.mp4" alt="ogfc-img">

            <ControlBar autoHide={false} className="my-class" />
        </Player>
           
              }
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}

export default Aboutabout
