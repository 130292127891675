import React from 'react';
import { Carousel } from "react-bootstrap";
import Footer from '../Includes/Footer';
import Aboutenquiry from '../About/Aboutenquiry';
import Galleryvideo from './Galleryvideo';
import { useTranslation } from 'react-i18next';

function Gallerypromotion() {


    const { t, i18n } = useTranslation();

    return (
        <div >



            <div className="headertop" >
                <Carousel className="slider ">
                    <Carousel.Item>
                     
                        <img className="d-block w-100" src="/gallery/promotionbanner.jpg" alt="ogfc-img" />

                        <div className="carousel-gallery" >

                            <h1 className='linedown' style={{ color: "#fff", fontFamily: t('fontFamilytwo') }} > {t("PROMOTION / TVC")}</h1>

                        </div>

                    </Carousel.Item>
                </Carousel>
            </div>

            <Galleryvideo />


            <Aboutenquiry />
            <Footer />



        </div >
    )
}

export default Gallerypromotion