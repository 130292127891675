


import React, { useState } from 'react';

import Aboutenquiry from '../About/Aboutenquiry'
import { Tab, Nav } from 'react-bootstrap';

import { Col, Row, Carousel } from 'react-bootstrap'

import Footer from "../Includes/Footer";
import { useTranslation } from 'react-i18next';
function Cookingoil() {
    const { t, i18n } = useTranslation();







    const [selectedImagesunlife, setSelectedImagesunlife] = useState('/oilproduct-details/COOKING OIL/PRODUCT IMAGES/SUNLIFE/Sunlife Cooking oil 750ml.jpg');
    const [selectedImagesahi, setSelectedImagesahi] = useState('/oilproduct-details/CORN OIL/PRODUCT IMAGES/CORN DELIGHT/Corn Delight Corn Oil 1p5L.jpg');
    const [selectedImagediyah, setSelectedImagediyah] = useState('/oilproduct-details/COOKING OIL/PRODUCT IMAGES/DIYAH/Diyah Cooking Oil 1p8L.jpg');
    const [selectedImageno1, setSelectedImageno1] = useState('/oilproduct-details/COOKING OIL/PRODUCT IMAGES/NO 1/No 1 Cooking Oil 750ml.jpg');
    const [selectedImagesalalah, setSelectedImagesalalah] = useState('/oilproduct-details/COOKING OIL/PRODUCT IMAGES/SALALAH/Salalah Cooking Oil 1p8L.jpg');


    const [selectedImagecooking, setSelectedImagecooking] = useState('/oilproduct-details/COOKING OIL/PRODUCT IMAGES/ZEENATH/Zeenath Cooking Oil 1p5L.jpg');


    const [selectedImagesultanak, setSelectedImagesultanak] = useState('/oilproduct-details/COOKING OIL/PRODUCT IMAGES/SULTANAH/Sultanah Cooking Oil 750ml.jpg');
    const [selectedImagealbarakat, setSelectedImagealbarakat] = useState('/oilproduct-details/COOKING OIL/PRODUCT IMAGES/AL BARAKAT/Al Barakat Cooking Oil 750ml.jpg');
    const [selectedImagedana, setSelectedImagedana] = useState('/oilproduct-details/COOKING OIL/PRODUCT IMAGES/DANA/Dana Cooking Oil 750ml.jpg');
    const [selectedImageluliya, setSelectedImageluliya] = useState('/oilproduct-details/COOKING OIL/PRODUCT IMAGES/LULIYA/Luliya Cooking Oil 1p5L.jpg');






    const handleImageChangesunlife = (imageName) => {
        setSelectedImagesunlife(imageName);
    };

    const handleImageChangeshai = (imageName) => {
        setSelectedImagesahi(imageName);
    };

    const handleImageChangediyah = (imageName) => {
        setSelectedImagediyah(imageName);
    };

    const handleImageChangeno1 = (imageName) => {
        setSelectedImageno1(imageName);
    };
    const handleImageChangesalalah = (imageName) => {
        setSelectedImagesalalah(imageName);
    };
    const handleImageChangecooking = (imageName) => {
        setSelectedImagecooking(imageName);
    };
    const handleImageChangesultanak = (imageName) => {
        setSelectedImagesultanak(imageName);
    };
    const handleImageChangealbarakat = (imageName) => {
        setSelectedImagealbarakat(imageName);
    };

    const handleImageChangedana = (imageName) => {
        setSelectedImagedana(imageName);
    };
    const handleImageChangeluliya = (imageName) => {
        setSelectedImageluliya(imageName);
    };
















    return (
        <div>

            <div className="headertop" >
                <Carousel className="slider ">
                    <Carousel.Item>
                        <img className="d-block w-100  " src="/oilproduct-details/COOKING OIL/BANNER/cookingnew.jpg" alt="ogfc-img" />

                        <div className="carousel-caption caption-left">
                            <div class="left-line2"></div>
                            <h1 className='linedown' style={{ color: '#000', fontFamily: t('fontFamilytwo') }} >{t("COOKING OIL")}
                            </h1>

                        </div>

                    </Carousel.Item>
                </Carousel>
            </div>










            <Tab.Container defaultActiveKey="first">

                <Row>
                    <Col sm={12}>
                        <Tab.Content>

                            <Tab.Pane eventKey="first">
                                <div className="container  py-5">


                                    <div className="row">
                                        <div className="col-md-6 product-details product-details-heading">
                                            <img style={{
                                                border: '1px solid #f2f2f2',
                                                borderRadius: '30px'
                                            }} src="/oilproduct-details/COOKING OIL/LOGO/Sunlife Logo.png" alt="ogfc-img" />
                                            <h1 style={{ fontFamily: t('fontFamilyone') }}>{t("COOKING OIL")}</h1>

                                            <h6 style={{ fontFamily: t('fontFamilyone') }}>{t("Ingredients :")}</h6>

                                            <h6 style={{ fontFamily: t('fontFamilyone') }}>{t("D.F Olein, Refined Sunflower Oil,Antioxidant (E319),")} <br></br>{t("Vitamin A & D")}</h6>


                                            <div class="productfree">
                                                <h2 className="rise" style={{ fontFamily: t('fontFamilytwo') }}>{t("FREE FROM TRANS FAT")}</h2>
                                            </div>

                                            <img className="detail-view" src="/oilproduct-details/COOKING OIL/TABLE/Cooking Table.jpg" alt="ogfc-img" />
                                        </div>
                                        <div style={{ textAlign: "center" }} className="tab-main col-md-6 ">


                                            <div className="availabletin">


                                                <div className="image-container">
                                                    <img src={selectedImagesunlife} alt="ogfc-img" />
                                                    <div className="overlay"></div>
                                                    <div className="button-container">



                                                        
                                                        <span style={{ fontWeight: 600 }}>{t("Available SKU’s")}</span>:
                                                        
                                                        <button onMouseOver={() => handleImageChangesunlife('/oilproduct-details/COOKING OIL/PRODUCT IMAGES/SUNLIFE/Sunlife Cooking oil 1L.jpg')}>1L</button>
                                                        <button onMouseOver={() => handleImageChangesunlife('/oilproduct-details/COOKING OIL/PRODUCT IMAGES/SUNLIFE/Sunlife Cooking oil 1p5L.jpg')}>1.5L</button>
                                                        <button onMouseOver={() => handleImageChangesunlife('/oilproduct-details/COOKING OIL/PRODUCT IMAGES/SUNLIFE/Sunlife Cooking oil 1p8L.jpg')}>1.8L</button>
                                                      
                                                        <button onMouseOver={() => handleImageChangesunlife('/oilproduct-details/COOKING OIL/PRODUCT IMAGES/SUNLIFE/Sunlife Cooking oil 4L.jpg')}>4L</button>
                                                        <button onMouseOver={() => handleImageChangesunlife('/oilproduct-details/COOKING OIL/PRODUCT IMAGES/SUNLIFE/Sunlife Cooking oil 5L.jpg')}>5L</button>
                                                        <button onMouseOver={() => handleImageChangesunlife('/oilproduct-details/COOKING OIL/PRODUCT IMAGES/SUNLIFE/Sunlife Cooking oil 20L.jpg')}>20L</button>
                                                        {/* Add more buttons as needed */}
                                                    </div>
                                                </div>

                                            </div>




                                        </div>
                                    </div>
                                </div>
                            </Tab.Pane>

                            <Tab.Pane eventKey="second">
                                <div className="container  py-5">
                                    <div className="row">
                                        <div className="col-md-6 product-details product-details-heading">
                                            <img style={{
                                                border: '1px solid #f2f2f2',
                                                borderRadius: '30px'
                                            }} src="/oilproduct-details/COOKING OIL/LOGO/Sahi Logo.png" alt="ogfc-img" />
                                            <h1 style={{ fontFamily: t('fontFamilyone') }}>{t("COOKING OIL")}</h1>

                                            <h6 style={{ fontFamily: t('fontFamilyone') }}>{t("Ingredients :")}</h6>

                                            <h6 style={{ fontFamily: t('fontFamilyone') }}>{t("D.F Olein, Refined Sunflower Oil,Antioxidant (E319),")} <br></br>{t("Vitamin A & D")}</h6>


                                            <div class="productfree">
                                                <h2 className="rise" style={{ fontFamily: t('fontFamilytwo') }}>{t("FREE FROM TRANS FAT")}</h2>
                                            </div>

                                            <img className="detail-view" src="/oilproduct-details/COOKING OIL/TABLE/Cooking Table.jpg" alt="Slide 1" />
                                        </div>
                                        <div style={{ textAlign: "center" }} className="tab-main col-md-6 ">



                                            <div className="availabletin">


                                                <div className="image-container">
                                                    <img src={selectedImagesahi} alt="ogfc-img" />
                                                    <div className="overlay"></div>
                                                    <div className="button-container">




                                                        <span style={{ fontWeight: 600 }}>{t("Available SKU’s")}</span>:
                                                        <button onMouseOver={() => handleImageChangeshai('/oilproduct-details/COOKING OIL/PRODUCT IMAGES/SAHI/Sahi Cooking oil 750ml.jpg')}>750ML</button>
                                                        <button onMouseOver={() => handleImageChangeshai('/oilproduct-details/COOKING OIL/PRODUCT IMAGES/SAHI/Sahi Cooking oil 1p5L.jpg')}>1.5L</button>
                                                        <button onMouseOver={() => handleImageChangeshai('/oilproduct-details/COOKING OIL/PRODUCT IMAGES/SAHI/Sahi Cooking oil 1p8L.jpg')}>1.8L</button>
                                                        <button onMouseOver={() => handleImageChangeshai('/oilproduct-details/COOKING OIL/PRODUCT IMAGES/SAHI/Sahi Cooking oil 5L.jpg')}>5L</button>
                                                       
                                                    </div>
                                                </div>

                                            </div>



                                        </div>
                                    </div>
                                </div>
                            </Tab.Pane>



                            <Tab.Pane eventKey="third">
                                <div className="container  py-5">
                                    <div className="row">
                                        <div className="col-md-6 product-details product-details-heading">
                                            <img style={{
                                                border: '1px solid #f2f2f2',
                                                borderRadius: '30px'
                                            }} src="/oilproduct-details/COOKING OIL/LOGO/Logo  Diyah.png" alt="ogfc-img" />
                                            <h1 style={{ fontFamily: t('fontFamilyone') }}>{t("COOKING OIL")}</h1>

                                            <h6 style={{ fontFamily: t('fontFamilyone') }}>{t("Ingredients :")}</h6>

                                            <h6 style={{ fontFamily: t('fontFamilyone') }}>{t("D.F Olein, Refined Sunflower Oil,Antioxidant (E319),")} <br></br>{t("Vitamin A & D")}</h6>


                                            <div class="productfree">
                                                <h2 className="rise" style={{ fontFamily: t('fontFamilytwo') }}>{t("FREE FROM TRANS FAT")}</h2>
                                            </div>
                                            <img className="detail-view" src="/oilproduct-details/COOKING OIL/TABLE/Cooking Table.jpg" alt="Slide 1" />
                                        </div>
                                        <div style={{ textAlign: "center" }} className="tab-main col-md-6 ">

                                            <div className="availabletin">


                                                <div className="image-container">
                                                    <img src={selectedImagediyah} alt="ogfc-img" />
                                                    <div className="overlay"></div>
                                                    <div className="button-container">



                                                        <span style={{ fontWeight: 600 }}>{t("Available SKU’s")}</span>:
                                                        <button onMouseOver={() => handleImageChangediyah('/oilproduct-details/COOKING OIL/PRODUCT IMAGES/DIYAH/Diyah Cooking Oil 1p8L.jpg')}>1.8L</button>
                                                        <button onMouseOver={() => handleImageChangediyah('/oilproduct-details/COOKING OIL/PRODUCT IMAGES/DIYAH/Diyah Cooking Oil 5L.jpg')}>5L</button>

                                                    </div>
                                                </div>

                                            </div>



                                        </div>
                                    </div>
                                </div>
                            </Tab.Pane>



                            <Tab.Pane eventKey="fourth">
                                <div className="container  py-5">
                                    <div className="row">
                                        <div className="col-md-6 product-details product-details-heading">
                                            <img style={{
                                                border: '1px solid #f2f2f2',
                                                borderRadius: '30px'
                                            }} src="/oilproduct-details/COOKING OIL/LOGO/Logo  No 1.png" alt="ogfc-img" />
                                            <h1 style={{ fontFamily: t('fontFamilyone') }}>{t("COOKING OIL")}</h1>

                                            <h6 style={{ fontFamily: t('fontFamilyone') }}>{t("Ingredients :")}</h6>

                                            <h6 style={{ fontFamily: t('fontFamilyone') }}>{t("D.F Olein, Refined Sunflower Oil,Antioxidant (E319),")} <br></br>{t("Vitamin A & D")}</h6>


                                            <div class="productfree">
                                                <h2 className="rise" style={{ fontFamily: t('fontFamilytwo') }}>{t("FREE FROM TRANS FAT")}</h2>
                                            </div>
                                            <img className="detail-view" src="/oilproduct-details/COOKING OIL/TABLE/Cooking Table.jpg" alt="Slide 1" />
                                        </div>



                                        <div style={{ textAlign: "center" }} className="tab-main col-md-6 ">

                                            <div className="availabletin">


                                                <div className="image-container">
                                                    <img src={selectedImageno1} alt="ogfc-img" />
                                                    <div className="overlay"></div>
                                                    <div className="button-container">



                                                        <span style={{ fontWeight: 600 }}>{t("Available SKU’s")}</span>:
                                                        <button onMouseOver={() => handleImageChangeno1('/oilproduct-details/COOKING OIL/PRODUCT IMAGES/NO 1/No 1 Cooking Oil 750ml.jpg')}>750ML</button>
                                                        <button onMouseOver={() => handleImageChangeno1('/oilproduct-details/COOKING OIL/PRODUCT IMAGES/NO 1/No 1 Cooking Oil 1p5L.jpg')}>1.5L</button>
                                                        <button onMouseOver={() => handleImageChangeno1('/oilproduct-details/COOKING OIL/PRODUCT IMAGES/NO 1/No 1 Cooking Oil 1p8L.jpg')}>1.8L</button>
                                                    </div>
                                                </div>

                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </Tab.Pane>



                            <Tab.Pane eventKey="five">

                                <div className="container  py-5">
                                    <div className="row">
                                        <div className="col-md-6 product-details product-details-heading">
                                            <img style={{
                                                border: '1px solid #f2f2f2',
                                                borderRadius: '30px'
                                            }} src="/oilproduct-details/COOKING OIL/LOGO/Logo  Salalah.png" alt="ogfc-img" />
                                            <h1 style={{ fontFamily: t('fontFamilyone') }}>{t("COOKING OIL")}</h1>

                                            <h6 style={{ fontFamily: t('fontFamilyone') }}>{t("Ingredients :")}</h6>

                                            <h6 style={{ fontFamily: t('fontFamilyone') }}>{t("D.F Olein, Refined Sunflower Oil,Antioxidant (E319),")} <br></br>{t("Vitamin A & D")}</h6>


                                            <div class="productfree">
                                                <h2 className="rise" style={{ fontFamily: t('fontFamilytwo') }}>{t("FREE FROM TRANS FAT")}</h2>
                                            </div>
                                            <img className="detail-view" src="/oilproduct-details/COOKING OIL/TABLE/Cooking Table.jpg" alt="Slide 1" />
                                        </div>
                                        <div style={{ textAlign: "center" }} className="tab-main col-md-6 ">

                                            <div className="availabletin">


                                                <div className="image-container">
                                                    <img src={selectedImagesalalah} alt="ogfc-img" />
                                                    <div className="overlay"></div>
                                                    <div className="button-container">



                                                        <span style={{ fontWeight: 600 }}>{t("Available SKU’s")}</span>:
                                                        <button onMouseOver={() => handleImageChangesalalah('/oilproduct-details/COOKING OIL/PRODUCT IMAGES/SALALAH/Salalah Cooking Oil 1p8L.jpg')}>1.8L</button>
                                                        <button onMouseOver={() => handleImageChangesalalah('/oilproduct-details/COOKING OIL/PRODUCT IMAGES/SALALAH/Salalah Cooking Oil 5L.jpg')}>5L</button>
                                                    </div>
                                                </div>

                                            </div>





                                        </div>
                                    </div>
                                </div>
                            </Tab.Pane>



                            <Tab.Pane eventKey="six">

                                <div className="container  py-5">
                                    <div className="row">
                                        <div className="col-md-6 product-details product-details-heading">
                                            <img style={{
                                                border: '1px solid #f2f2f2',
                                                borderRadius: '30px'
                                            }} src="/oilproduct-details/COOKING OIL/LOGO/Logo  Zeenath.png" alt="ogfc-img" />
                                            <h1 style={{ fontFamily: t('fontFamilyone') }}>{t("COOKING OIL")}</h1>

                                            <h6 style={{ fontFamily: t('fontFamilyone') }}>{t("Ingredients :")}</h6>

                                            <h6 style={{ fontFamily: t('fontFamilyone') }}>{t("D.F Olein, Refined Sunflower Oil,Antioxidant (E319),")} <br></br>{t("Vitamin A & D")}</h6>


                                            <div class="productfree">
                                                <h2 className="rise" style={{ fontFamily: t('fontFamilytwo') }}>{t("FREE FROM TRANS FAT")}</h2>
                                            </div>
                                            <img className="detail-view" src="/oilproduct-details/COOKING OIL/TABLE/Cooking Table.jpg" alt="ogfc-img" />
                                        </div>
                                        <div style={{ textAlign: "center" }} className="tab-main col-md-6 ">

                                            <div className="availabletin">


                                                <div className="image-container">
                                                    <img src={selectedImagecooking} a alt="ogfc-img" />
                                                    <div className="overlay"></div>
                                                    <div className="button-container">



                                                        <span style={{ fontWeight: 600 }}>{t("Available SKU’s")}</span>:

                                                        <button onMouseOver={() => handleImageChangecooking('/oilproduct-details/COOKING OIL/PRODUCT IMAGES/ZEENATH/Zeenath Cooking Oil 1p5L.jpg')}>1.5L</button>
                                                        <button onMouseOver={() => handleImageChangecooking('/oilproduct-details/COOKING OIL/PRODUCT IMAGES/ZEENATH/Zeenath Cooking Oil 1p8L.jpg')}>1.8L</button>

                                                    </div>
                                                </div>

                                            </div>




                                        </div>
                                    </div>
                                </div>





                            </Tab.Pane>
                            <Tab.Pane eventKey="seven">

                                <div className="container  py-5">
                                    <div className="row">
                                        <div className="col-md-6 product-details product-details-heading">
                                            <img style={{
                                                border: '1px solid #f2f2f2',
                                                borderRadius: '30px'
                                            }} src="/oilproduct-details/COOKING OIL/LOGO/Logo  Sultanah.png" alt="ogfc-img" />
                                            <h1 style={{ fontFamily: t('fontFamilyone') }}>{t("COOKING OIL")}</h1>

                                            <h6 style={{ fontFamily: t('fontFamilyone') }}>{t("Ingredients :")}</h6>

                                            <h6 style={{ fontFamily: t('fontFamilyone') }}>{t("D.F Olein, Refined Sunflower Oil,Antioxidant (E319),")} <br></br>{t("Vitamin A & D")}</h6>


                                            <div class="productfree">
                                                <h2 className="rise" style={{ fontFamily: t('fontFamilytwo') }}>{t("FREE FROM TRANS FAT")}</h2>
                                            </div>
                                            <img className="detail-view" src="/oilproduct-details/COOKING OIL/TABLE/Cooking Table.jpg" alt="ogfc-img" />
                                        </div>
                                        <div style={{ textAlign: "center" }} className="tab-main col-md-6 ">
                                            <div className="availabletin">


                                                <div className="image-container">
                                                    <img src={selectedImagesultanak} alt="ogfc-img" />
                                                    <div className="overlay"></div>
                                                    <div className="button-container">



                                                        <span style={{ fontWeight: 600 }}>{t("Available SKU’s")}</span>:
                                                        <button onMouseOver={() => handleImageChangesultanak('/oilproduct-details/COOKING OIL/PRODUCT IMAGES/SULTANAH/Sultanah Cooking Oil 750ml.jpg')}>750ML</button>
                                                        <button onMouseOver={() => handleImageChangesultanak('/oilproduct-details/COOKING OIL/PRODUCT IMAGES/SULTANAH/Sultanah Cooking Oil 1p5L.jpg')}>1.5L</button>
                                                        <button onMouseOver={() => handleImageChangesultanak('/oilproduct-details/COOKING OIL/PRODUCT IMAGES/SULTANAH/Sultanah Cooking Oil 1p7L.jpg')}>1.7L</button>
                                                        <button onMouseOver={() => handleImageChangesultanak('/oilproduct-details/COOKING OIL/PRODUCT IMAGES/SULTANAH/Sultanah Cooking Oil 1p8L.jpg')}>1.8L</button>

                                                        <button onMouseOver={() => handleImageChangesultanak('/oilproduct-details/COOKING OIL/PRODUCT IMAGES/SULTANAH/Sultanah Cooking Oil 4L.jpg')}>4L</button>


                                                    </div>
                                                </div>

                                            </div>





                                        </div>
                                    </div>
                                </div>

                            </Tab.Pane>




                            <Tab.Pane eventKey="eight">

                                <div className="container  py-5">
                                    <div className="row">
                                        <div className="col-md-6 product-details product-details-heading">
                                            <img style={{
                                                border: '1px solid #f2f2f2',
                                                borderRadius: '30px'
                                            }} src="/oilproduct-details/COOKING OIL/LOGO/Logo Al-Barakat.png" alt="ogfc-img" />
                                            <h1 style={{ fontFamily: t('fontFamilyone') }}>{t("COOKING OIL")}</h1>

                                            <h6 style={{ fontFamily: t('fontFamilyone') }}>{t("Ingredients :")}</h6>

                                            <h6 style={{ fontFamily: t('fontFamilyone') }}>{t("D.F Olein, Refined Sunflower Oil,Antioxidant (E319),")} <br></br>{t("Vitamin A & D")}</h6>


                                            <div class="productfree">
                                                <h2 className="rise" style={{ fontFamily: t('fontFamilytwo') }}>{t("FREE FROM TRANS FAT")}</h2>
                                            </div>
                                            <img className="detail-view" src="/oilproduct-details/COOKING OIL/TABLE/Cooking Table.jpg" alt="Slide 1" />
                                        </div>
                                        <div style={{ textAlign: "center" }} className="tab-main col-md-6 ">

                                            <div className="availabletin">


                                                <div className="image-container">
                                                    <img src={selectedImagealbarakat} alt="ogfc-img" />
                                                    <div className="overlay"></div>
                                                    <div className="button-container">



                                                        <span style={{ fontWeight: 600 }}>{t("Available SKU’s")}</span>:



                                                        <button onMouseOver={() => handleImageChangealbarakat('/oilproduct-details/COOKING OIL/PRODUCT IMAGES/AL BARAKAT/Al Barakat Cooking Oil 750ml.jpg')}>750ML</button>
                                                        <button onMouseOver={() => handleImageChangealbarakat('/oilproduct-details/COOKING OIL/PRODUCT IMAGES/AL BARAKAT/Al Barakat Cooking Oil 1p5L.jpg')}>1.5L</button>
                                                        <button onMouseOver={() => handleImageChangealbarakat('/oilproduct-details/COOKING OIL/PRODUCT IMAGES/AL BARAKAT/Al Barakat Cooking Oil 1p8L.jpg')}>1.8L</button>
                                                        <button onMouseOver={() => handleImageChangealbarakat('/oilproduct-details/COOKING OIL/PRODUCT IMAGES/AL BARAKAT/Al Barakat Cooking Oil 4L.jpg')}>4L</button>

                                                        <button onMouseOver={() => handleImageChangealbarakat('/oilproduct-details/COOKING OIL/PRODUCT IMAGES/AL BARAKAT/Al Barakat Cooking Oil 5L.jpg')}>5L</button>


                                                    </div>
                                                </div>

                                            </div>




                                        </div>
                                    </div>
                                </div>

                            </Tab.Pane>
                            <Tab.Pane eventKey="nine">

                                <div className="container  py-5">
                                    <div className="row">
                                        <div className="col-md-6 product-details product-details-heading">
                                            <img style={{
                                                border: '1px solid #f2f2f2',
                                                borderRadius: '30px'
                                            }} src="/oilproduct-details/COOKING OIL/LOGO/Logo  Dana.png" alt="ogfc-img" />
                                            <h1 style={{ fontFamily: t('fontFamilyone') }}>{t("COOKING OIL")}</h1>

                                            <h6 style={{ fontFamily: t('fontFamilyone') }}>{t("Ingredients :")}</h6>

                                            <h6 style={{ fontFamily: t('fontFamilyone') }}>{t("D.F Olein, Refined Sunflower Oil,Antioxidant (E319),")} <br></br>{t("Vitamin A & D")}</h6>


                                            <div class="productfree">
                                                <h2 className="rise" style={{ fontFamily: t('fontFamilytwo') }}>{t("FREE FROM TRANS FAT")}</h2>
                                            </div>
                                            <img className="detail-view" src="/oilproduct-details/COOKING OIL/TABLE/Cooking Table.jpg" alt="ogfc-img" />
                                        </div>
                                        <div style={{ textAlign: "center" }} className="tab-main col-md-6 ">

                                            <div className="availabletin">


                                                <div className="image-container">
                                                    <img src={selectedImagedana} alt="ogfc-img" />
                                                    <div className="overlay"></div>
                                                    <div className="button-container">



                                                        <span style={{ fontWeight: 600 }}>{t("Available SKU’s")}</span>:
                                                        <button onMouseOver={() => handleImageChangedana('/oilproduct-details/COOKING OIL/PRODUCT IMAGES/DANA/Dana Cooking Oil 750ml.jpg')} >750ML</button>
                                                        <button onMouseOver={() => handleImageChangedana('/oilproduct-details/COOKING OIL/PRODUCT IMAGES/DANA/Dana Cooking Oil 1p5L.jpg')}>1.5L</button>
                                                        <button onMouseOver={() => handleImageChangedana('/oilproduct-details/COOKING OIL/PRODUCT IMAGES/DANA/Dana Cooking Oil 1p8L.jpg')}>1.8L</button>


                                                    </div>
                                                </div>

                                            </div>






                                        </div>
                                    </div>
                                </div>

                            </Tab.Pane>
                            <Tab.Pane eventKey="ten">
                                <div className="container  py-5">
                                    <div className="row">
                                        <div className="col-md-6 product-details product-details-heading">
                                            <img style={{
                                                border: '1px solid #f2f2f2',
                                                borderRadius: '30px'
                                            }} src="/oilproduct-details/COOKING OIL/LOGO/Logo  Luliya.png" alt="ogfc-img" />
                                            <h1 style={{ fontFamily: t('fontFamilyone') }}>{t("COOKING OIL")}</h1>

                                            <h6 style={{ fontFamily: t('fontFamilyone') }}>{t("Ingredients :")}</h6>

                                            <h6 style={{ fontFamily: t('fontFamilyone') }}>{t("D.F Olein, Refined Sunflower Oil,Antioxidant (E319),")} <br></br>{t("Vitamin A & D")}</h6>


                                            <div class="productfree">
                                                <h2 className="rise" style={{ fontFamily: t('fontFamilytwo') }}>{t("FREE FROM TRANS FAT")}</h2>
                                            </div>
                                            <img className="detail-view" src="/oilproduct-details/COOKING OIL/TABLE/Cooking Table.jpg" alt="ogfc-img" />
                                        </div>
                                        <div style={{ textAlign: "center" }} className="tab-main col-md-6 ">

                                            <div className="availabletin">


                                                <div className="image-container">
                                                    <img src={selectedImageluliya} alt="ogfc-img" />
                                                    <div className="overlay"></div>
                                                    <div className="button-container">



                                                        <span style={{ fontWeight: 600 }}>{t("Available SKU’s")}</span>:
                                                        <button onMouseOver={() => handleImageChangeluliya('/oilproduct-details/COOKING OIL/PRODUCT IMAGES/LULIYA/Luliya Cooking Oil 1p5L.jpg')}>1.5L</button>



                                                    </div>
                                                </div>

                                            </div>



                                        </div>
                                    </div>
                                </div>
                            </Tab.Pane>

                        </Tab.Content>
                    </Col>
                </Row>

                <Row>
                    <Col sm={12}>

                        <Nav style={{ padding: '0px' }} variant="pills" className="d-flex justify-content-center">
                            <Nav.Item style={{ padding: '10px 5px ' }}>
                                <Nav.Link eventKey="first" style={{ background: "#fff", padding: "0px" }}>

                                    <img style={{
                                        width: '100%',
                                        border: '1px solid #f2f2f2',
                                        borderRadius: '30px'
                                    }} src="/oilproduct-details/COOKING OIL/LOGO/Sunlife Logo.png" alt="ogfc-img" />
                                </Nav.Link>
                            </Nav.Item>


                            <Nav.Item style={{ padding: '10px 5px' }}>
                                <Nav.Link eventKey="second" style={{ background: "#fff", padding: "0px" }}>
                                    <img style={{
                                        width: '100%',
                                        border: '1px solid #f2f2f2',
                                        borderRadius: '30px'
                                    }} src="/oilproduct-details/COOKING OIL/LOGO/Sahi Logo.png" alt="ogfc-img" />
                                </Nav.Link>
                            </Nav.Item>


                            <Nav.Item style={{ padding: '10px 5px' }}>
                                <Nav.Link eventKey="third" style={{ background: "#fff", padding: "0px" }}>
                                    <img style={{
                                        width: '100%',
                                        border: '1px solid #f2f2f2',
                                        borderRadius: '30px'
                                    }} src="/oilproduct-details/COOKING OIL/LOGO/Logo  Diyah.png" alt="ogfc-img" />
                                </Nav.Link>
                            </Nav.Item>

                            <Nav.Item style={{ padding: '10px 5px' }}>
                                <Nav.Link eventKey="fourth" style={{ background: "#fff", padding: "0px" }}>

                                    <img style={{
                                        width: '100%',
                                        border: '1px solid #f2f2f2',
                                        borderRadius: '30px'
                                    }} src="/oilproduct-details/COOKING OIL/LOGO/Logo  No 1.png" alt="ogfc-img" />
                                </Nav.Link>
                            </Nav.Item>

                            <Nav.Item style={{ padding: '10px 5px' }}>
                                <Nav.Link eventKey="five" style={{ background: "#fff", padding: "0px" }}>
                                    <img src="/oilproduct-details/COOKING OIL/LOGO/Logo  Salalah.png" alt="ogfc-img" />

                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item style={{ padding: '10px 5px' }}>
                                <Nav.Link eventKey="six" style={{ background: "#fff", padding: "0px" }}>
                                    <img style={{
                                        width: '100%',
                                        border: '1px solid #f2f2f2',
                                        borderRadius: '30px'
                                    }} src="/oilproduct-details/COOKING OIL/LOGO/Logo  Zeenath.png" alt="ogfc-img" />

                                </Nav.Link>
                            </Nav.Item>

                            <Nav.Item style={{ padding: '10px 5px' }}>
                                <Nav.Link eventKey="seven" style={{ background: "#fff", padding: "0px" }}>

                                    <img src="/oilproduct-details/COOKING OIL/LOGO/Logo  Sultanah.png" alt="ogfc-img" />
                                </Nav.Link>
                            </Nav.Item>











                            <Nav.Item style={{ padding: '10px 5px' }}>
                                <Nav.Link eventKey="eight" style={{ background: "#fff", padding: "0px" }}>

                                    <img src="/oilproduct-details/COOKING OIL/LOGO/Logo Al-Barakat.png" alt="ogfc-img" />
                                </Nav.Link>
                            </Nav.Item>






                            <Nav.Item style={{ padding: '10px 5px' }}>
                                <Nav.Link eventKey="nine" style={{ background: "#fff", padding: "0px" }}>

                                    <img style={{
                                        width: '100%',
                                        border: '1px solid #f2f2f2',
                                        borderRadius: '30px'
                                    }} src="/oilproduct-details/COOKING OIL/LOGO/Logo  Dana.png" alt="ogfc-img" />
                                </Nav.Link>
                            </Nav.Item>







                            <Nav.Item style={{ padding: '10px 5px' }}>
                                <Nav.Link eventKey="ten" style={{ background: "#fff", padding: "0px" }}>

                                    <img src="/oilproduct-details/COOKING OIL/LOGO/Logo  Luliya.png" alt="ogfc-img" />
                                </Nav.Link>
                            </Nav.Item>


                        </Nav>

                    </Col>
                </Row>
            </Tab.Container >

            < Aboutenquiry />
            <Footer />





        </div>
    )
}
<>
    <span style={{ fontWeight: '600', marginRight: '10px', }}>Available SKU’s </span>
</>




export default Cookingoil