import React from 'react';
import { useState } from 'react';
import { Carousel } from "react-bootstrap";
import Footer from '../Includes/Footer';
import Aboutenquiry from '../About/Aboutenquiry';
import Galleryvideo from './Galleryvideo';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';

function Archeive() {
  const { t, i18n } = useTranslation();


  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalIsOpen1, setModalIsOpen1] = useState(false);
  const [modalIsOpen2, setModalIsOpen2] = useState(false);
  const [modalIsOpen3, setModalIsOpen3] = useState(false);
  const [modalIsOpen_4, setModalIsOpen_4] = useState(false);
  const [modalIsOpen_5, setModalIsOpen_5] = useState(false);
  const [modalIsOpen_6, setModalIsOpen_6] = useState(false);
  const [modalIsOpen_7, setModalIsOpen_7] = useState(false);
  const [modalIsOpen_8, setModalIsOpen_8] = useState(false);
  const [modalIsOpen_9, setModalIsOpen_9] = useState(false);
  const [modalIsOpen_10, setModalIsOpen_10] = useState(false);
  const [modalIsOpen_11, setModalIsOpen_11] = useState(false);
  const [modalIsOpen_12, setModalIsOpen_12] = useState(false);
  const [modalIsOpen_13, setModalIsOpen_13] = useState(false);
  const [modalIsOpen_14, setModalIsOpen_14] = useState(false);


  const toggleModal = () => {

    setModalIsOpen3(false);
    setModalIsOpen2(false);
    setModalIsOpen1(false);
    setModalIsOpen(!modalIsOpen);
  };
  const toggleModal1 = () => {
    setModalIsOpen3(false);
    setModalIsOpen2(false);
    setModalIsOpen(false);
    setModalIsOpen1(!modalIsOpen1);
  };
  const toggleModal2 = () => {
    setModalIsOpen3(false);
    setModalIsOpen(false);
    setModalIsOpen1(false);
    setModalIsOpen2(!modalIsOpen2);
  };
  const toggleModal3 = () => {
    setModalIsOpen(false);
    setModalIsOpen2(false);
    setModalIsOpen1(false);
    setModalIsOpen3(!modalIsOpen3);
  };

  // ------------

  const toggleModal_4 = () => {
    setModalIsOpen(false);
    setModalIsOpen2(false);
    setModalIsOpen1(false);
    setModalIsOpen3(false);
    setModalIsOpen_4(!modalIsOpen_4)
    setModalIsOpen_5(false)
    setModalIsOpen_6(false)
    setModalIsOpen_7(false)
    setModalIsOpen_8(false)
    setModalIsOpen_9(false)
    setModalIsOpen_10(false)
    setModalIsOpen_11(false)
    setModalIsOpen_12(false)
    setModalIsOpen_13(false)
    setModalIsOpen_14(false)

  };
  const toggleModal_5 = () => {
    setModalIsOpen(false);
    setModalIsOpen2(false);
    setModalIsOpen1(false);
    setModalIsOpen3(false);
    setModalIsOpen_4(false)
    setModalIsOpen_5(!modalIsOpen_5)
    setModalIsOpen_6(false)
    setModalIsOpen_7(false)
    setModalIsOpen_8(false)
    setModalIsOpen_9(false)
    setModalIsOpen_10(false)
    setModalIsOpen_11(false)
    setModalIsOpen_12(false)
    setModalIsOpen_13(false) 
    setModalIsOpen_14(false)

   };

  const toggleModal_6 = () => {
    setModalIsOpen(false);
    setModalIsOpen2(false);
    setModalIsOpen1(false);
    setModalIsOpen3(false);
    setModalIsOpen_4(false)
    setModalIsOpen_5(false)
    setModalIsOpen_6(!modalIsOpen_6)
    setModalIsOpen_7(false)
    setModalIsOpen_8(false)
    setModalIsOpen_9(false)
    setModalIsOpen_10(false)
    setModalIsOpen_11(false)
    setModalIsOpen_12(false)
    setModalIsOpen_13(false)
    setModalIsOpen_14(false)
 
    };
  const toggleModal_7 = () => {
    setModalIsOpen(false);
    setModalIsOpen2(false);
    setModalIsOpen1(false);
    setModalIsOpen3(false);
    setModalIsOpen_4(false)
    setModalIsOpen_5(false)
    setModalIsOpen_6(false)
    setModalIsOpen_7(!modalIsOpen_7)
    setModalIsOpen_8(false)
    setModalIsOpen_9(false)
    setModalIsOpen_10(false)
    setModalIsOpen_11(false)
    setModalIsOpen_12(false)
    setModalIsOpen_13(false)
    setModalIsOpen_14(false)

     };
    
  const toggleModal_8 = () => {
    setModalIsOpen(false);
    setModalIsOpen2(false);
    setModalIsOpen1(false);
    setModalIsOpen3(false);
    setModalIsOpen_4(false);
    setModalIsOpen_5(false);
    setModalIsOpen_6(false);
    setModalIsOpen_7(false);
    setModalIsOpen_8(!modalIsOpen_8);
    setModalIsOpen_9(false);
    setModalIsOpen_10(false);
    setModalIsOpen_11(false);
    setModalIsOpen_12(false);
    setModalIsOpen_13(false);
    setModalIsOpen_14(false)

    };
  const toggleModal_9 = () => {
    setModalIsOpen(false);
    setModalIsOpen2(false);
    setModalIsOpen1(false);
    setModalIsOpen3(false);
    setModalIsOpen_4(false);
    setModalIsOpen_5(false);
    setModalIsOpen_6(false);
    setModalIsOpen_7(false);
    setModalIsOpen_8(false);
    setModalIsOpen_9(!modalIsOpen_9);
    setModalIsOpen_10(false);
    setModalIsOpen_11(false);
    setModalIsOpen_12(false);
    setModalIsOpen_13(false);
    setModalIsOpen_14(false)

    };
  const toggleModal_10 = () => {
    setModalIsOpen(false);
    setModalIsOpen2(false);
    setModalIsOpen1(false);
    setModalIsOpen3(false);
    setModalIsOpen_4(false);
    setModalIsOpen_5(false);
    setModalIsOpen_6(false);
    setModalIsOpen_7(false);
    setModalIsOpen_8(false);
    setModalIsOpen_9(false);
    setModalIsOpen_10(!modalIsOpen_10);
    setModalIsOpen_11(false);
    setModalIsOpen_12(false);
    setModalIsOpen_13(false); 
    setModalIsOpen_14(false)

   };
  const toggleModal_11 = () => {
    setModalIsOpen(false);
    setModalIsOpen2(false);
    setModalIsOpen1(false);
    setModalIsOpen3(false);
    setModalIsOpen_4(false);
    setModalIsOpen_5(false);
    setModalIsOpen_6(false);
    setModalIsOpen_7(false);
    setModalIsOpen_8(false);
    setModalIsOpen_9(false);
    setModalIsOpen_10(false);
    setModalIsOpen_11(!modalIsOpen_11);
    setModalIsOpen_12(false);
    setModalIsOpen_13(false); 
    setModalIsOpen_14(false)

   };
  const toggleModal_12 = () => {
    setModalIsOpen(false);
    setModalIsOpen2(false);
    setModalIsOpen1(false);
    setModalIsOpen3(false);
    setModalIsOpen_4(false);
    setModalIsOpen_5(false);
    setModalIsOpen_6(false);
    setModalIsOpen_7(false);
    setModalIsOpen_8(false);
    setModalIsOpen_9(false);
    setModalIsOpen_10(false);
    setModalIsOpen_11(false);
    setModalIsOpen_12(!modalIsOpen_12);
    setModalIsOpen_13(false);
    setModalIsOpen_14(false)

    };
  const toggleModal_13 = () => {
    setModalIsOpen(false);
    setModalIsOpen2(false);
    setModalIsOpen1(false);
    setModalIsOpen3(false);
    setModalIsOpen_4(false);
    setModalIsOpen_5(false);
    setModalIsOpen_6(false);
    setModalIsOpen_7(false);
    setModalIsOpen_8(false);
    setModalIsOpen_9(false);
    setModalIsOpen_10(false);
    setModalIsOpen_11(false);
    setModalIsOpen_12(false);
    setModalIsOpen_13(!modalIsOpen_13);
    setModalIsOpen_14(false)

    };

    const toggleModal_14 = () => {
      setModalIsOpen(false);
      setModalIsOpen2(false);
      setModalIsOpen1(false);
      setModalIsOpen3(false);
      setModalIsOpen_4(false);
      setModalIsOpen_5(false);
      setModalIsOpen_6(false);
      setModalIsOpen_7(false);
      setModalIsOpen_8(false);
      setModalIsOpen_9(false);
      setModalIsOpen_10(false);
      setModalIsOpen_11(false);
      setModalIsOpen_12(false);
      setModalIsOpen_14(!modalIsOpen_14);
      setModalIsOpen_13(false)
  
      };
  return (
    <div >



      <div className="headertop" >
        <Carousel className="slider ">
          <Carousel.Item>
            {/* <img className="d-block w-100  " src="/aboutimages/compnay-new.jpg" alt="ogfc-img" /> */}
            <img className="d-block w-100" src="/gallery/promotionbanner.jpg" alt="ogfc-img" />

            <div className="carousel-gallery" >

              <h1 className='linedown' style={{ color: "#fff", fontFamily: t('fontFamilytwo') }} > {t("ARCHIVE")}</h1>

            </div>

          </Carousel.Item>
        </Carousel>
      </div>
      <div className='container'>
        <div className='row'>



        <div className='col-sm-12 col-md-6 col-lg-3 ps-3'>
            <div className='archive-box'>
              <img src='/images/news/OGFC Participate Saudi Food Show 2024.jpg' onClick={toggleModal_14}></img>
              <Modal
                isOpen={modalIsOpen_14}
                onRequestClose={toggleModal_14}
                contentLabel="Zoomed Image"
                className="modal"
                overlayClassName="overlay"
              >
                <img src='/images/news/OGFC Participate Saudi Food Show 2024.jpg' alt="Zoomed In" />
                <button className="close-button" onClick={toggleModal_14} style={{ fontFamily: t('fontFamilyone') }}>
                  {t("Close")}
                </button>
              </Modal>

              <h1 style={{ fontFamily: t('fontFamilyone') }}>
                {t("OGFC Participate Saudi Food Show 2024")}
              </h1>
            </div>
          </div>

        <div className='col-sm-12 col-md-6 col-lg-3 ps-3'>
            <div className='archive-box'>
              <img src='/gallery/ARCHIVE/new 1.jpg' onClick={toggleModal_4}></img>
              <Modal
                isOpen={modalIsOpen_4}
                onRequestClose={toggleModal_4}
                contentLabel="Zoomed Image"
                className="modal"
                overlayClassName="overlay"
              >
                <img src='/gallery/ARCHIVE/new 1.jpg' alt="Zoomed In" />
                <button className="close-button" onClick={toggleModal_4} style={{ fontFamily: t('fontFamilyone') }}>
                  {t("Close")}
                </button>
              </Modal>

              <h1 style={{ fontFamily: t('fontFamilyone') }}>
                {t("Gulfood Exhibition – Feb 2024")}
              </h1>
            </div>
          </div>

          <div className='col-sm-12 col-md-6 col-lg-3 ps-3'>
            <div className='archive-box'>
              <img src='/gallery/ARCHIVE/new 2.jpg' onClick={toggleModal_5}></img>
              <Modal
                isOpen={modalIsOpen_5}
                onRequestClose={toggleModal_5}
                contentLabel="Zoomed Image"
                className="modal"
                overlayClassName="overlay"
              >
                <img src='/gallery/ARCHIVE/new 2.jpg' alt="Zoomed In" />
                <button className="close-button" onClick={toggleModal_5} style={{ fontFamily: t('fontFamilyone') }}>
                  {t("Close")}
                </button>
              </Modal>

              <h1 style={{ fontFamily: t('fontFamilyone') }}>
                {t("Gulfood Exhibition – Feb 2024")}
              </h1>
            </div>
          </div>

          <div className='col-sm-12 col-md-6 col-lg-3 ps-3'>
            <div className='archive-box'>
              <img src='/gallery/ARCHIVE/new 3.jpg' onClick={toggleModal_6}></img>
              <Modal
                isOpen={modalIsOpen_6}
                onRequestClose={toggleModal_6}
                contentLabel="Zoomed Image"
                className="modal"
                overlayClassName="overlay"
              >
                <img src='/gallery/ARCHIVE/new 3.jpg' alt="Zoomed In" />
                <button className="close-button" onClick={toggleModal_6} style={{ fontFamily: t('fontFamilyone') }}>
                  {t("Close")}
                </button>
              </Modal>

              <h1 style={{ fontFamily: t('fontFamilyone') }}>
                {t("Gulfood Exhibition – Feb 2024")}
              </h1>
            </div>
          </div>

          <div className='col-sm-12 col-md-6 col-lg-3 ps-3'>
            <div className='archive-box'>
              <img src='/gallery/ARCHIVE/new 4.jpg' onClick={toggleModal_7}></img>
              <Modal
                isOpen={modalIsOpen_7}
                onRequestClose={toggleModal_7}
                contentLabel="Zoomed Image"
                className="modal"
                overlayClassName="overlay"
              >
                <img src='/gallery/ARCHIVE/new 4.jpg' alt="Zoomed In" />
                <button className="close-button" onClick={toggleModal_7} style={{ fontFamily: t('fontFamilyone') }}>
                  {t("Close")}
                </button>
              </Modal>

              <h1 style={{ fontFamily: t('fontFamilyone') }}>
                {t("Gulfood Exhibition – Feb 2024")}
              </h1>
            </div>
          </div>

          <div className='col-sm-12 col-md-6 col-lg-3 ps-3'>
            <div className='archive-box'>
              <img src='/gallery/ARCHIVE/new 5.jpg' onClick={toggleModal_8}></img>
              <Modal
                isOpen={modalIsOpen_8}
                onRequestClose={toggleModal_8}
                contentLabel="Zoomed Image"
                className="modal"
                overlayClassName="overlay"
              >
                <img src='/gallery/ARCHIVE/new 5.jpg' alt="Zoomed In" />
                <button className="close-button" onClick={toggleModal_8} style={{ fontFamily: t('fontFamilyone') }}>
                  {t("Close")}
                </button>
              </Modal>

              <h1 style={{ fontFamily: t('fontFamilyone') }}>
                {t("Gulfood Exhibition – Feb 2024")}
              </h1>
            </div>
          </div>

          <div className='col-sm-12 col-md-6 col-lg-3 ps-3'>
            <div className='archive-box'>
              <img src='/gallery/ARCHIVE/new 6.jpg' onClick={toggleModal_9}></img>
              <Modal
                isOpen={modalIsOpen_9}
                onRequestClose={toggleModal_9}
                contentLabel="Zoomed Image"
                className="modal"
                overlayClassName="overlay"
              >
                <img src='/gallery/ARCHIVE/new 6.jpg' alt="Zoomed In" />
                <button className="close-button" onClick={toggleModal_9} style={{ fontFamily: t('fontFamilyone') }}>
                  {t("Close")}
                </button>
              </Modal>

              <h1 style={{ fontFamily: t('fontFamilyone') }}>
                {t("Gulfood Exhibition – Feb 2024")}
              </h1>
            </div>
          </div>

          <div className='col-sm-12 col-md-6 col-lg-3 ps-3'>
            <div className='archive-box'>
              <img src='/gallery/ARCHIVE/new 7.jpg' onClick={toggleModal_10}></img>
              <Modal
                isOpen={modalIsOpen_10}
                onRequestClose={toggleModal_10}
                contentLabel="Zoomed Image"
                className="modal"
                overlayClassName="overlay"
              >
                <img src='/gallery/ARCHIVE/new 7.jpg' alt="Zoomed In" />
                <button className="close-button" onClick={toggleModal_10} style={{ fontFamily: t('fontFamilyone') }}>
                  {t("Close")}
                </button>
              </Modal>

              <h1 style={{ fontFamily: t('fontFamilyone') }}>
                {t("Gulfood Exhibition – Feb 2024")}
              </h1>
            </div>
          </div>

          <div className='col-sm-12 col-md-6 col-lg-3 ps-3'>
            <div className='archive-box'>
              <img src='/gallery/ARCHIVE/new 8.jpg' onClick={toggleModal_11}></img>
              <Modal
                isOpen={modalIsOpen_11}
                onRequestClose={toggleModal_11}
                contentLabel="Zoomed Image"
                className="modal"
                overlayClassName="overlay"
              >
                <img src='/gallery/ARCHIVE/new 8.jpg' alt="Zoomed In" />
                <button className="close-button" onClick={toggleModal_11} style={{ fontFamily: t('fontFamilyone') }}>
                  {t("Close")}
                </button>
              </Modal>

              <h1 style={{ fontFamily: t('fontFamilyone') }}>
                {t("Gulfood Exhibition – Feb 2024")}
              </h1>
            </div>
          </div>

          <div className='col-sm-12 col-md-6 col-lg-3 ps-3'>
            <div className='archive-box'>
              <img src='/gallery/ARCHIVE/new 9.jpg' onClick={toggleModal_12}></img>
              <Modal
                isOpen={modalIsOpen_12}
                onRequestClose={toggleModal_12}
                contentLabel="Zoomed Image"
                className="modal"
                overlayClassName="overlay"
              >
                <img src='/gallery/ARCHIVE/new 9.jpg' alt="Zoomed In" />
                <button className="close-button" onClick={toggleModal_12} style={{ fontFamily: t('fontFamilyone') }}>
                  {t("Close")}
                </button>
              </Modal>

              <h1 style={{ fontFamily: t('fontFamilyone') }}>
                {t("Gulfood Exhibition – Feb 2024")}
              </h1>
            </div>
          </div>

          <div className='col-sm-12 col-md-6 col-lg-3 ps-3'>
            <div className='archive-box'>
              <img src='/gallery/ARCHIVE/new 10.jpg' onClick={toggleModal_13}></img>
              <Modal
                isOpen={modalIsOpen_13}
                onRequestClose={toggleModal_13}
                contentLabel="Zoomed Image"
                className="modal"
                overlayClassName="overlay"
              >
                <img src='/gallery/ARCHIVE/new 10.jpg' alt="Zoomed In" />
                <button className="close-button" onClick={toggleModal_13} style={{ fontFamily: t('fontFamilyone') }}>
                  {t("Close")}
                </button>
              </Modal>

              <h1 style={{ fontFamily: t('fontFamilyone') }}>
                {t("Gulfood Exhibition – Feb 2024")}
              </h1>
            </div>
          </div>





          <div className='col-sm-12 col-md-6 col-lg-3 ps-3'>
            <div className='archive-box'>
              <img src='/gallery/ARCHIVE/Oman-Brand-Leadership-Awards-2023copy.jpg' onClick={toggleModal}>

              </img>

              <Modal
                isOpen={modalIsOpen}
                onRequestClose={toggleModal}
                contentLabel="Zoomed Image"
                className="modal"
                overlayClassName="overlay"
              >
                <img src='/gallery/ARCHIVE/Oman-Brand-Leadership-Awards-2023copy.jpg' alt="Zoomed In" />
                <button className="close-button" onClick={toggleModal} style={{ fontFamily: t('fontFamilyone') }}>
                  {t("Close")}
                </button>
              </Modal>

              <h1 style={{ fontFamily: t('fontFamilyone') }}>
                {t("Oman Brand Leadership Awards 2023")}
              </h1>
            </div>
          </div>

          <div className='col-sm-12 col-md-6 col-lg-3 ps-3'>
            <div className='archive-box'>
              <img src='/gallery/ARCHIVE/OGFC Participate Gulfood ExhibitionFeb 2023.jpg' onClick={toggleModal3}>

              </img>

              <Modal
                isOpen={modalIsOpen3}
                onRequestClose={toggleModal3}
                contentLabel="Zoomed Image"
                className="modal"
                overlayClassName="overlay"
              >
                <img src='/gallery/ARCHIVE/OGFC Participate Gulfood ExhibitionFeb 2023.jpg' alt="Zoomed In" />
                <button className="close-button" onClick={toggleModal3} style={{ fontFamily: t('fontFamilyone') }}>
                  {t("Close")}
                </button>
              </Modal>
              <h1 style={{ fontFamily: t('fontFamilyone') }}>
                {t("OGFC Participate Gulfood Exhibition – Feb 2023")}
              </h1>
            </div>
          </div>
          <div className='col-sm-12 col-md-6 col-lg-3 ps-3'>
            <div className='archive-box'>
              <img src='/gallery/ARCHIVE/Medical Camp by Badr Al Sama at OVODDec 2022.jpg' onClick={toggleModal2}>

              </img>

              <Modal
                isOpen={modalIsOpen2}
                onRequestClose={toggleModal2}
                contentLabel="Zoomed Image"
                className="modal"
                overlayClassName="overlay"
              >
                <img src='/gallery/ARCHIVE/Medical Camp by Badr Al Sama at OVODDec 2022.jpg' alt="Zoomed In" />
                <button className="close-button" onClick={toggleModal2} style={{ fontFamily: t('fontFamilyone') }}>
                  {t("Close")}
                </button>
              </Modal>

              <h1 style={{ fontFamily: t('fontFamilyone') }}>
                {t("Medical Camp by Badr Al Sama at OVOD - Dec 2022")}
              </h1>
            </div>
          </div>

          <div className='col-sm-12 col-md-6 col-lg-3 ps-3'>
            <div className='archive-box'>
              <img src='/gallery/ARCHIVE/Damascus International ExhibitionAug 2019.jpg' onClick={toggleModal1}></img>
              <Modal
                isOpen={modalIsOpen1}
                onRequestClose={toggleModal1}
                contentLabel="Zoomed Image"
                className="modal"
                overlayClassName="overlay"
              >
                <img src='/gallery/ARCHIVE/Damascus International ExhibitionAug 2019.jpg' alt="Zoomed In" />
                <button className="close-button" onClick={toggleModal1} style={{ fontFamily: t('fontFamilyone') }}>
                  {t("Close")}
                </button>
              </Modal>

              <h1 style={{ fontFamily: t('fontFamilyone') }}>
                {t("Damascus International Exhibition- Aug 2019")}
              </h1>
            </div>
          </div>

          {/* ----------- */}
         


        </div>
      </div>




      <Aboutenquiry />
      <Footer />



    </div >
  )
}

export default Archeive