import React from 'react'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

function Aboutenquiry() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate()
  return (
    <div className='enquiryform'>
      <img src='/aboutimages/enquirybutton.jpg' alt="ogfc-img"></img>

      <div className='enquiry-para'>
        <div className='container '>

          <div className='row '>
            <div className='col-md-8 col-lg-8' >
              <div>
                <p style={{ fontFamily: t('fontFamilytwo') }}>{t("OGFC ADDS LIFE TO LIFE !")}</p>
                <p style={{ fontFamily: t('fontFamilytwo') }}>{t("SUPERIOR QUALITY OIL FOR A HEALTHY LIFE !")}</p>
              </div>
            </div>
            <div className='col-md-4 col-lg-4 m-auto'>
              <div className='contact-enquirybtn'>
                <Link style={{ fontFamily: t('fontFamilyone') }} to="/contact-us" onClick={() => { navigate('/contact-us', { replace: true }); }}><Button variant="warning">{t("ENQUIRE NOW")}</Button></Link> </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}

export default Aboutenquiry
