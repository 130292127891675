import React from "react";
import { useState, useEffect } from "react";

import { Routes, Route } from 'react-router-dom'

import Topbar from "./Components/Includes/Topbar";

import Home from "./Components/Home/Home";

import Boardofdirectors from "./Components/Boardofdirector/Boardofdirectors";
import Importexport from "./Components/Importexport/Importexport";
import Ourproduct from "./Components/Products/Ourproduct";
import Contact from "./Components/Contact/Contact";
import Nonoilproducts from "./Components/Products/Nonoilproducts";
import Aboutogfc from "./Components/About/Aboutogfc";

import Qualitymanagement from "./Components/MissionQuality/Qualitymanagement";
import Privtatebrands from "./Components/Privatebrands/Privtatebrands";
import Certification from "./Components/Certification/Certification";
import Oilproducts from "./Components/Products/Oilproducts"
import Recipes from "./Components/Healthandrecipe/Recipes";
import Healthtips from "./Components/Healthandrecipe/Healthtips";

import Careersmain from "./Components/Careersmain/Careersmain";
import Palmoilproductdetails from "./Components/Oilproductdetails/Palmoilproductdetails";
import Sunfloweroilproductdetails from "./Components/Oilproductdetails/Sunfloweroilproductdetails";
import Cornoil from "./Components/Oilproductdetails/Cornoil";
import Cornblendoil from "./Components/Oilproductdetails/Cornblendoil";
import Fryingoil from "./Components/Oilproductdetails/Fryingoil";
import Dfolein from "./Components/Oilproductdetails/Dfolein";
import Ghee from "./Components/Oilproductdetails/Ghee";
import Shortening from "./Components/Oilproductdetails/Shortening";
import Cookingoil from "./Components/Oilproductdetails/Cookingoil";


import { useLocation } from 'react-router-dom';
import NotFound from "./Components/NotFound/NotFound";
import Galleryvideo from "./Components/Gallery/Galleryvideo";
import Gallerypage from "./Components/Gallery/Gallerypage";
import Gallerypromotion from "./Components/Gallery/Gallerypromotion";
import Medianews from "./Components/Gallery/Medianews";
import Archeive from "./Components/Gallery/Archeive";
import Newssub from "./Components/Home/Newssub";

function App() {
  const [loading, setLoading] = useState(false);
  const { pathname } = useLocation();

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);



  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  // return null;



  return (

    <div >
      {
        loading ? (<div className="loader-container" >
          {/* <div className="spinner"></div> */}
        </div>) : (
          <div className="App">

            {/* <ScrollToTop /> */}
            <Topbar />

            <Routes>
              <Route exact path='/' element={<Home />} ></Route>
            </Routes>
            <Routes>
              <Route path='/about-us' element={<Aboutogfc />}></Route>
            </Routes>
            <Routes>
              <Route path='/board-of-directors' element={<Boardofdirectors />}></Route>
            </Routes>
            <Routes>
              <Route path='/importexport' element={<Importexport />}></Route >
            </Routes>
            <Routes>
              <Route path='/ourproduct' element={<Ourproduct />}></Route >
            </Routes>
            <Routes>
              <Route path='/contact-us' element={<Contact />}></Route >
            </Routes>
            <Routes>
              <Route path='/oil-products' element={<Oilproducts />}></Route >
            </Routes>
            <Routes>
              <Route path='/non-oilproducts' element={<Nonoilproducts />}></Route >
            </Routes>
            <Routes>
              <Route path='/mission-quality' element={<Qualitymanagement />}></Route >
            </Routes>
            <Routes>
              <Route path='/private-brands' element={<Privtatebrands />}></Route >
            </Routes>
            <Routes>
              <Route path='/quality-certification' element={<Certification />}></Route >
            </Routes>
            <Routes>
              <Route path='/recipe' element={<Recipes />}></Route >
            </Routes>
            <Routes>
              <Route path='/health-tips' element={<Healthtips />}></Route >
            </Routes>
            <Routes>
              <Route path='/careers' element={<Careersmain />}></Route >
            </Routes>

            <Routes>
              <Route path='/palmolein-details' element={<Palmoilproductdetails />}></Route >
            </Routes>
            <Routes>
              <Route path='/sunfloweroil-details' element={<Sunfloweroilproductdetails />}></Route >
            </Routes>
            <Routes>
              <Route path='/cornoil-details' element={<Cornoil />}></Route >
            </Routes>
            <Routes>
              <Route path='/cornblend-oil' element={<Cornblendoil />}></Route >
            </Routes>
            <Routes>
              <Route path='/frying-oil' element={<Fryingoil />}></Route >
            </Routes>
            <Routes>
              <Route path='/cooking-oil' element={<Cookingoil />}></Route >
            </Routes>
            <Routes>
              <Route path='/df-olein' element={<Dfolein />}></Route >
            </Routes>
            <Routes>
              <Route path='/ghee' element={<Ghee />}></Route >
            </Routes>
            <Routes>
              <Route path='/shortening' element={<Shortening />}></Route >
            </Routes>
            <Routes>
              <Route path='/gallery' element={<Gallerypage />}></Route >
            </Routes>
            <Routes>
              <Route path='/gallery-promotion' element={<Gallerypromotion />}></Route >
            </Routes>
            <Routes>
              <Route path='/media-news' element={<Medianews />}></Route >
            </Routes>
            <Routes>
              <Route path='/archive' element={<Archeive />}></Route >
            </Routes>
            <Routes>
              <Route path='/news' element={<Newssub />}></Route >
            </Routes>
            <Routes>
              <Route component={<NotFound />}></Route>
            </Routes>
          
            {/* <Routes>
        <Route path='/cooking' element={<Cookingoil/>}></Route >
      </Routes> */}
          </div>

        )

      }
    </div>)
}

export default App;
