import React from 'react'
import { Player, ControlBar, PosterImage } from 'video-react';
import { useState, useEffect } from 'react';
import axios from 'axios';


import { useTranslation } from 'react-i18next';
function Galleryvideo({ props }) {
    const { t, i18n } = useTranslation();

    const [data, setData] = useState([]);

    useEffect(() => {
        axios
            .get('https://abhi9744684275.pythonanywhere.com/news/')
            .then((response) => {
                setData(response.data);
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);
    console.log('data', data);
    return (
        <div className='container py-5'>

           

            <Player controls={true}
                // poster="/gallery/poster.png"
                src="/gallery/Kabayan Hypermarket Promo - Sunlife Cooking Oil.mp4" alt="ogfc-img">

                <ControlBar autoHide={false} className="my-class" />
            </Player>
            <div className='video-head pb-5'>
                <h1 style={{ fontFamily: t('fontFamilyone') }}>{t("Kabayan Hypermarket Promo - Sunlife Cooking Oil")}</h1>
            </div>

            <div className="row pb-5">

            
                <div className="col-md-6 pe-3">
                    <div className=" d-flex">
                        <Player controls={true}

                            src="gallery/Nestouae.mp4" alt="ogfc-img">

                            <ControlBar autoHide={false} className="my-class" />
                        </Player>

                    </div>
                    <div className='video-head'>
                        <h1 style={{ fontFamily: t('fontFamilyone') }}>{t("Nesto UAE Promo -  Sunlife Cooking Oil")}</h1>
                    </div>
                </div>
                <div className="col-md-6 ps-3">
                    <div className=" d-flex" >
                        <Player controls={true}

                            src="/gallery/Nesto Salalah.mp4" alt="ogfc-img">

                            <ControlBar autoHide={false} className="my-class" />
                        </Player>

                    </div>

                    <div className='video-head'>
                        <h1 style={{ fontFamily: t('fontFamilyone') }}>{t("Nesto Salalah Promo -  Sundrops Sunflower Oil")}</h1>
                    </div>
                </div>

            </div >

            <Player controls={true}
                poster="/gallery/poster.png"
                src="/gallery/AD Low Res MP4.mp4 " alt="ogfc-img">

                <ControlBar autoHide={false} className="my-class" />
            </Player>
            <div className='video-head pb-5'>
                <h1 style={{ fontFamily: t('fontFamilyone') }}>{t("Sunlife Cooking Oil")}</h1>
            </div>
            <div className="row pb-5">
                <div className="col-md-6 pe-3">
                    <div className=" d-flex">
                        <Player controls={true}

                            src="/gallery/Aflah Cooking Oil Ad.mp4" alt="ogfc-img">

                            <ControlBar autoHide={false} className="my-class" />
                        </Player>

                    </div>
                    <div className='video-head'>
                        <h1 style={{ fontFamily: t('fontFamilyone') }}>{t("Aflah Cooking Oil -TVC")}</h1>
                    </div>
                </div>
 
              

                <div className="col-md-6 offset-md-6 " >
                    <div className=" d-flex">
                        

                    <img src="/gallery/PROMOTION TVC/gallery-promotion.jpeg" className='w-100' ></img>

                           
                    </div>

                    <div className='video-head'>
                        <h1 style={{ fontFamily: t('fontFamilyone') }}>{t("TAZA_NATIONAL DAY PROMOTION @ JEDDAH")}</h1>
                    </div>
                </div>
            </div >

              <Player controls={true}
                // poster="/gallery/poster.png"
                src="/gallery/SUNLIFE COOKING OIL PROMOTION - CITY FLOWER, KSA(1).mp4 " alt="ogfc-img">

                <ControlBar autoHide={false} className="my-class" />
            </Player>
            <div className='video-head pb-5'>
                <h1 style={{ fontFamily: t('fontFamilyone') }}>{t("SUNLIFE COOKING OIL PROMOTION - CITY FLOWER, KSA")}</h1>
            </div>































            <div className="row justify-content-center">
                <div className="col-6 col-sm-6 col-md-2" style={{ width: '240px' }}>
                    <div className='video-bottom '>
                        <div className=" d-flex">
                            <img src="/gallery/PROMOTION TVC/DISPLAY OF SAHI SUNFLOWER OIL 1.5L - CARREFOUR SALALAH GARDEN MALL.jpg" ></img>

                        </div>
                        <div className='video-head'>
                            <h6 style={{ fontFamily: t('fontFamilyone') }}>{t("DISPLAY OF SAHI SUNFLOWER OIL 1.5L - CARREFOUR SALALAH GARDEN MALL")}</h6>
                        </div>
                    </div>
                </div>
                <div className=" col-6 col-sm-6 col-md-2  " style={{ width: '240px' }}>
                    <div className='video-bottom'>
                        <div className=" d-flex">
                            <img src="/gallery/PROMOTION TVC/DISPLAY OF SAHI SUNFLOWER OIL 3L - MUSCAT.jpg" ></img>

                        </div>

                        <div className='video-head'>
                            <h6 style={{ fontFamily: t('fontFamilyone') }} >{t("DISPLAY OF SAHI SUNFLOWER OIL 3L - MUSCAT")}</h6>
                        </div>
                    </div>
                </div>
                <div className=" col-md-2  " style={{ width: '240px' }}>
                    <div className='video-bottom'>
                        <div className=" d-flex">
                            <img src="/gallery/PROMOTION TVC/DISPLAY OF SULTANAH COOKING OIL 4L-DUBAI.jpg" ></img>

                        </div>

                        <div className='video-head'>
                            <h6 style={{ fontFamily: t('fontFamilyone') }}>{t("DISPLAY OF SULTANAH COOKING OIL 4L-DUBAI")}</h6>
                        </div>
                    </div>
                </div>
                <div className=" col-md-2  " style={{ width: '240px' }}>
                    <div className='video-bottom'>
                        <div className="d-flex">
                            <img src="/gallery/PROMOTION TVC/DISPLAY OF SUNDROPS SUNFLOWER OIL 5L - DUBAI.jpg" ></img>

                        </div>
                        <div className='video-head'>
                            <h6 style={{ fontFamily: t('fontFamilyone') }}>{t("DISPLAY OF SUNDROPS SUNFLOWER OIL 5L - DUBAI")}</h6>
                        </div>
                    </div>
                </div>

                <div className=" col-md-2  " style={{ width: '240px' }}>
                    <div className='video-bottom'>
                        <div className=" d-flex">
                            <img src="/gallery/PROMOTION TVC/DISPLAY OF SUNLIFE COOKING OIL 5L - DUBAI.jpg" ></img>

                        </div>
                        <div className='video-head'>
                            <h6 style={{ fontFamily: t('fontFamilyone') }}>{t("DISPLAY OF SUNLIFE COOKING OIL 5L - DUBAI")}</h6>
                        </div>

                    </div>
                </div>



                <div className=" col-md-2  " style={{ width: '240px' }}>
                    <div className='video-bottom '>
                        <div className=" d-flex">
                            <img src="/gallery/PROMOTION TVC/DISPLAY OF TAIBAH SUNFLOWER OIL 5L - MUSCAT.jpg" ></img>

                        </div>
                        <div className='video-head'>
                            <h6 style={{ fontFamily: t('fontFamilyone') }}>{t("DISPLAY OF TAIBAH SUNFLOWER OIL 5L - MUSCAT")}</h6>
                        </div>
                    </div>
                </div>

                <div className=" col-md-2  " style={{ width: '240px' }}>
                    <div className='video-bottom'>
                        <div className=" d-flex">
                            <img src="/gallery/PROMOTION TVC/EID OFFER DISPALY - MUSCAT.jpg" ></img>

                        </div>
                        <div className='video-head'>
                            <h6 style={{ fontFamily: t('fontFamilyone') }}>{t("EID OFFER DISPALY - MUSCAT")}</h6>
                        </div>
                    </div>
                </div>
                <div className=" col-md-2  " style={{ width: '240px' }}>

                    <div className='video-bottom'>
                        <div className=" d-flex">
                            <img src="/gallery/PROMOTION TVC/EID OFFER DISPLAY - JEDDAH.jpg" ></img>

                        </div>
                        <div className='video-head'>
                            <h6 style={{ fontFamily: t('fontFamilyone') }}>{t("EID OFFER DISPLAY - JEDDAH")}</h6>
                        </div>


                    </div>
                </div>
                <div className=" col-md-2  " style={{ width: '240px' }}>
                    <div className='video-bottom'>
                        <div className="d-flex">
                            <img src="/gallery/PROMOTION TVC/EID OFFER DISPLAY - RIYADH.jpg" ></img>

                        </div>
                        <div className='video-head'>
                            <h6 style={{ fontFamily: t('fontFamilyone') }}>{t("EID OFFER DISPLAY - RIYADH")}</h6>
                        </div>
                    </div>
                </div>

                <div className=" col-md-2  " style={{ width: '240px' }}>

                    <div className='video-bottom'>
                        <div className=" d-flex">
                            <img src="/gallery/PROMOTION TVC/EID-OFFER-DISPLAY-JEDDAH.jpg" ></img>

                        </div>
                        <div className='video-head'>
                            <h6 style={{ fontFamily: t('fontFamilyone') }}>{t("EID OFFER DISPLAY-JEDDAH")}</h6>
                        </div>

                    </div>
                </div>










            </div>












        </div>
    )
}

export default Galleryvideo
