import React from 'react'
import Topbar from '../Includes/Topbar'
import Footer from '../Includes/Footer'

function NotFound() {
    return (
        <div>
            <div className="headertop" >
                <h1>NotFound</h1>
            </div>
        </div>
    )
}

export default NotFound