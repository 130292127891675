import React from 'react';
import { Carousel } from 'react-bootstrap';
import '../About.css'
import { useTranslation } from 'react-i18next';
function Boardslider() {
  const { t, i18n } = useTranslation();

  return (
    <div className="headertop" >
      <Carousel className="slider ">
        <Carousel.Item>
          <img className="d-block w-100  " src="/board/sliderboardnew.jpg" alt="ogfc-img" />

          <div className="carousel-caption caption-left">
            <div class="left-line"></div>
            <h1 style={{ fontFamily: t('fontFamilytwo') }} className='linedown'>{t("BOARD OF")}<br></br>{t("DIRECTORS")}
            </h1>
            <p style={{ fontFamily: t('fontFamilyone') }} className='linedown'>{t("5 prominent members of the local business")}<br></br>
              {t("community, all leaders in their industries.")}</p>
          </div>

        </Carousel.Item>
      </Carousel>
    </div>


  );
}

export default Boardslider;