import React, { useState } from 'react';
import { Carousel, Container } from 'react-bootstrap';
import '../About.css'
import { useTranslation } from 'react-i18next';
function Aboutslider({ text }) {
  const { t, i18n } = useTranslation();

  return (
    <div className="headertop" >
      <Carousel className="slider ">
        <Carousel.Item>
          <img className="d-block w-100  " src="/aboutimages/compnay-new.png" alt="ogfc-img" />

          <div className="carousel-caption caption-left">
            <div class="left-line"></div>
            <h1 style={{ fontFamily: t('fontFamilytwo') }} className='linedown'>{t("ABOUT")}<br></br>{t("COMPANY")}
            </h1>
         
            <p className='linedown' style={{ fontWeight: "600", fontFamily: t('fontFamilyone') }}>{t("Delivering healthy and tasty dishes for every kitchen")}<br></br>{t("with our pure and fresh cooking oil for decades.")}</p>

          </div>

        </Carousel.Item>
      </Carousel>
    </div>
  );
}

export default Aboutslider;