import React from 'react'

import { Carousel, Button } from "react-bootstrap";
import Aboutenquiry from '../About/Aboutenquiry';
import Footer from '../Includes/Footer';

import { Link, useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
function Gallerypage() {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate()
    return (
        <div>
            <div className="headertop" >
                <Carousel className="slider ">
                    <Carousel.Item>
                        <img className="d-block w-100  " src="/gallery/Banner-Gallery-new.jpg" alt="ogfc-img" />

                      
                        <div className="carousel-gallery" >

                            <h1 className='linedown' style={{ color: "#ef342b", fontFamily: t('fontFamilytwo') }} > {t("GALLERY")}</h1>

                        </div>

                    </Carousel.Item>
                </Carousel>
            </div>

            <div className='container '>
                <div className='gallery-image'>
                    <div className='row'>

                        <div className='col-md-6'>
                            <div className='gallery-first'>
                                <img className="d-block w-100  " src="/gallery/promotion-banner-ne.jpg" alt="ogfc-img" />

                                <div className='gallery-enquiry'>
                                    <h1 style={{ fontFamily: t('fontFamilytwo') }} >{t("PROMOTION / TVC")}</h1>
                                    <Link to="/gallery-promotion" onClick={() => { navigate('/gallery-promotion', { replace: true }); }}><Button variant="warning" style={{ fontFamily: t('fontFamilyone') }}>{t("VIEW ALL")}</Button></Link>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>

                            <div className='gallery-first'>
                                <img className="d-block w-100  " src="/gallery/promotion-banner-ne.jpg" alt="ogfc-img" />

                                <div className='gallery-enquiry'>
                                    <h1 style={{ fontFamily: t('fontFamilytwo') }}>{t("MEDIA NEWS")}</h1>
                                    <Link to="/media-news" onClick={() => { navigate('/media-news', { replace: true }); }}><Button variant="warning" style={{ fontFamily: t('fontFamilyone') }}>{t("VIEW ALL")}</Button></Link>
                                </div>
                            </div>


                        </div>
                    </div>



                    <div className='row justify-content-center'>
                        <div className='col-md-offset-3 col-md-6'>


                            <div className='gallery-first'>
                                <img className="d-block w-100  " src="/gallery/promotion-banner-ne.jpg" alt="ogfc-img" />

                                <div className='gallery-enquiry'>
                                    <h1 style={{ fontFamily: t('fontFamilytwo') }}>{t("ARCHIVE")}</h1>
                                    <Link to="/archive" onClick={() => { navigate('/archive', { replace: true }); }}><Button variant="warning" style={{ fontFamily: t('fontFamilyone') }}>{t("VIEW ALL")}</Button></Link>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <Aboutenquiry />
            <Footer />

        </div>





    )
}

export default Gallerypage