import React from 'react'
import '../board.css'
import { useTranslation } from 'react-i18next';
function Boardabout() {
  const { t, i18n } = useTranslation();

  return (
    <div>
     
      <div className="container">
        <div className='row'>
          <div className='col-md-12'>
            <div className='board-para-new'>
              <p style={{ fontFamily: t('fontFamilytwo') }} >{t("The Board of Directors is responsible for Omani Gulf Food Company strategic direction, oversight and corporate governance, ensuring the business delivers on the government of Oman development and diversification priorities.")}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Boardabout
