import React from 'react'
import { Carousel } from 'react-bootstrap';
import Aboutcompany from '../About/Aboutcompany';
import Aboutenquiry from '../About/Aboutenquiry';
import Footer from '../Includes/Footer';
import { useTranslation } from 'react-i18next';
function Privtatebrands() {
  const { t, i18n } = useTranslation();
  return (
    <div>
      <div className="headertop" >
        <Carousel className="slider">
          <Carousel.Item>
            <img className="d-block w-100" src="/privatebrands/Private-Brands.jpg" alt="ogfc-img"></img>
                        <div className="carousel-caption caption-left">
              <div class="left-line"></div>
              <h1 className='linedown privatehead' style={{ color: '#007825',fontFamily: t('fontFamilytwo') }}>{t("PRIVATE")}<br></br>
                {t("BRANDS")}
              </h1>
              <p className='linedown privatepara' style={{ color: '#000', fontWeight: '600',fontFamily: t('fontFamilyone') }}>{t("We produce and sell a comprehensive portfolio")}<br></br>{t("of private label, Its presents an opportunity")}<br></br>{t("to  address and satisfy emerging consumer needs")}</p>
            </div>
            <div className='private-sub carousel-caption caption-left'>
              <h5 className='linedown' style={{fontFamily: t('fontFamilyone')}} >
                {t("300+ ADMIRING BRANDS ACROSS GCC & AFRICA")}
              </h5>
            </div>
          </Carousel.Item>
        </Carousel>
      </div>

      <div className='private-section' style={{backgroundColor: '#ffe3c5' }}>
        <div className='container py-5'>
          <div className='row private-brands'>
            <div className='col-md-2 col-6'>
      <img src='/privatebrands/1.jpg'></img>
            
            </div>
            <div className='col-md-2 col-6'>
            <img src='/privatebrands/2.jpg'></img>
            </div>
            <div className='col-md-2 col-6'>
            <img src='/privatebrands/3.jpg'></img>
            </div>
            <div className='col-md-2 col-6'>
            <img src='/privatebrands/4.jpg'></img>
            </div>
            <div className='col-md-2 col-6'>
            <img src='/privatebrands/5.jpg'></img>
            </div>
            <div className='col-md-2 col-6'>
            <img src='/privatebrands/6.jpg'></img>
            </div>
         
            <div className='col-md-2  col-6'>
            <img src='/privatebrands/7.jpg'></img>
            </div>
            <div className='col-md-2  col-6' >
            <img src='/privatebrands/8.jpg'></img>
            </div>
            <div className='col-md-2 col-sm-4  col-6'>
            <img src='/privatebrands/9.jpg'></img>
            </div>
            <div className='col-md-2 col-sm-4  col-6'>
            <img src='/privatebrands/10.jpg'></img>
            </div>
            <div className='col-md-2 col-sm-4  col-6'>
            <img src='/privatebrands/11.jpg'></img>
            </div>
            <div className='col-md-2 col-sm-4  col-6'>
            <img src='/privatebrands/12.jpg'></img>
            </div>
         
            <div className='col-md-2 col-6'>
            <img src='/privatebrands/13.jpg'></img>
            </div>
            <div className='col-md-2 col-6'>
            <img src='/privatebrands/14.jpg'></img>
            </div>
            <div className='col-md-2 col-6'>
            <img src='/privatebrands/15.jpg'></img>
            </div>
            <div className='col-md-2 col-6'>
            <img src='/privatebrands/16.jpg'></img>
            </div>
            <div className='col-md-2 col-6'>
            <img src='/privatebrands/17.jpg'></img>
            </div>
            <div className='col-md-2 col-6'>
            <img src='/privatebrands/18.jpg'></img>
            </div>
      
            <div className='col-md-2 col-6'>
            <img src='/privatebrands/19.jpg'></img>
            </div>
            <div className='col-md-2 col-6'>
            <img src='/privatebrands/20.jpg'></img>
            </div>
            <div className='col-md-2 col-6'>
            <img src='/privatebrands/21.jpg'></img>
            </div>
            <div className='col-md-2 col-6'>
            <img src='/privatebrands/22.jpg'></img>
            </div>
            <div className='col-md-2 col-6'>
            <img src='/privatebrands/23.jpg'></img>
            </div>
            <div className='col-md-2 col-6'>
            <img src='/privatebrands/24.jpg'></img>
            </div>
          </div>


        </div>
        <Aboutcompany />
        <Aboutenquiry />
        <Footer />

      </div>
    </div>
  )
}

export default Privtatebrands