import React from 'react'
import { Carousel } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
function Banner() {
  const { t, i18n } = useTranslation();
  return (
    <div>



      <Carousel>
        <Carousel.Item interval={1000}>
          <img
            className="d-block w-100 headertop"
            src='/images/banner/Slider4.jpg'
            alt="ogfc-img"
          />
          <Carousel.Caption>

          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={1000}>
          <img
            className="d-block w-100 headertop"
            src={t("home-banner")}
            alt="ogfc-img"
          />


        </Carousel.Item>

      </Carousel>

    </div>
  )
}

export default Banner