import React from 'react'
import { Container } from 'react-bootstrap'; import '../Import.css'
import { useTranslation } from 'react-i18next';

function Importabout() {
    const { t, i18n } = useTranslation();
    return (
        <div style={{ background: '#fee3c5' }} >
            <div className='container py-5'><div className='row'>
                <div className='col-md-12'>
                    <div className='import'>
                        <h1 style={{ fontFamily: t('fontFamilytwo') }}>{t("Import")}</h1>
                        <p style={{ fontFamily: t('fontFamilyone') }}>{t("Raw materials like Crude & Refined Palm Oil Products, Sunflower Oil, Corn Oil, Soya Oil, Canola Oil and other vegetable oil products.")}</p>
                    </div>
                    <div className='import export'>
                        <h1 style={{ fontFamily: t('fontFamilytwo') }}>{t("Export")}</h1>
                        <p style={{ fontFamily: t('fontFamilyone') }}>{t("All edible oils & Vegetable Ghee in consumer packs from 500 ml/gms to 20 Litres/Kgs.")}</p>
                    </div>
                </div>
            </div>
            </div>
        </div >
    )
}

export default Importabout