import React from 'react';
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
function Aboutcompany() {
  const { t, i18n } = useTranslation();


  return (
    <div >

      <div className='aboutcompanyhead'>
        <div className='aboutcompany'>
          <div className='container'>

            <div className='row'>

              <div className='col-xs-12   col-sm-12 col-md-12   col-lg-5'>
                <div className='about-company-head'>
                  <h1 style={{ fontFamily: t('fontFamilytwo') }}>{t("Trusted By More Than 300+ Popular Private Labels Across Gulf Region")}</h1>
                  <Link to="/ourproduct">

                    <Button className='my-2' style={{ fontFamily: t('fontFamilyone') }}>{t("DISCOVER NOW")}</Button></Link>
                </div>
              </div>
              <div className='col-xs-12   col-sm-12 col-md-12   col-lg-7'>
                <ul className='aboutbrands'>
                  <li><img src='/aboutimages/brand1.jpg' alt="ogfc-img"></img></li>
                  <li><img src='/aboutimages/brand6.jpg' alt="ogfc-img"></img></li>
                  <li><img src='/aboutimages/brand2.jpg' alt="ogfc-img"></img></li>
                  <li><img src='/aboutimages/brand3.jpg' alt="ogfc-img"></img></li>
                  <li><img src='/aboutimages/brand4.jpg' alt="ogfc-img"></img></li>
                  <li><img src='/aboutimages/brand5.jpg' alt="ogfc-img"></img></li>
                  <li><img src='/aboutimages/brand7.jpg' alt="ogfc-img"></img></li>
                </ul>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div >
  )
}

export default Aboutcompany
